.input-search-streamline-close {
    width: 0px;
    transition: 0.2s;
}
.input-search-streamline-open {
    height: 34px;
    margin-left: 13px;
    margin-right: 12px;
    width: 414px;
    background: #ffffff;
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    padding: 5px 24px;
    font-weight: 400;
    font-size: 12px;
    transition: 0.2s;
}
.search-icon__container {
    position: absolute;
    right: 34px;
    top: 32px;
}

.section-search {
    background: #ffffff;
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    padding: 5px 12px;
    width: 38px;
    height: 34px;
    cursor: pointer;
    position: relative;

    .section-search-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
