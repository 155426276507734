.multiple-filter-demand-anticipation {
    .multiple-icon-filter {
        width: 32px;
        height: 32px;
        border: 1px solid #b1b1b1;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;

        .label-field {
            color: #2d2d2d;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 12px;
        }
        .menu-button {
            box-sizing: border-box;
            height: 32px !important;

            padding-left: 16px;
            border-radius: 4px;
            background: white;
            cursor: pointer;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            border: 0.5px solid #d7d7d7 !important;
            border-radius: 4px !important;
            outline: grey;

            color: #2d2d2d;

            span:nth-child(1) {
                text-align: left;
            }
        }
        .menu-item {
            height: 32px;

            border-bottom: 0.5px solid #929191;
            color: #2d2d2d;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        .menuList-container {
            max-height: 300px;
            overflow: auto;
            min-width: 179px;
            padding-top: 0px;
            padding-bottom: 0px;
            border: 0.5px solid #929191;
            border-radius: 4px;
            border-bottom: none;
        }
        .btn-filter-multiple-badge {
            position: absolute;
            width: 18px;
            height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ef5350;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 15px;
            border-radius: 100%;
            color: #ffffff;
            top: -10px;
            right: -10px;
        }
        .body {
            border-radius: 4px;
            border: 0.5px solid #d7d7d7;
            background: #ffffff;
            margin-top: 14px;
            height: 205px;
            overflow: unset;
            padding: 20px 18px;
        }
    }
    .has-filter-multiple-selected {
        border: 1px solid #1746a2;
        border-radius: 2px;
    }
}
