.container-column-status-demand-anticipation {
    .fieldmenu-placeholder {
        font-family: var(--main-font);
        font-weight: 400;
        font-style: normal;
        font-size: 10px;
        line-height: 15px;
        color: #2d2d2d;
    }
    .fieldmenu-input {
        border: 0.5px solid #1746a2;
        width: 103px;
        height: 20px;

        font-family: var(--main-font);
        font-weight: 400;
        font-style: normal;
        font-size: 10px;
        line-height: 15px;
        color: #2d2d2d;
        padding-right: 6px;
    }

    .menuList-container {
        max-height: 103px !important;
        overflow: auto !important;
        width: 103px;

        background: white;
        font-family: var(--main-font);
        font-weight: 400;
        font-style: normal;
        font-size: 10px;
        line-height: 15px;
        color: #2d2d2d;
    }
}
