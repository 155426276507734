.container-edit-column-order-delivery {
    .column-text {
        font-family: var(--main-font);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-transform: capitalize;
        color: #2d2d2d;
        text-align: left;

        @media (min-width: 1441px) {
            font-size: 12px;
        }
    }
    .input-column {
        @extend .column-text;
        text-align: center;
        padding: 5px;
        outline: none;
        border: rgb(155, 155, 155) 1px solid;
    }
}
