.create-modal__container__orderplanning {
    .section-head__container {
        height: 64px;
        justify-content: center;
        align-items: center;
        display: flex;
        border-bottom: 0.5px solid #b0b0b0;
        box-sizing: border-box;
        .head-title__container {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 27px;
            align-items: center;
            text-align: center;
            text-transform: capitalize;

            color: #2d2d2d;
        }
    }

    .section-body__container {
        .modal-item__container {
            margin-bottom: 28px;

            .label {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #2d2d2d;
                margin-right: 32px;
            }

            .required-field__text {
                color: red;
                margin-top: 4px;
                font-size: 12px;
                font-family: 'Lato';
            }

            .input-container,
            select,
            .menu-button {
                border: 1px solid #d7d7d7;
                box-sizing: border-box;
                height: 40px;
                width: 100%;
                padding-left: 16px;
                border-radius: 4px;
                background: white;
                cursor: pointer;
                flex: 1;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;

                outline: grey;

                color: #939393;

                span:nth-child(1) {
                    text-align: left;
                }
            }
        }
        .section-card_container {
            border-bottom: 0.5px solid #b0b0b0;
            box-sizing: border-box;
        }

        .section-card-input_container {
            padding: 0px 22px 0px 22px;
        }
    }

    .text-semi-title__container {
        font-style: bold;
        font-weight: 700;
        font-size: 23px;
        color: black;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 26px;
    }

    .text-semi__container {
        font-style: bold;
        font-weight: 600;
        font-size: 14px;
        color: black;
        margin-bottom: 26px;
        margin-top: 32px;
    }

    .input-container,
    select,
    .menu-button {
        border: 1px solid rgb(165, 165, 165);
        box-sizing: border-box;
        height: 40px;
        width: 100%;
        padding-left: 16px;
        border-radius: 4px;
        background: white;
        cursor: pointer;
        flex: 1;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;

        outline: grey;

        color: #939393;

        span:nth-child(1) {
            text-align: left;
        }
    }

    .section-footer__container {
        display: flex;
        padding: 16px;
        width: 50%;

        margin: 0 auto;

        .button-add__container {
            width: 100%;
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            color: white;
            background: #1746a2;

            height: 42px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            margin: 5px;
        }

        .button-close__container {
            width: 100%;
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            color: #1746a2;
            border: 2px rgb(225, 225, 225) solid;
            background: white;

            height: 42px;
            border: 1px solid #1746a2;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            margin: 5px;
        }
    }
}
