:root {
    --main-color: #1746a2;

    --main-color-7: rgba(23, 70, 162, 0.7);
    --main-color-5: rgba(23, 70, 162, 0.5);
    --main-color-3: rgba(23, 70, 162, 0.3);
    --main-color-1: rgba(23, 70, 162, 0.1);

    --default-grey: #e4e4e4;
    --default-black: #2d2d2d;
}
