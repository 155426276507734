.tab-item {
    font-family: var(--main-font);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(23, 70, 162, 0.3);
    cursor: pointer;
    margin-right: 24px;
}

.active-tab {
    opacity: 1;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1746a2;
    position: relative;
    padding: 0 6px;
    padding-bottom: 7px;
}

.active-tab::after {
    content: '';
    border-bottom: 4px solid #1746a2;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
}
