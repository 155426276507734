.material-configuration__container {
    height: calc(100vh - 100px);
    padding: 32px 18px;
    font-family: var(--main-font);
    background: white;
    border: 0.5px solid #d7d7d7;
    border-radius: 8px;
    box-sizing: border-box;
    overflow: hidden;
}

.modal-actived__material-configuration {
    height: calc(100vh - 86px);
    overflow: scroll;
}

.material-configuration__container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.material-configuration__container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
