.order-management__container {
    height: calc(100vh - 135px);
    padding: 22px 15px;
    font-family: var(--main-font);
    background: white;
    border: 0.5px solid #d7d7d7;
    border-radius: 8px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}

@media only screen and (min-width: 1024px) and (max-width: 1500px) {
    .order-management__container {
        height: calc(100vh - 135px);
        padding: 22px 15px;
        font-family: var(--main-font);
        background: white;
        border: 0.5px solid #d7d7d7;
        border-radius: 8px;
        box-sizing: border-box;
        overflow: hidden;
    }
    .order-management__container::-webkit-scrollbar {
        display: none;
    }
}

// ---media-query for zoom 50%
//  @media (min-width:2052px) and (max-width:2732px) {
//         .order-management__container {
//         width: calc(100vw - 442px);
//     }
//  }

// ---media-query for zoom 33%
//   @media (min-width:2733px) and (max-width:4102px) {
//     .order-management__container {
//         width: calc(100vw - 1721px);
//     }
//  }

// ---media-query for zoom 25%
//   @media (min-width:4103px) and (max-width:5464px) {
//     .order-management__container {
//         width: calc(100vw - 2998px);
//     }
//  }

.order-management__container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.order-management__container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
