.weighing-card__wrapper__main-dashboard {
    width: inherit;

  
    .weighing-card__container__main-dashboard {
        border: 0.5px solid #8f8e8e;
        background: #ffffff;
        border-radius: 4px;
        height: 131px;

        cursor: pointer;

        .weighing-card__head-container {
            justify-content: space-between;
            align-items: center;
            padding: 7px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;

            .production-status__line-name {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                text-transform: capitalize;
            }

            .head-title__container {
                font-family: var(--main-font);
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                text-align: center;
                text-transform: capitalize;

                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .weighing-card__body-container {
            .product-name__text {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                text-transform: capitalize;
                font-family: var(--main-font);
                color: #2d2d2d;
                margin-bottom: 4px;
                height: 36px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                margin-top: 2px;
            }

            .code-name__text {
                font-style: normal;
                font-weight: 400;
                font-size: 9px;
                line-height: 14px;
                text-transform: capitalize;
                color: #939393;
                margin-bottom: 4px;
            }

            .expectation-label {
                font-style: normal;
                font-weight: 400;
                font-size: 9px;
                line-height: 14px;
                color: #2d2d2d;
                margin-right: 6px;
            }

            .expectation-text {
                font-style: normal;
                font-weight: 400;
                font-size: 9px;
                line-height: 14px;
            }
        }
    }
}
