.download-loader__container{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

    .download-loader__box{
        background: white;
        width: 240px;
        height: 240px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .label{
        font-size: 14px;
        margin-top: 24px;
        font-weight: 500;
    }
}