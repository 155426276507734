.modal-autocorrection__container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 46px 16px;

    .success-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        margin: 24px 0;
        width: 100%;
        text-align: center;
    }

    .description-container {
        flex-direction: column;
        max-height: 300px;
        overflow: auto;
        width: 100%;
    }

    .success-description {
        margin-bottom: 4px;
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
        width: 100%;
        padding: 0 24px;
        display: flex;

        p {
            font-weight: 700;
            margin-right: 10px;
        }
    }

    .back-button {
        margin-top: 32px;
        width: 219px;
        height: 36px;
        border-radius: 0px !important;
        border: 1px solid #2d2d2d;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #2d2d2d;
        background: white;
    }
}
