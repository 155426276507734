.section-table__container__table-rekap-delivery {
    position: relative;
    overflow-x: scroll;
    padding-bottom: 16px;
    height: calc(100vh - 296px);
    .section-table_rekap-delivery {
        height: calc(100vh - 360px);
    }
}

@media (max-width: 1280px) {
    .section-table__container__table-rekap-delivery {
    width: calc(100vw - 330px);
    position: relative;
    overflow-x: scroll;
    padding-bottom: 16px;
    height: calc(100vh - 296px);
    .section-table_rekap-delivery {
        height: calc(100vh - 360px);
    }

    .section-table__container__table-rekap-delivery::-webkit-scrollbar-track {
        border-top: 2px solid white;
        border-bottom: 2px solid white;
        background: rgba(181, 176, 176, 0.3);
    }
    
    
    .section-table__container__table-rekap-delivery::-webkit-scrollbar {
        height: 7px;
        width: 0;
    }
    
    .section-table__container__table-rekap-delivery::-webkit-scrollbar-thumb {
        border-radius: 16px;
        background-color: #4163a4;
    }
  }
}

.section-table__container__table-rekap-delivery::-webkit-scrollbar-track {
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    background: rgba(181, 176, 176, 0.3);
}


.section-table__container__table-rekap-delivery::-webkit-scrollbar {
    height: 7px;
    width: 0;
}

.section-table__container__table-rekap-delivery::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: #4163a4;
}

// .hide-scrollbar-section-table__container__table-rekap-delivery::-webkit-scrollbar {
//     display: none;
// }

.hide-scrollbar-section-table__container__table-rekap-delivery {
    height: max-content;

    .section-table {
        height: max-content;
    }
}
