.recap-order-head-table-container {
    background-color: #e8edf6;
    height: 33px !important;
    .recap-order-head-table {
        text-transform: capitalize !important;
        padding-right: 0px !important;
        padding-top: 6px !important;
        padding-bottom: 6px !important;
        font-family: 'Poppins' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 21px !important;
        letter-spacing: 0.2px !important;
        background-color: #e8edf6;
    }
}
