.modal-failed-global__container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 30px 0px 55.08px 0px;

    .failed-title {
        margin-top: 12px;
        color: #2d2d2d;
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22.846px; /* 163.183% */
    }

    .failed-description {
        margin-top: 12px;
        margin-bottom: 32px;
        width: 320px;
        color: #2d2d2d;
        text-align: center;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 183.333% */
    }

    .left-button {
        color: #1746a2;
        font-family: Poppins;
        font-size: 10.544px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border: 0.879px solid #1746a2;
        background-color: #fff;
        border-radius: 3.515px;
        width: 118.039px;
        height: 31.555px;
    }
    .right-button {
        color: #fff;
        font-family: Poppins;
        font-size: 10.544px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border-radius: 3.515px;
        background-color: #1746a2;
        width: 118.039px;
        height: 31.555px;
    }
}
