.table-column__container__rekap-delivery {
    width: 100%;
    .column__item {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        padding-top: 11px;
        padding-bottom: 11px;
        padding-left: 10px;
        border-bottom: 1px solid #cecece;

        .checkbox-active {
            height: 15px;
            width: 15px;
            border: 1px solid #BBBBBB;
            outline: none;
        };

        .checkbox-disable {
            height: 15px;
            width: 15px;
            border: 1px solid #2D2D2D;
            outline: none;
        }


        .column-status {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 19px;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: capitalize;
            color: var(--default-black);
            padding: 2px 16px;
            background: #e8e7e7;
            border-radius: 43px;

            @media (min-width: 1441px) {
                font-size: 14px;
            }
        }

        .column-text {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-transform: capitalize;
            color: #2D2D2D;
            text-align: left;

            @media (min-width: 1441px) {
                font-size: 12px;
            }
        }

        .text-ellipsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .column-text-status {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            text-align: center;
        }
    }

    .column__item {
        display: flex;
        justify-content: center;
        align-items: center;
        }

    .column__item:first-child {
        @media (max-width: 1280px) {
            padding-inline: 21.7px;
            display: flex;
            flex: 1;
        }
    }

    .column__item:nth-child(2) {
        @media (max-width: 1280px) {
            padding-inline: 46.7px;
        }
    }

    .column__item:nth-child(3) {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @media (max-width: 1280px) {
            padding-right: 75px;
        }
    }

    .column__item:nth-child(4) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 20px;
        @media (max-width: 1280px) {
            width: 361px;
            display: flex;
            flex: 1;
        }
    }

    .column__item:nth-child(5) {
        @media (max-width: 1280px) {
            padding-inline: 19px;
            display: flex;
            align-items: center;
            justify-content: center;

            .wrapper-column-text {
                display: inline-block;
                width: max-content;
            }
        }
    }

    .column__item:nth-child(6) {
        @media (max-width: 1280px) {
            padding-inline: 51.4px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .column__item:nth-child(7) {
        @media (max-width: 1280px) {
            padding-inline: 19px;
            display: flex;
            align-items: center;
            justify-content: center;

            .wrapper-column-text {
                display: inline-block;
                width: max-content;
            }
        }
    }
    .column__item:nth-child(8) {
        @media (max-width: 1280px) {
            padding-inline: 48px;
        }
    }
    .column__item-child {
        display: flex;
        align-items: center;
        width: 100%;

        .column__item-grand-child {
            width: 100%;
            display: flex;
            align-items: center;
        }

        .column-text {
            flex: 1;
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-transform: capitalize;
            color: #2D2D2D;

            @media (min-width: 1441px) {
                font-size: 12px;
            }
        }
    }
    .notes-icon {
      position: absolute;
      top: 0px;
      right: -28px;
      cursor: pointer;
    }
} 