.modal-container-filter-multiple-raw-data-order {
    .modal-filter-multiple-header {
        justify-content: space-between !important;
        width: 100% !important;
        padding: 13px 18px;
        border-bottom: 1px solid #b7b7b7;

        .modal-header-text {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height */

            color: #2d2d2d;
        }
    }

    .modal-body {
        // max-height: 505px;
        // overflow: hidden;
        padding: 0px 18px 32px 18px;
        border-radius: 4px;
        border: 0.5px solid #d7d7d7;
        background: #fff;

        .divider {
            height: 31px;
            width: 0.5px;
            background-color: #b7b7b7;
            margin-right: 4px;
        }

        .filter-no-po__container {
            // background: pink;
            flex-direction: column;
            margin-top: 16px;

            .label-title {
                margin-bottom: 12px;
                font-family: var(--main-font);
                font-style: normal;
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: 0em;
                text-align: left;
                color: #2D2D2D;
                text-transform: capitalize;
            }

            .fieldmenu-input {
                width: 374px;
                height: 32px;
                border-radius: 2px;
                border: 0.5px solid #2D2D2D;

                font-family: var(--main-font);
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: left;
                color: #2D2D2D;
            }

            .fieldmenu-input::placeholder {
                font-family: var(--main-font);
                font-style: normal;
                font-size: 12px;
                font-weight: 300;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: left;
                color: #B7B7B7;
            }
        }

        .datepicker-konfirmasi-eta__container {
            margin-top: 24px;

            .label-title-confirm {
                margin-bottom: 12px;
                font-family: var(--main-font);
                font-style: normal;
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: 0em;
                text-align: left;
                color: #2D2D2D;
                text-transform: capitalize;
                margin-bottom: 12px;
            }

        .calender_confirm-eta {
            width: 374px;
            height: 32px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

        .date-picker-confirm-eta {
            width: 374px;
            height: 32px;

            font-family: var(--main-font);
            font-style: normal;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            color: #2D2D2D;
            outline: none;

            .react-date-picker__wrapper {
            border-top: 0.5px solid #2D2D2D;
            border-right: 0.5px solid #2D2D2D;
            border-left: 0.5px solid #2D2D2D;
            border-bottom: 0.5px solid #2D2D2D;
            border-radius: 2px;
            }

            .react-date-picker__inputGroup {
            padding: 0px 6px;
            }

            .react-date-picker__button {
            display: flex;
            align-items: center;
            padding: 6px;
            border-left: 0.5px solid #b7b7b7;;
            }

            .react-date-picker__calendar {
            width: 350px;
            max-width: 100vw;
            z-index: 5;
        }
        }

        .calendar-confirm-eta {
            width: 250px;
            position: absolute;
            left: 60px;
            top: 12px;
            margin-left: 5px;
            border-radius: 4px;
        }

        .react-calendar__month-view__weekdays {
            padding-bottom: 6px;   
        }
        
        .react-calendar__month-view__weekdays__weekday {
            color: #2D2D2D;
            font-weight: 600;
            font-style: normal;
            font-size: 8px;
            line-height: 12px;
            text-align: center;
            text-transform: capitalize;
            text-decoration: none;
            padding: 16px 0px;
        }

        .react-calendar__month-view__days__day {
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 16px;
            text-align: center;
            background: white;
            color: #2D2D2D;
        }
     }
}

        .input-box {
            width: 374px;
            height: 36px;
            border: 1px solid #b1b1b1;
            border-radius: 2px;
            padding: 9px 12px;
            font-size: 12px;
        }
        .input-box:focus {
            outline: none;
        }
        .input-box::placeholder {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height */

            color: #d7d7d7;
        }

        .search-icon {
            position: absolute;
            top: 3.5px;
            right: 12px;
            height: 29px;
            border-left: 0.5px solid #d7d7d7;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 16.25px;
        }

    .scroll-box-container {
            max-height: 190px;
            width: 374px;
            overflow: auto;
            background-color: rgb(255, 255, 255);
            border-radius: 4px;

            .loading_fetch_next_page {
                color: var(--black, #757575);
                font-family: Open Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-left: 12px;
                padding-top: 10px;
                padding-bottom: 10px;
                text-align: center;
            }

            .no_data_infinite_scroll {
                color: var(--black, #757575);
                font-family: Open Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                padding-top: 10px;
                padding-bottom: 10px;
                text-align: center;
                position: absolute;
                left: 0;
                right: 0;
                background-color: #ffffff;
                border-left: 1px solid #e2e8f0;
                border-right: 1px solid #e2e8f0;
            }
            .list_item_infinite {
                color: var(--black, #2d2d2d) !important;
                font-family: Open Sans !important;
                font-size: 12px !important;
                font-style: normal !important;
                font-weight: 600 !important;
                line-height: normal !important;
                padding: 10px 10px;
                border-radius: 2px;
                border-bottom: 0.5px solid #d7d7d7;
            }
            .list_item_infinite:hover {
                background-color: #b9c7e3;
                color: #fff !important;
                cursor: pointer;
            }
            .list_item_infinite:active {
                background-color: #e0e0e0;
            }
        }
    }
    
    .scroll-box-container::-webkit-scrollbar-track {
        background: #b7b7b7 !important;
        border: solid #ffffff 7px !important;
    }

    .scroll-box-container::-webkit-scrollbar {
        height: 6px !important;
        width: 15px !important;
    }

    .scroll-box-container::-webkit-scrollbar-thumb {
        border-radius: 100px !important;
        background: #4163a4 !important;
        border: solid #fff 5.7px;
        border-bottom: transparent !important;
        border-top: transparent !important;
    }

    .ModalFooter {
        gap: 12px;
        padding: 20px;
        border-top: 1px solid #b7b7b7;

        .btn-modal-close {
            width: 93px;
            height: 32px;
            border: 1px solid #0154a2;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .text-modal-close {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height */
                color: #4163a4;
            }
        }
    }

    .btn-apply-filter {
        width: 95px;
        height: 32px;
        padding: 7px 12px;
        background: #4163a4;
        border: 1px solid #0154a2;
        border-radius: 2px;
        cursor: pointer;

        .text-apply-filter {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height */

            color: #ffffff;
        }
    }
    .disabled-btn-apply-filter {
        background: #4163a4;
        opacity: 0.3;
    }
}
