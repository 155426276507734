.menu-filter-supplier__container {
    position: relative;
    display: flex;
    .input-filter-parent-supplier {
        background: white !important;
        height: 36px;
        width: 225px;
        border: 0.5px solid #d7d7d7 !important;
        border-radius: 4px !important;
        display: flex;
        align-items: center;
        justify-content: center;

        font-family: var(--main-font);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #2d2d2d;
        margin-left: 16px;

        .input-selected-supplier {
            // width: 225px;
            height: 36px;
            border-radius: 4px;
            border-width: thin;
            text-align: left;

            color: var(#2d2d2d);
            font-family: var(--main-font);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            text-align: left;
            text-transform: capitalize;
        }

        .input-selected-supplier:focus {
            outline: none;
        }
    }

    .menu-list-wrapper {
        position: absolute;
        right: -244px;
        // right: -241px;
        width: 227px;
        padding-top: 17px;
        padding-left: 12px;
        padding-right: 12px;
        margin-top: 44px;
        z-index: 20;
    }

    .container-item-supplier {
        .checkbox-custom-style {
            .css-1ydjfm6 {
                width: 15px;
                height: 15px;
                border-color: #2d2d2d;
                border-width: 2.5px;
                position: absolute;
                top: -7.5px;
                right: -13px;
            }
            .css-1ydjfm6[data-checked] {
                background: #1746a2;
                border-color: #1746a2;
            }
        }

        .infinite-scroll-component {
            overflow-y: hidden;
        }

        #scrollableDiv {
            // max-height: 169px;
            max-height: 200px;
            width: 208px;
            overflow: auto;
            padding-top: 2px;
            padding-bottom: 2px;
        }

        #scrollableDiv::-webkit-scrollbar {
            height: 6px !important;
            width: 15px !important;
        }

        #scrollableDiv::-webkit-scrollbar-track {
            background: #b7b7b7 !important;
            border: solid #ffffff 7px !important;
        }

        #scrollableDiv::-webkit-scrollbar-thumb {
            border-radius: 100px !important;
            background: #2d2d2d !important;
            border: solid #fff 5.7px;
            border-bottom: transparent !important;
            border-top: transparent !important;
        }

        .loading_fetch_next_page {
            color: var(--black, #757575);
            font-family: Open Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-left: 12px;
            padding-top: 10px;
            padding-bottom: 10px;
            text-align: center;
        }
        .no_data_infinite_scroll {
                color: var(--black, #757575);
                font-family: Open Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                padding-top: 10px;
                padding-bottom: 10px;
                text-align: center;
                position: absolute;
                left: 0;
                right: 0;
                background-color: #ffffff;
                border-left: 1px solid #e2e8f0;
                border-right: 1px solid #e2e8f0;
            }
    }

    .menu-item-supplier {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // width: 177px;
        height: 38px;
        border-bottom: 0.5px solid #929191;
        padding-left: 2px;
        padding-top: 15px;
        padding-bottom: 7px;

        .menu-item-text {
            color: var(#2d2d2d);
            font-family: var(--main-font);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            text-align: left;
            text-transform: capitalize;
            padding-right: 10px;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .chakra-text {
            text-transform: capitalize;
        }

        .wrapper-menu-checkbox-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 177px;
        }
    }

    .search-icon {
        position: absolute;
        top: 20.5px;
        right: 17px;
        height: 29px;
        border-left: 0.5px solid #d7d7d7;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 12px;
    }

    .input-box-menu-list {
        width: 202px;
        height: 36px;
        border: 1px solid #b1b1b1;
        border-radius: 2px;
        padding: 9px 12px;
        font-size: 12px;
        margin-bottom: 16px;
    }
    .input-box-menu-list:focus {
        outline: none;
    }
    .input-box-menu-list::placeholder {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height */

        color: #d7d7d7;
    }

    .label-input {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height */

        color: #2d2d2d;
    }
    .clear-global-filter-jenis-material {
        height: 36px;
        margin-left: 5px;
        padding-left: 5px;
        padding-right: 5px;
    }
}
