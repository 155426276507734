.modal-success-global__container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 46px 16px;

    .success-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin: 24px 0;
    }

    .success-description {
        margin-bottom: 42px;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        width: 324px;
    }

    .back-button {
        width: 219px;
        height: 36px;
        border-radius: 0px !important;
        border: 1px solid #2d2d2d;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #2d2d2d;
        background: white;
    }
}
