.table-head__container__table-add-item__item-set {
    position: sticky;
    top: 0;
    width: 100%;
    .table-head__item {
        display: flex;
        justify-content: flex-start;
        padding-left: 32px;
        align-items: center;
        text-align: left;

        p {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            text-transform: capitalize;
            color: #2D2D2D;
        }
    }

    .table-head__item:first-child {
        justify-content: flex-start;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color: #EDF0F6;
        height: 40px;
    }

    .table-head__item:nth-child(2) {
        background-color: #EDF0F6;
        height: 40px;
    }

    .table-head__item:last-child {
        justify-content: center;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background-color: #EDF0F6;
        height: 40px;
    }

    #table-head__item-name {
        justify-content: flex-start;
    }

    .table-head__item-name {
        text-align: left !important;
    }
}