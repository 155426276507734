.modal-custom-global__container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 46px 16px;

    .custom-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin: 24px 0;
    }

    .custom-description {
        margin-bottom: 42px;

        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        width: 303px;
    }

    .button-container {
        display: flex;
        gap: 20px;

        .custom-button,
        .custom-cancel {
            width: 117px;
            height: 36px;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            border-radius: 4px;
            outline: none !important;
        }
    }
}
