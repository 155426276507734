.section-table__container__order-planning {
    width: calc(100vw - 336px);
    overflow-x: scroll;
    position: relative;
    padding-bottom: 12px;
}

.section-table__container__order-planning::-webkit-scrollbar-track {
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    background: rgba(181, 176, 176, 0.3);
}

.section-table__container__order-planning::-webkit-scrollbar {
    height: 7px;
    width: 0;
}

.section-table__container__order-planning::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: #4163a4;
}

.hide-scrollbar-section-table__container::-webkit-scrollbar {
    display: none;
}
