.section-upload__container__orderplanning{
    background: #ffffff;
    border: 0.5px solid #d7d7d7;
    border-radius: 4px;
    padding: 24px  16px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 131px);


    .section-title{
        display: flex;
        align-items: center;
        cursor: pointer;

        .text{
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #2D2D2D;
            margin-left: 12px;
        }
    }

    .section-body{
        display: flex;
        justify-content: center;
        align-items: center;

        .upload-box {

            .file-uploader, .file-uploader__drag-and-drop-only {
                position: absolute;
                top: 0;
                width: 100%;
                right: 0;
                left: 0;
                bottom: 0;
                cursor: pointer;
                opacity: 0;
                height: 100%;
                max-height: none;
                max-width: none;
                box-sizing: border-box;
            }

            .file-uploader__drag-and-drop-only{
                cursor:auto;
            }

            display: flex;
            position: relative;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin-top: 165px;
            width: 545px;
            height: 320px;
            border: 1px dashed #2D2D2D;
            border-radius: 4px;

            .upload-text{
                margin: 15px 0 35px;
                width: 231px;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                text-align: center;
                color: #000000;
            }

            .upload-button{
                position: relative;
                background: #0154A1;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: #FFFFFF;
            }
        }

    }
}