.input-batch-booking__container__orderplanning {
    
    .section-head__container {
        height: 64px;
        justify-content: center;
        align-items: center;
        display: flex;
        border-bottom: 0.5px solid #b0b0b0;
        box-sizing: border-box;
        .head-title__container {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 27px;
            align-items: center;
            text-align: center;
            text-transform: capitalize;

            color: #2d2d2d;
        }
    }

    .section-body__container {
        padding: 19px 0;
        .modal-item__container {
            .label {
                color: #000;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 8px;

                -webkit-user-select: none; /* Safari */
                -ms-user-select: none; /* IE 10 and IE 11 */
                user-select: none; /* Standard syntax */
            }

            .input-container__disabled{
                width: 235px;
                height: 38px;
                background: #D7D7D7;
                border-radius: 4px;
                display: flex;
                align-items: center;
                padding-left: 12px;
                border: 0.5px solid #D7D7D7;

                color: #2D2D2D;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                -webkit-user-select: none; /* Safari */
                -ms-user-select: none; /* IE 10 and IE 11 */
                user-select: none; /* Standard syntax */
           
            }

            .counter-button-plus__container,
            .counter-button-minus__container{
                width: 32px;
                height: 38px;
                border: 1px solid #d7d7d7;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
            
            .counter-button-plus__container{
                border-radius: 0;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
            .input-container::placeholder {
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: #939393;
            }

            .input-container {
                font-size: 14px;
                line-height: 21px;
                color: #2d2d2d !important;

                -webkit-user-select: none; /* Safari */
                -ms-user-select: none; /* IE 10 and IE 11 */
                user-select: none; /* Standard syntax */
           
            }

            .required-field__text {
                color: red;
                margin-top: 4px;
                font-size: 12px;
                font-family: 'Lato';
            }

            .input-container{
                border: none;
                border-bottom: 1px solid #d7d7d7;
                border-top: 1px solid #d7d7d7;
                text-align: center;
                box-sizing: border-box;
                height: 38px;
                width: 100%;
                padding-left: 16px;
                background: white;
                cursor: pointer;
                flex: 1;
                font-style: normal;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                outline: grey;

                color: #939393;

                span:nth-child(1) {
                    text-align: left;
                }
            }
        }
        .section-card_container {
            border-bottom: 0.5px solid #b0b0b0;
            box-sizing: border-box;
        }

        .section-card-input_container {
            padding: 0px 22px 0px 22px;
        }
    }

    .text-semi-title__container {
        font-style: bold;
        font-weight: 700;
        font-size: 23px;
        color: black;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 26px;
    }

    .text-semi__container {
        font-style: bold;
        font-weight: 600;
        font-size: 14px;
        color: black;
        margin-bottom: 26px;
        margin-top: 32px;
    }

    .input-container,
    select,
    .menu-button {
        border: 1px solid rgb(165, 165, 165);
        box-sizing: border-box;
        height: 40px;
        width: 100%;
        padding-left: 16px;
        border-radius: 0;
        background: white;
        cursor: pointer;
        flex: 1;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;

        outline: grey;

        color: #939393;

        span:nth-child(1) {
            text-align: left;
        }
    }

    .section-footer__container {
        display: flex;
        justify-content: flex-end;
        padding: 0 44px 38px;
        margin-top: 12px;

        .button-add__container {
            width: 68px;
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            color: white;
            background: #1746a2;

            height: 32px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-left: 16px;
        }

        .button-close__container {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            color: #1746a2;
            border: 2px rgb(225, 225, 225) solid;
            background: white;
            width: 68px;
            height: 32px;

            border: 1px solid #1746a2;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

        }
    }
}
