@import '@styles/baseColor';

.login-page__container {
    width: 100vw;
    height: 100vh;
    font-family: 'Poppins';
    background-image: url('./blue-bg.jpg');
    display: flex;
    justify-content: flex-end;

    .image-login__container {
        flex-grow: 5;
    }

    .box-login__container {
        display: flex;
        flex-direction: column;
        width: 50%;
        max-width: 772px;
        align-items: center;
        justify-content: center;
        box-shadow: 0 1px 6px 0 var(--color-shadow, rgba(49, 53, 59, 0.12));
        background: #fff;
        vertical-align: middle;

        .alert__login-page {
            width: 60%;
            margin-bottom: 20px;
            font-size: 16px;
        }

        .box-login__body {
            width: 390px;
            // margin-top: 109px;
        }
    }

    @media (max-width: 1400px) {
        .box-login__container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .box-login__body {
                width: 390px;
                margin-top: 0;
            }

            .section-top__container__login-page {
                .title {
                    margin-bottom: 48px;
                    font-size: 1rem;
                    line-height: 24px;
                }

                .text {
                    font-size: 0.8rem;
                }
            }

            .section-field-input__container__login-page {
                .box-remember__container {
                    .chakra-checkbox__control {
                        width: 12px;
                        height: 12px;
                    }

                    .chakra-checkbox__label {
                        font-size: 10px;
                        margin-left: 8px;
                    }
                }
                .field-input__item {
                    margin-bottom: 16px;

                    .label {
                        font-size: 12px;
                        margin-bottom: 6px;
                    }

                    .input-login {
                        height: 36px;
                        font-size: 12px;
                    }

                    .input-login::placeholder {
                        font-size: 12px;
                    }
                }

                .icon-eye__container {
                    top: 9px;
                }
            }

            .section-bottom__container__login-page .button-login__login-page {
                height: 36px;
                margin-top: 64px;
                font-size: 12px;
            }
        }
    }
}
