.table-head__container__material-configuration {
    width: max-content;
    border-radius: 4px;
    position: sticky;
    top: 0;
    z-index: 2;
    background: white;

    .active-margin__right {
        margin-right: 20px;
        margin-left: 10px;
    }

    .table-head__item:first-child {
        position: sticky;
        z-index: 5;
        left: 0;
    }

    .table-head__item:nth-child(2) {
        position: sticky;
        z-index: 5;
        left: 40px;
    }

    .table-head__item:nth-child(3) {
        position: sticky;
        z-index: 5;
        left: 180px;
        border-right: 1px solid #cecece;
    }

    .table-head__item:nth-child(8) {
        position: sticky;
        text-align: center;
    }

    .table-head__item:nth-child(9) {
        position: sticky;
        text-align: center;
    }

    .table-head__item:nth-child(1) {
        .table-head__parent {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .table-head__item:nth-child(2) {
        .table-head__parent {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .table-head__item:nth-child(3) {
        .table-head__parent {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .table-head__item:nth-child(4),
    .table-head__item:nth-child(5),
    .table-head__item:nth-child(7) {
        .table-head__parent {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .table-head__item:nth-child(8) {
        .table-head__parent {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }

    .table-head__item:nth-child(10) {
        .table-head__parent {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
    .table-head__item:nth-child(11) {
        .table-head__parent {
            padding: 0px 0px 0px 37px;
        }
    }

    .table-head__item:nth-child(31) {
        .table-head__parent {
            display: flex;
            align-items: last baseline;
            text-align: left;
            text-transform: capitalize;
        }
    }

    .table-head__item:nth-child(32) {
        .table-head__parent {
            display: flex;
            align-items: last baseline;
            text-align: left;
        }
    }

    .table-head__item:nth-child(39) {
        .table-head__parent {
            display: flex;
            align-items: last baseline;
        }
    }

    .table-head__item:nth-child(40) {
        .table-head__parent {
            display: flex;
            align-items: last baseline;
        }
    }

    .table-head__item:nth-child(46) {
        .table-head__parent {
            display: flex;
            align-items: last baseline;
        }
    }

    .text-tooltip-label {
        font-family: var(--main-font);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
        color: white;
    }

    .table-head__item {
        display: flex;
        align-items: center;
        text-transform: capitalize;

        font-family: var(--main-font);
        font-style: normal;
        color: #2d2d2d;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;

        @media (min-width: 1441px) {
            font-size: 12px;
        }

        .table-head__parent {
            width: 100%;
            height: 40px;
            align-items: center;
            font-family: var(--main-font);
            font-style: normal;
            color: #2d2d2d;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            text-align: left;
            background: #edf0f6;
            margin-bottom: 4px;
            border-top: 2px solid #f0f2ef;
            border-bottom: 2px solid #f0f2ef;

            @media (min-width: 1441px) {
                font-size: 12px;
            }
        }

        .table-head__child-container {
            display: flex;
            align-items: center;
            flex-direction: column;
            flex: 1;

            width: 100%;
            height: 16px;
            background: #ededed;
        }

        .table-head__child,
        .table-head__grand-child {
            flex: 1;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding-left: 10px;

            font-family: var(--main-font);
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 18px;
            text-transform: capitalize;
            color: #2d2d2d;
        }

        .table-head__child {
            padding-bottom: 4px;
            margin-bottom: 4px;
            width: 100%;
            height: 16px;
        }

        .table-head__child-container {
            .table-head__child {
                padding-bottom: 0;
                margin-bottom: 0;

                p {
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
    }

    .table-head__item:first-child {
        .table-head__parent,
        .table-head__child-container {
            border-left: 2px solid #f0f2ef;
            border-radius: 4px 0 0 4px;
        }
    }

    .table-head__item:last-child {
        position: sticky;
        right: 0;
        z-index: 5;
        border-left: 1px solid #cecece;
        .table-head__parent,
        .table-head__child-container {
            justify-content: center;
            border-radius: 0 4px 4px 0;
            border-right: 2px solid #f0f2ef;
            text-align: center;
        }
    }
}
