.page-container {
    display: flex;
    justify-content: flex-start;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: #ffffff;

    .dashboard-section-left__container {
        width: max-content;
        transition: 0.25s;
    }

    .hide-side__menu-container {
        // transform: translateX(-253px);
        width: 0;

        .side-menu__wrapper {
            opacity: 0;
            transition: 0.25;
        }
    }

    .dashboard-body__container {
        .dashboard-content__container {
          

            .section-production-status__container__main-dashboard,
            .section-order__container__main-dashboard {
                width: calc(100vw - 96px) !important;
            }

            .section-table__container__order-planning {
                width: calc(100vw - 130px);
            }

            .section-table__container__sku-configuration {
                width: calc(100vw - 134px);
            }

            .section-table-material-configuration{
                width: calc(100vw - 134px);
           }

           .section-table-raw-data_order {
                width: calc(100vw - 134px) !important;
           }
           
           .section-table-demand_anticipation {
                width: calc(100vw - 134px);
           }

           .section-table-supplier_stock {
                width: calc(100vw - 134px) !important;
           }

           .section-table-order_management {
                width: calc(100vw - 134px) !important;
           }

           .section-table__add-new-item__configuration {
             width: calc(100vw - 134px);
           }

           .section-table-order_confirmation {
            width: calc(100vw - 130px) !important;
           }

           .section-table__container__table-rekap-delivery {
            width: calc(100vw - 130px) !important;
           }

           .section-table-order_delivery {
            width: calc(100vw - 130px) !important;
           }

           .section-table-archived-list-configuration {
             width: calc(100vw - 130px);
           }

            .section-add-new-item__configuration {
                width: calc(100vw - 130px);
            }
            
            .section-table__container__streamline-management {
                width: calc(100vw - 98px);
            }

            .section-table__container__user-management,
            .section-table__container__asset-management {
                width: calc(100vw - 130px);
            }
        }
    }

    .icon-expand__container-floating {
        position: fixed;
        left: -6px;
        height: max-content;
        top: 40px;
        z-index: 10;
        background: white;
        padding: 8px;
        border-radius: 6px;
        border: 1px solid grey;
        cursor: pointer;
        animation-name: animate;
        animation-duration: 0.5s;
    }

    @keyframes animate {
        0% {
            transform: translateX(-60px);
        }

        100% {
            transform: translateX(0);
        }
    }

    .dashboard-section-right__container {
        flex: 1;
    }

    .dashboard-content__container {
        padding: 26px 24px;
        position: relative;
        height: 100%;
        overflow: auto;
    }

    .active-paddingTop {
        padding-top: 86px;
    }

    .dashboard-content__container::-webkit-scrollbar {
        display: none;
    }

    .header-lobby-page__container {
        width: 100%;
        height: 65px;
        padding: 22px 0px;
        padding-right: 23px;
        display: flex;
        background: #ffffff;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        z-index: 2;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

        .img-user {
            width: 24px;
            height: 24px;
        }

        .text-user {
            font-family: var(--main-font);
            font-weight: 500;
            font-style: normal;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            text-transform: capitalize;
            color: black;
        }

        .square-label {
            height: 24px;
            border-radius: 4px;
            background: rgba(166, 191, 239, 0.4);
            border: 0.5px solid #1746a2;
            padding: 0px 4px;

            font-family: var(--main-font);
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 22px;
            text-align: center;
            text-transform: capitalize;
            color: #1746a2;
        }
    }

    .lobbypage-img-bg {
        background-image: url('./images/rectangle-bg.png'), url('./images/vectary-texture-bg.png');
        background-position: left center, center;
        background-repeat: no-repeat, no-repeat;
        background-size: auto, cover;
        flex-grow: 5;
        width: 100vw;
        height: 100vh;

        .dashboard-content__container{
            padding-top: 86px !important;
        }
    }
}
