.modal-container-input-week-demand-anticipation {
    .modal-input-week-header {
        justify-content: space-between !important;
        width: 100% !important;
        padding: 13px 18px;
        border-bottom: 1px solid #b7b7b7;

        .modal-header-text {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height */

            color: #2d2d2d;
        }
    }
    .modal-body {
        max-height: 539px;
        overflow: hidden;
        padding: 16px 18px;

        .fieldMenu-placeholder {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height */

            color: #9c9898;
        }

        .selected-week-text {
            font-family: 'Poppins' !important;
            font-style: normal !important;
            font-weight: 500 !important;
            font-size: 14px !important;
            line-height: 21px !important;
            color: #2d2d2d !important;
        }
        .menu-button {
            border: 1px solid rgb(165, 165, 165);
            box-sizing: border-box;
            height: 48px !important;
            width: 100% !important;
            padding-left: 16px;
            border-radius: 4px;
            background: white;
            cursor: pointer;
            flex: 1;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            border: 0.5px solid #d7d7d7 !important;
            border-radius: 4px !important;
            outline: grey;

            color: #2d2d2d;

            span:nth-child(1) {
                text-align: left;
            }
        }
        .menu-list-container {
            max-height: 300px;
            overflow: auto;
            width: 508px !important;
        }
        .menu-list-container::-webkit-scrollbar-track {
            background: #b7b7b7 !important;
            border: solid #ffffff 7px !important;
        }

        .menu-list-container::-webkit-scrollbar {
            height: 6px !important;
            width: 15px !important;
        }

        .menu-list-container::-webkit-scrollbar-thumb {
            border-radius: 100px !important;
            background: #4163a4 !important;
            border: solid #fff 5.7px;
            border-bottom: transparent !important;
            border-top: transparent !important;
        }
    }
    .modal-Footer {
        gap: 12px;
        padding: 18px 20px 20px 20px;
        .btn-submit {
            width: 95px;
            height: 32px;
            padding: 7px 12px;
            background: #4163a4;
            border: 1px solid #0154a2;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            cursor: pointer;

            .text-submit {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height */

                color: #ffffff;
            }
        }
        .disabled-btn-submit {
            background: #4163a4;
            opacity: 0.3;
            cursor: no-drop;
        }
    }
}
