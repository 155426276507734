.section-table__container__table-order_management {
    width: calc(100vw - 330px);
    overflow-x: scroll;
    position: relative;
    height: calc(100vh - 337px);
    padding-bottom: 16px;

    .section-table {
        height: calc(100vh - 360px);
    }
}

// ---media-query 1500px to 1024px 
@media only screen and (max-width: 1500px) and (min-width: 1024px) {
    .section-table__container__table-order_management::-webkit-scrollbar-track,
    .section-table__container__table-order_management::-webkit-scrollbar,
    .section-table__container__table-order_management::-webkit-scrollbar-thumb {
        display: none;
    }
}

.section-table__container__table-order_management::-webkit-scrollbar-track {
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    background: rgba(181, 176, 176, 0.3);
}

.section-table__container__table-order_management::-webkit-scrollbar {
    height: 7px;
    width: 0;
}

.section-table__container__table-order_management::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: #4163a4;
}

.hide-scrollbar-section-table__container__table-order_management {
    height: max-content;

    .section-table {
        height: max-content;
    }
}