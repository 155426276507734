.side-menu-hide__wrapper {
    border-right: 1px solid var(--default-grey);
    width: 64px;
    height: 100vh;

    .side-menu__container {
        .section-head__side-menu {
            padding: 18px;
            position: relative;
            height: 72px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .icon-expand__container {
                cursor: pointer;
            }
        }

        .section-body__side-menu {
            padding: 32px 8px 32px 6px;

            .section-body-item__side-menu {
                cursor: pointer;
                height: 40px;
                margin-bottom: 16px;

                .section-body-item__text {
                    font-family: var(--main-font);
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    text-transform: capitalize;
                    color: #2d2d2d;
                    margin-left: 12px;
                }
            }

            .section-body-item__side-menu:hover {
                background: var(--main-color-3);
                opacity: 0.5;
                border-radius: 4px;
            }

            .section-body-item__side-menu__active {
                background: var(--main-color-5);
                border-radius: 4px;
                .section-body-item__text {
                    color: white;
                    font-weight: 500;
                }
            }

            .username-container {
                padding: 32px 22px;
                border-top: 1px solid var(--default-grey);
                cursor: pointer;
                .username-text__global-header {
                    font-family: var(--main-font);
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    margin: 0 32px 0 10px;
                    color: #2d2d2d;
                }
            }
            .back-to-main {
                cursor: pointer;
                margin-top: 30px;
                align-items: center;
                .icon-container {
                    border: #2d2d2d 2px solid;
                    border-radius: 100%;
                    width: 24px;
                }
            }
        }
    }
}
