.table-column__container__section-archived-list {
    .column__item {
        display: flex;
        padding-top: 11px;
        padding-bottom: 11px;
        // padding: 8px 0 8px;
        padding-left: 10px;
        border-bottom: 1px solid #cecece;
        // padding-bottom: 8px;

        .checkbox-active {
            height: 15px;
            width: 15px;
            border: 1px solid #BBBBBB;
            outline: none;
        };

        .checkbox-disable {
            height: 15px;
            width: 15px;
            border: 1px solid #2D2D2D;
            outline: none;
        }


        .column-status {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 19px;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: capitalize;
            color: var(--default-black);
            padding: 2px 16px;
            background: #e8e7e7;
            border-radius: 43px;

            @media (min-width: 1441px) {
                font-size: 14px;
            }
        }

        .column-text {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-transform: capitalize;
            color: #2D2D2D;
            text-align: left;

            @media (min-width: 1441px) {
                font-size: 12px;
            }
        }

        .text-ellipsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .column-text-status {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            text-align: center;
        }
    }

    .column__item:last-child {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    .column__item-child {
        display: flex;
        align-items: center;
        width: 100%;

        .column__item-grand-child {
            width: 100%;
            display: flex;
            align-items: center;
        }

        .column-text {
            flex: 1;
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-transform: capitalize;
            color: #2D2D2D;

            @media (min-width: 1441px) {
                font-size: 12px;
            }
        }
    }
    .column__item:last-child {
        .column-text {
            color: #B7B7B7;
        }
    }
}
