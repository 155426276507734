.table-head__container__order-planning {
    width: max-content;

    .floating-action-head {
        position: sticky;
        right: 0;
        border-radius: 0px;
        display: flex;
        justify-content: flex-end !important;
        flex-direction: column;
        align-items: flex-end !important;
        height: max-content;

        p {
            background: #e8ecf6;
            border-width: 2px 2px 2px 0px;
            line-height: 29px;
            width: 100px;

            margin-bottom: 4px;
        }

        .grey-box {
            line-height: 28px !important;
            width: 100px;
            align-items: center !important;
            background: #ededed;
            text-align: center !important;
        }
    }

    .table-head__item:first-child {
        .table-head__parent,
        .table-head__child {
            padding-left: 12px;
        }
    }

    .table-head__item:last-child {
        .table-head__child {
            padding-right: 12px;
        }
    }

    #table-head__item--0,
    #table-head__item--1 ,
    #table-head__item--2 {
        position: sticky;
        left: 0;
        z-index: 2;
    }

    #table-head__item--1 {
        left: 164px;
    }

    #table-head__item--2 {
        left: 284px;
        border-right: 1px solid #cecece;
    }


    .table-head__item {
        display: flex;
        align-items: center;

        font-family: var(--main-font);
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #2d2d2d;

        @media (min-width: 1441px) {
            font-size: 14px;
        }

        .table-head__parent {
            width: 100%;
            align-items: center;
            background: #e8ecf6;
            height: 33px;
            font-weight: 500;
            font-size: 12px;
            line-height: 21px;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 4px;
            padding-left: 12px;

            @media (min-width: 1441px) {
                font-size: 14px;
            }
        }

        .table-head__child-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            flex: 1;
            width: 100%;
            height: 28px;

            .table-head__child{
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 0 !important;
            }
        }

        .table-head__child,
        .grey-box {
            padding-left: 12px;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;

            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            background: #ededed;
            color: #2d2d2d;

            @media (min-width: 1441px) {
                font-size: 14px;
            }
        }


        .table-head__parent-multiple-child {
            justify-content: center;
            padding-left: 0px;

            .table-head__child{
                text-align: center;
            }
        }

        .table-head__child:first-child {
            // padding-left: 12px;
        }

        .table-head__child {
            width: 100%;
            font-size: 12px;

         
        }
    }

    #table-head__item--2 {
        .table-head__parent {
            padding-left: 12px;
        }

        .table-head__child {
            padding-left: 12px;
        }
    }

    .table-head__item__grey-box {
        border-left: 1px solid #cecece;
    }
}
