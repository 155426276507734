.table-column__container__product-detail-status {
    .column-sticky__item {
        padding-left: 15px;
        width: 100%;
        font-family: var(--main-font);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #001330;
        padding: 15px;
        .column-status {
            width: fit-content;
            padding: 5px;
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 19px;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: capitalize;
            background: #c5c5c5;
            border-radius: 4px;
        }
    }
}

.detail-prodcut-status__container::-webkit-scrollbar {
    display: none;
}

.card-table__container {
    height: calc(100% - 200px);
    overflow-y: auto;
}

.detail-prodcut-status__container {
    position: relative;
}
