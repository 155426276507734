.section-table-order-confirmation__configuration {
    padding: 22px 15px;
    font-family: var(--main-font);
    background: white;
    border: 0.5px solid #d7d7d7;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 2;
}

// ---media-query for zoom 67%
@media (min-width: 1724px) and (max-width: 2051px) {
    .section-table-order-confirmation__configuration {
        width: calc(100vw - 288px);
    }
}

// ---media-query for zoom 50%
@media (min-width: 2052px) and (max-width: 2732px) {
    .section-table-order-confirmation__configuration {
        width: calc(100vw - 927px);
        // background: yellow;
    }
}

// ---media-query for zoom 33%
@media (min-width: 2733px) and (max-width: 4102px) {
    .section-table-order-confirmation__configuration {
        width: calc(100vw - 2203px);
    }
}

// ---media-query for zoom 25%
@media (min-width: 4103px) and (max-width: 5464px) {
    .section-table-order-confirmation__configuration {
        width: calc(100vw - 3481px);
    }
}

.section-table-order-confirmation__configuration::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.section-table-order-confirmation__configuration {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
