.modal-container-filter-multiple-supplier-stock {
    .modal-body {
        max-height: 123px;
        overflow: hidden;
        padding: 0px 18px 32px 18px;
        border-radius: 4px;
        border: 0.5px solid #D7D7D7;
        background: #FFF;

        .container-input {
            .fieldMenu-container-single {
                
                .label {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    /* identical to box height */
                    color: #2d2d2d;
                    margin-bottom: 12px;
                    margin-top: 16px;
                }
                .FieldMenu-placeholder {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 18px;
                    /* identical to box height */

                    color: #b7b7b7;
                }
                .menuList-container {
                    // max-height: 300px;
                    overflow: auto;
                    width: 216px;
                    padding-top: 0px;
                    padding-bottom: 0px;
                    border: 0.5px solid #929191;
                    border-radius: 4px;
                    border-bottom: none;
                }
                .menu-button {
                    box-sizing: border-box;
                    height: 32px !important;
                    padding-left: 16px;
                    border-radius: 4px;
                    background: white;
                    cursor: pointer;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    border-radius: 2px;
                    border: 0.5px solid #2d2d2d;
                    outline: grey;

                    color: #2d2d2d;

                    span:nth-child(1) {
                        text-align: left;
                    }
                }
                .menu-item {
                    height: 38px;
                    border-bottom: 0.5px solid #929191;
                    background: white;
                }
            }
        }
    }
}
