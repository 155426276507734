.table-column__wrapper__streamline-management {
    background: #ffffff;
    border: 2px solid #e8ecf6;
    border-radius: 8px;
    margin-bottom: 12px;

    .expand-button__container {
        border-top: 2px solid #e4e4e4;
        height: 52px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        padding-right: 46px;

        .expand-text {
            color: #4163a4;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            margin-right: 8px;
        }
    }

    .table-column__container__streamline-management {
        padding: 24px 52px 0 52px;
        margin-bottom: 24px;
        overflow-y: hidden;
        max-height: 92px;

        .column-sticky__item:first-child {
            .column-text::before {
                content: '';
                display: none;
            }
        }

        .column-sticky__item {
            display: flex;
            height: max-content;
            justify-content: flex-start;

            .checkbox-input>span {
                border-radius: 50%;
                height: 20px;
                width: 20px;
            }

            .column-status {
                font-family: var(--main-font);
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                display: flex;
                align-items: center;
                text-align: center;
                text-transform: capitalize;
                color: var(--default-black);
                padding: 2px 16px;
                background: #e8e7e7;
                border-radius: 43px;
            }

            .column-text {
                font-family: var(--main-font);
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #001330;
                padding: 4px 0;
                height: max-content;
                position: relative;
                text-overflow: ellipsis;
                margin-bottom: 16px;
            }

        }
    }



    .icon-edit {
        border: 1px solid #1746A2;
        border-radius: 4;
        padding: 5;
    }
    .icon-delete {
        border: 1px solid #EF5350;
        border-radius: 4;
        padding: 5;
    }


    .rotate-arrow {
        transform: rotate(180deg);
    }

    .unset-max-height {
        max-height: none;
    }
}