.section-upload__container__material-configuration {
    background: #ffffff;
    border: 0.5px solid #dcdcdc;
    border-radius: 8px;
    margin-bottom: 50px;

    .parent {
        height: 649px;
        display: flex;
        justify-content: center;
        align-items: center;
        .child {
            width: 200px;
            text-align: center;
            .cancel-button {
                padding: 5px 12px !important;
                color: #1746a2 !important;
                font-size: 12px !important;
                font-family: Poppins !important;
                font-weight: 500 !important;
                height: 32px !important;
            }
            .text-loading {
                color: #000;
                font-size: 12px;
                font-family: Poppins;
                margin-top: 12px;
                margin-bottom: 29px;
            }
        }
    }

    .head {
        padding: 25px 17px;
        align-items: center;
        justify-content: space-between;

        .left-section {
            column-gap: 10px;
            align-items: center;
            cursor: pointer;

            .title-label {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                /* identical to box height */

                color: #2d2d2d;
            }
        }
        .right-section {
            cursor: pointer;
            width: 173px;
            height: 32px;
            border: 1px solid #0154a2;
            border-radius: 2px;
            column-gap: 8px;
            justify-content: center;
            align-items: center;
            background-color: white;

            .title-download {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height */

                color: #4163a4;
            }
        }
    }
    .body {
        height: 672px;
        display: flex;
        justify-content: center;
        align-items: center;
        .section-body {
            // display: flex;
            // justify-content: center;
            // align-items: center;

            .upload-box {
                display: flex;
                position: relative;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                width: 545px;
                height: 320px;
                border: 1px solid #2d2d2d;
                border-radius: 4px;

                .parent-line-vertical {
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    gap: 5%;

                    .child-line-vertical {
                        background-color: rgb(255, 255, 255);
                        width: 20px;
                        height: 320px;
                        margin-top: -1px;
                    }
                }
                .parent-line-horizon {
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 5%;

                    .child-line-horizon {
                        background-color: rgb(255, 255, 255);
                        width: 546px;
                        height: 20px;
                        margin-top: -1px;
                        margin-left: -1px;
                    }
                }

                .file-uploader,
                .file-uploader__drag-and-drop-only {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    cursor: pointer;
                    opacity: 0;
                    height: 100%;
                    max-height: none;
                    max-width: none;
                    z-index: 4;
                    box-sizing: border-box;
                }

                .file-uploader__drag-and-drop-only {
                    cursor: auto;
                    z-index: 4;
                }

                .before-drop {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: rgb(255, 255, 255);
                    border-radius: 4px;
                    display: flex;
                    position: relative;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    transition: 0.2s;
                }
                .after-drop {
                    position: absolute;
                    background-color: #e2e2e2;
                    width: 100%;
                    border-radius: 4px;
                    height: 100%;
                    z-index: 2;
                    justify-content: center;
                    align-items: center;
                    display: flex;

                    .after-drop-title {
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 900;
                        font-size: 25px;
                        line-height: 18px;
                        text-align: center;
                        color: #919191;
                    }
                }
                .upload-text {
                    margin: 15px 0 35px;
                    width: 231px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    text-align: center;
                    color: #000000;
                }

                .upload-button {
                    height: 31.56px;
                    border-radius: 2px;
                    position: relative;
                    background: #1746a2;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    color: #ffffff;
                    z-index: 9 !important;
                }
            }
        }
    }
    .container-table-view {
        padding: 16px 34px;
        overflow: auto;
        .btn-action-group {
            gap: 13.09px;
            justify-content: flex-end;
            margin-top: 44px;
            margin-bottom: 38.44px;
            .cancel-button {
                height: 31.56px !important;
                width: 119.21px !important;
                font-family: Poppins !important;
                font-size: 11px !important;
                font-weight: 600 !important;
                line-height: 16px !important;
                letter-spacing: 0em !important;
                text-align: left !important;
            }
            .save-button {
                height: 31.56px !important;
                width: 118.039px !important;
                border-radius: 2px;
                position: relative;
                background: #1746a2;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: #ffffff;
                z-index: 9 !important;
            }
        }
        .label-table {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height */
            margin-bottom: 16px;
            color: #1746a2;
        }
    }
    .container-table {
        overflow: auto;
    }
    .container-table::-webkit-scrollbar-track {
        border-top: 2px solid white;
        border-bottom: 2px solid white;
        background: rgba(181, 176, 176, 0.3);
    }

    .container-table::-webkit-scrollbar {
        height: 7px;
        width: 0px;
    }

    .container-table::-webkit-scrollbar-thumb {
        border-radius: 16px;
        background-color: #4163a4;
    }
}
