.table-head__container__detail-product-status {
    width: 100%;
    margin-top: 24px;
    background: var(--default-grey);

    .table-head__item {
        display: flex;
        text-transform: uppercase;
        padding-left: 15px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height */

        text-align: center;
        text-transform: uppercase;

        color: #2d2d2d;
        justify-content: flex-start;
        align-items: center;
        height: 33px;
        background: #d1daec;
    }

    .table-head__item:first-child {
        border-radius: 4px 0px 0px 4px;
    }

    .table-head__item:last-child {
        border-radius: 0px 4px 4px 0px;
    }
    .table-head__item-name {
        width: 200px;
        text-align: left !important;
    }
}
