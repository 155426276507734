.table-column__container__asset-management {
    .column-sticky__item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 46px;
        padding-left: 32px;
        .checkbox-input > span {
            border-radius: 50%;
            height: 20px;
            width: 20px;
            border: 1px solid grey;
        }

        .column-status {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: capitalize;
            color: var(--default-black);
            padding: 2px 16px;
            background: #e8e7e7;
            border-radius: 43px;
        }

        .column-text {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            color: #001330;
            text-transform: uppercase;
        }
    }

    .column-sticky__item:last-child {
        justify-content: center;
    }
}
