.form-complete-data-container {
    padding: 23px 15px 0px 15px;
    .complete-data-container {
        display: flex;
        gap: 73px;
        .left-side {
            width: 267px;
            .title {
                color: #2d2d2d;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
            .description {
                color: #767676;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 166.667% */
                max-width: 267px;
            }
        }
        .right-side {
            .label-input-item-code {
                color: #2d2d2d;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            .input-item-code {
                height: 48px;
                margin-top: 6px;
                border-radius: 4px;
                border: 0.5px solid #d7d7d7;
                padding: 15px 17px;
                /// text
                color: var(--Black, #2d2d2d);
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                min-width: 706px;
                outline: none;
            }
            .input-item-code::placeholder {
                color: #9c9898;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            .infinite-scroll-container {
                .enabled-input-supplier-name {
                    border: 0.5px solid #d7d7d7 !important;
                    padding-right: 60px;
                }
                .enabled-input-supplier-name::placeholder {
                    color: #9c9898;
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
                .required-field__text {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 15px;
                    color: #ef5350;
                    margin-top: 4px;
                }
                .divider_input_infinite_scroll {
                    height: 29px;
                    width: 1px;
                    background-color: #d7d7d7;
                    margin-right: 14px;
                }
                .scroll-box-container {
                    max-height: 310px;
                    overflow: auto;
                    background-color: rgb(255, 255, 255);
                    width: 706px;
                    border-radius: 4px;

                    .loading_fetch_next_page {
                        color: var(--black, #757575);
                        font-family: Open Sans;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        margin-left: 12px;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        text-align: center;
                    }

                    .no_data_infinite_scroll {
                        color: var(--black, #757575);
                        font-family: Open Sans;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        text-align: center;
                        position: absolute;
                        left: 0;
                        right: 0;
                        background-color: #ffffff;
                        border-left: 1px solid #e2e8f0;
                        border-right: 1px solid #e2e8f0;
                    }
                    .list_item_infinite {
                        color: var(--black, #2d2d2d) !important;
                        font-family: Open Sans !important;
                        font-size: 12px !important;
                        font-style: normal !important;
                        font-weight: 600 !important;
                        line-height: normal !important;
                        padding: 10px 10px;
                        border-radius: 2px;
                        border-bottom: 0.5px solid #d7d7d7;
                    }
                    .list_item_infinite:hover {
                        background-color: #b9c7e3;
                        color: #fff !important;
                        cursor: pointer;
                    }
                    .list_item_infinite:active {
                        background-color: #e0e0e0;
                    }
                }
            }
            .btn-generate {
                background-color: #fff;
                margin-top: 24px;
                float: right;
                width: fit-content;
                height: 32px;
                padding: 5px 12px;
                border-radius: 2px;
                border: 1px solid #0154a1;
                color: #4163a4;
                display: flex;
                align-items: center;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
    }
}
