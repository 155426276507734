.create-and-edit-modal__container__assetmanagement {
    width: 544px;
    border-radius: 8px;
    background: white;
    padding-bottom: 25px;

    .field-menu-list__container {
        max-height: 300px;
        overflow: auto;
    }

    .section-head__container {
        padding: 16px;
        border-bottom: 1px solid #d7d7d7;
        .head-title__container {
            font-family: var(--main-font);
            font-weight: 700;
            text-align: center;
            font-size: 18px;
            line-height: 27px;
            color: #2d2d2d;
        }
    }
    .section-body__container {
        padding: 16px;
        border-bottom: 1px solid #d7d7d7;
        margin-bottom: 33px;

        .modal-item__container {
            margin-bottom: 16px;

            .menu-button {
                width: 100%;
                background: white !important;
                display: flex;
                justify-content: space-between;

                border: 1px solid #8b8b8b !important;
                border-radius: 4px !important;

                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                text-align: center;

                color: #939393;

                .chakra-button__icon {
                    margin-left: 32px;
                }

                span:nth-child(1) {
                    flex: 0;
                }
            }

            .label {
                font-family: var(--main-font);
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 8px;
                display: flex;
                color: #2d2d2d;
            }

            .required-field__text {
                color: red;
                margin-top: 4px;
                font-size: 12px;
                font-family: 'Lato';
            }

            .input-container,
            select,
            .menu-button {
                border: 1px solid rgb(165, 165, 165);
                box-sizing: border-box;
                height: 44px;
                width: 350px;
                padding-left: 16px;
                border-radius: 4px;
                background: white;
                outline: none;
                margin-bottom: 14px;
            }

            .input-container-invalid {
                border: 1px solid red;
                box-sizing: border-box;
                height: 44px;
                width: 350px;
                padding-left: 16px;
                border-radius: 4px;
                background: white;
                outline: none;
                margin-bottom: 14px;
            }

            .input-container::placeholder {
                font-family: var(--main-font);
                font-weight: 500;
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                color: #9c9898;
                text-align: left;
            }

            .input-container-invalid::placeholder {
                font-family: var(--main-font);
                font-weight: 500;
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                color: #9c9898;
                text-align: left;
            }

            .button-delete-asset {
                background: white;
                border: 1px solid #ef5350;
                width: 155px;
                height: 44px;
                font-size: 12px;
                line-height: 18px;
                font-weight: 500;
                color: #ef5350;
                text-align: center;
                font-family: var(--main-font);
                border-radius: 4px;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                cursor: pointer;
            }

            .button-add-asset {
                background: white;
                border: 1px solid #1746a2;
                width: 155px;
                height: 44px;
                font-size: 12px;
                line-height: 18px;
                font-weight: 500;
                color: #1746a2;
                text-align: center;
                font-family: var(--main-font);
                border-radius: 4px;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .wrap-asset-body {
        max-height: 544px;
        overflow-y: auto;
    }

    .modal-fieldmenu__container {
        padding-left: 16px;
        padding-right: 16px;
        display: flex;
        flex-direction: column;

        .menu-field-asset-button {
            border: 1px solid rgb(165, 165, 165);
            box-sizing: border-box;
            height: 48px;
            width: 100%;
            padding-left: 16px;
            border-radius: 4px;
            background: white;
            cursor: pointer;
            flex: 1;

            font-family: var(--main-font);
            font-weight: 400;
            font-style: normal;
            text-transform: uppercase;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #2d2d2d;
            outline: grey;

            span:nth-child(1) {
                text-align: left;
            }
        }

        .text-default {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #9c9898;
            text-transform: none;
        }

        .text-material {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            font-family: var(--main-font);
            color: #2d2d2d;
            text-align: center;
            margin-bottom: 8px;
        }

        .field-menu-list__container {
            width: 500px;

            .field-menu-item__container {
                height: 48px;
                border-bottom: 1px solid #d7d7d7;
                font-family: var(--main-font);
                font-weight: 400;
                font-style: normal;
                text-transform: uppercase;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #2d2d2d;
            }

            .field-menu-item__container:last-child {
                border-bottom: none;
            }

            .field-menu-item__container:hover {
                background: var(--main-color-3);
                color: white;
            }
        }

        .button-add-account__container {
            width: 100%;
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            color: #2d2d2d;
            background: rgb(225, 225, 225);

            height: 37px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
    .button-wrapper {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        gap: 16px;
        margin-top: 30px;

        .button-save {
            width: 117px;
            height: 42px;
            border-radius: 8px;
            background: #1746a2;
            color: white;
            font-size: 12px;
            line-height: 18px;
            font-weight: 600;
            font-style: normal;
            font-family: var(--main-font);
            text-align: center;
            outline: none;
        }

        .button-cancel {
            width: 117px;
            height: 42px;
            border-radius: 8px;
            background: white;
            color: #1746a2;
            border: 1px solid #1746a2;
            font-size: 12px;
            line-height: 18px;
            font-weight: 600;
            font-style: normal;
            font-family: var(--main-font);
            text-align: center;
            outline: none;
        }
    }
}

.wrap-asset-body::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.wrap-asset-body {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}
