.modal-stop-problem__container {
    .title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #2d2d2d;
        margin-bottom: 13px;
    }

    .desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        text-align: center;
        color: #2d2d2d;
    }
}
