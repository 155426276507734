.modal-select-line__container {
    background: #ffffff;

    .modal-head {
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: #2d2d2d;
        height: 64px;
        border-bottom: 0.5px solid #b0b0b0;
    }

    .modal-body {
        padding: 42px 22px 28px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .modal-body__label {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #2d2d2d;
            margin-bottom: 14px;
        }

        .modal-body__selected-container,
        .modal-body__choice-container {
            flex-wrap: wrap;
            gap: 6px 8px;
            padding: 10px;
            width: 100%;
            margin-bottom: 18px;

            border: 1px solid #d9d9d9;
            border-radius: 4px;

            min-height: 118px;
            .selected-box,
            .choice-box {
                padding: 5px 12px;
                height: 32px;

                border: 1px solid #e4e6e9;
                border-radius: 4px;
                display: flex;
                align-items: center;

                .selected-box__name {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    color: #1746a2;
                    text-align: center;
                    margin-right: 8px;
                }
            }

            .selected-box {
                border: 1px solid #1746a2;
            }
        }

        .modal-body__choice-container {
            border: none;
            padding: 0 !important;
            min-height: auto;
            margin-bottom: 32px;
            .choice-box {
                cursor: pointer;
            }
        }
    }

    .modal-footer__container {
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
        padding-right: 22px;
        gap: 20px;

        button {
            width: 117px;
            height: 42px;

            border-radius: 4px;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;

            color: #2d2d2d;
        }

        .button-submit {
            background: #1746a2;
            color: white;
        }

        .button-cancel {
            border: 1px solid #1746a2;
            color: #1746a2;
            background: white;
        }
    }
}
