.rekap-delivery-box-header {
    justify-content: space-between;
    padding: 0px 0px 35px 0px;
    background: #ffffff;

    .text-order-confirmation {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        /* identical to box height */

        color: #4163a4;
    }
    .action-button-container {
        .divider {
            height: 31px;
            width: 0.5px;
            background-color: #b7b7b7;
            margin: 0px 18px;
        }
        .button-order-confirmation__container {
            color: #4163a4;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            ///
            border-radius: 2px;
            border: 1px solid #0154a1;
            display: flex;
            height: 32px;
            width: 169px;

            align-items: center;
            cursor: pointer;
            padding: 5px 11px;
            margin-right: 12px;
        }
        .button-order-delivery__container {
            color: #4163a4;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            ///
            border-radius: 2px;
            border: 1px solid #0154a1;
            display: flex;
            height: 32px;
            width: 137px;

            align-items: center;
            cursor: pointer;
            padding: 5px 11px;
        }
        .box {
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25) !important;
            margin-right: -17px;
        }
        .box-download-data {
            border: 1px solid #0154A1;
            width: 144px;
            height: 32px;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            
            .text-download-data {
                margin-left: 8px;
                font-family: var(--main-font);
                font-weight: 600;
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                text-transform: capitalize;
                text-align: center;
                color: #4163A4;
            }
        }   
    }
}
