.modal-container-filter-trade-name-component {
    .modal-filter-checked-header {
        justify-content: space-between !important;
        width: 100% !important;
        padding: 13px 18px;
        border-bottom: 1px solid #b7b7b7;

        .modal-header-text {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height */

            color: #2d2d2d;
        }
    }
    .modal-body {
        max-height: 539px;
        overflow: hidden;
        padding: 16px 18px;

        .scroll-container {
            .infinite-scroll-component {
                max-height: 200px;
                overflow: auto;
                display: grid;
                gap: 12px;
                padding-right: 10px;
                padding-top: 2px;
                padding-bottom: 2px;
            }
            .infinite-scroll-component::-webkit-scrollbar-track {
                background: #b7b7b7 !important;
                border: solid #ffffff 7px !important;
            }

            .infinite-scroll-component::-webkit-scrollbar {
                height: 6px !important;
                width: 15px !important;
            }

            .infinite-scroll-component::-webkit-scrollbar-thumb {
                border-radius: 100px !important;
                background: #2d2d2d !important;
                border: solid #fff 5.7px;
                border-bottom: transparent !important;
                border-top: transparent !important;
            }
            .loading_fetch_next_page {
                color: var(--black, #757575);
                font-family: Open Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-left: 12px;
                padding-top: 10px;
                padding-bottom: 10px;
                text-align: center;
            }
            .no_data_infinite_scroll {
                color: var(--black, #757575);
                font-family: Open Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                padding-top: 10px;
                padding-bottom: 10px;
                text-align: center;
                position: absolute;
                left: 0;
                right: 0;
                background-color: #ffffff;
                border-left: 1px solid #e2e8f0;
                border-right: 1px solid #e2e8f0;
            }
            .container-list-trade-name {
                display: flex;
                flex-direction: column;
                gap: 10px;
                .list-item-text {
                    @extend .truncate;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    /* identical to box height */

                    color: #000000;
                }
                .checkbox-custom-style {
                    .css-1ydjfm6 {
                        width: 15px;
                        height: 15px;
                        border-color: #2d2d2d;
                        border-width: 2.5px;
                    }
                    .css-1ydjfm6[data-checked] {
                        background: #1746a2;
                        border-color: #1746a2;
                    }
                }
                .truncate {
                    width: 350px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .label-list-item-text {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height */
            margin-top: 16px;
            color: #b7b7b7;
            margin-bottom: 11px;
        }

        .search-icon {
            position: absolute;
            top: 3.5px;
            right: 12px;
            height: 29px;
            border-left: 0.5px solid #d7d7d7;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 16.25px;
        }

        .input-box {
            width: 508px;
            height: 36px;
            border: 1px solid #b1b1b1;
            border-radius: 2px;
            padding: 9px 12px;
            font-size: 12px;
        }
        .input-box:focus {
            outline: none;
        }
        .input-box::placeholder {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height */

            color: #d7d7d7;
        }

        .label-input {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height */

            color: #2d2d2d;
        }

        .box-selected-item {
            margin-top: 15px;
            margin-bottom: 26px;
            width: 508px;
            height: 128px;
            overflow: auto;
            border: 0.5px solid #2d2d2d;
            border-radius: 2px;
            padding: 11px 10px;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            row-gap: 8px;
            column-gap: 6px;

            .box-card-selected-item {
                justify-content: space-between;
                width: 139px;
                height: 29px;
                padding: 7px 12px;
                border: 1px solid #1746a2;
                border-radius: 4px;
                align-items: center;

                .text-selected-item {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 15px;
                    overflow: hidden;
                    white-space: nowrap;
                    /* identical to box height */
                    text-overflow: ellipsis;
                    color: #1746a2;
                }
            }
        }
        .box-selected-item::-webkit-scrollbar-track {
            background: #b7b7b7 !important;
            border: solid #ffffff 7px !important;
        }

        .box-selected-item::-webkit-scrollbar {
            height: 6px !important;
            width: 15px !important;
        }
        .box-selected-item::-webkit-scrollbar-button {
            height: 4px;
        }

        .box-selected-item::-webkit-scrollbar-thumb {
            border-radius: 100px !important;
            background: #2d2d2d !important;
            border: solid #fff 5.7px;
            border-bottom: transparent !important;
            border-top: transparent !important;
        }
    }
    .modal-footer {
        gap: 12px;
        padding: 20px;
        border-top: 1px solid #b7b7b7;

        .btn-apply-filter {
            width: 95px;
            height: 32px;
            padding: 7px 12px;
            background: #4163a4;
            border: 1px solid #0154a2;
            border-radius: 2px;
            cursor: pointer;

            .text-apply-filter {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height */

                color: #ffffff;
            }
        }
        .disabled-btn-apply-filter {
            background: #4163a4;
            opacity: 0.3;
        }

        .btn-modal-reset {
            width: 93px;
            height: 32px;
            border: 1px solid #0154a2;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .text-modal-reset {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height */
                color: #4163a4;
            }
        }
    }
}
