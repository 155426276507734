.multiple-filter-material-config {
    .multiple-icon-filter {
        width: 32px;
        height: 32px;
        border: 1px solid #b1b1b1;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;

        .btn-filter-multiple-badge {
            position: absolute;
            width: 18px;
            height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ef5350;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 15px;
            border-radius: 100%;
            color: #ffffff;
            top: -10px;
            right: -10px;
        }
    }
    .has-filter-multiple-selected {
        border: 1px solid #1746a2;
        border-radius: 2px;
    }
}
