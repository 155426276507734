.modal-edit-datepicker__container {
  .date-picker-content {
    width: 204px;
    // width: 238px;
    height: 24px;

    background: white;
    font-family: var(--main-font);
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: #B1B1B1;
    outline: none;

    .react-date-picker__wrapper {
      border-top: none;
      border-right: none;
      border-left: none;
      border-color: #B1B1B1;
    }

    .react-date-picker__button {
      padding: 0px;
    }
  }

  .required-field__text {
    color: red;
    margin-top: 4px;
    font-size: 12px;
    font-family: 'Lato';
}

  .calendar-first {
    width: 250px;
    // height: 269px;
    position: absolute;
    left: -26px;
    top: 17px;
    margin-left: 5px;
    border-radius: 4px;
    z-index: 10;
    // z-index: -9999;
  }

  .react-calendar__month-view__weekdays {
      padding-bottom: 6px;   
  }

  .react-calendar__month-view__weekdays__weekday {
      color: #2D2D2D;
      font-weight: 600;
      font-style: normal;
      font-size: 8px;
      line-height: 12px;
      text-align: center;
      text-transform: capitalize;
      text-decoration: none;
      padding: 16px 0px;
  }

  .react-calendar__month-view__days__day {
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      text-align: center;
      background: white;
      color: #2D2D2D;
  }

  .btn-add__more {
    width: 92px;
    height: 20px;
    outline: none;
    background: none;

    font-family: var(--main-font);
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    color: #4163A4;
  }
  .b-bottom {
    border-bottom: 1px solid #4163A4;

  }
}