.order-confirmation-box-header {
    justify-content: space-between;
    padding: 0px 0px 35px 0px;
    background: #ffffff;
    // border: 0.5px solid #dcdcdc;
    // border-radius: 8px;

    .container-text-order-confirmation {
        gap: 10px;
        align-items: center;
        cursor: pointer;

        .text-order-confirmation {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            /* identical to box height */

            color: #4163a4;
        }
    }

    .action-button-container {
        align-items: center;
        .divider {
            height: 31px;
            width: 0.5px;
            background-color: #b7b7b7;
            margin: 0px 12px;
        }
        .button-edit {
            width: 73px;
            height: 32px;
            border: 1px solid #0154a1;
            background: white;
            border-radius: 2px;
            margin-right: 9px;
            font-family: var(--main-font);
            font-weight: 600;
            font-style: normal;
            font-size: 12px;
            line-height: 18px;
            color: #4163a4;
            text-transform: capitalize;
        }
    }
    .wrapper-btn {
        align-items: center;

        .btn-cancel {
            width: 68px;
            height: 32px;
            border-radius: 2px;
            border: 1px solid #0154a1;
            background: white;
            margin-right: 14px;

            font-family: var(--main-font);
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            text-transform: capitalize;
            text-align: center;
            color: #4163a4;
        }
        .btn-confirm {
            width: 75px;
            height: 32px;
            border-radius: 2px;
            background: #1746a2;
            margin-right: 14px;
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            text-transform: capitalize;
            text-align: center;
            color: #fff;
        }
    }
}
