.create-and-edit-modal__wrapper__streamlinemanagement {
    position: absolute;
    left: 16px;
    right: 16px;
    top: 10px;
    display: flex;
    justify-content: center;
    background: white;
    min-height: calc(100vh - 32px);
    max-height: calc(100vh - 32px);
    overflow: auto;

    .field-menu-list__container {
        max-height: 300px;
        overflow: auto;
    }

    .create-and-edit-modal__container__streamlinemanagement {
        margin: auto;

        width: 500px;
        padding: 48px 0;

        .section-head__container {
            display: flex;
            align-items: center;
            margin-bottom: 33px;
            color: #1746a2;
            .head-title__container {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                display: flex;
                align-items: center;
                text-align: center;
                text-transform: capitalize;

                margin-left: 100px;
            }
        }
        .section-body__container {
            .modal-item__container {
                margin-bottom: 20px;

                .label-title {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #2d2d2d;
                    margin-bottom: 6px;
                }

                .label-description {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #2d2d2d;
                    margin-bottom: 24px;
                }

                .label {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    color: #2d2d2d;
                    display: flex;
                    width: 210px;
                    margin-right: 32px;
                    margin-bottom: 6px;
                }

                .required-field__text {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 15px;
                    color: #ef5350;
                    margin-top: 4px;
                }

                .field-menu-list__container {
                    width: 500px;

                    .field-menu-item__container {
                        height: 48px;
                        border-bottom: 0.5px solid #d7d7d7;
                    }

                    .field-menu-item__container:last-child {
                        border-bottom: none;
                    }

                    .field-menu-item__container:hover {
                        background: var(--main-color-3);
                        color: white;
                    }
                }

                .input-container,
                select,
                .menu-button {
                    border: 1px solid rgb(165, 165, 165);
                    box-sizing: border-box;
                    height: 48px;
                    width: 100%;
                    padding-left: 16px;
                    border-radius: 4px;
                    background: white;
                    cursor: pointer;
                    flex: 1;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;

                    outline: grey;

                    color: #2d2d2d;

                    span:nth-child(1) {
                        text-align: left;
                    }
                }

                .placeholder,
                .input-container::placeholder {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #9c9898;
                }

                .menu-input__multiple-container {
                    flex: 1;
                    width: 100%;

                    .selected-multiple-input__container {
                        min-height: 40px;
                        flex: 1;

                        position: relative;
                        border: 1px solid rgb(165, 165, 165);
                        box-sizing: border-box;
                        gap: 8px;
                        align-items: center;
                        border-radius: 4px;
                        padding: 4px 8px;

                        .selected-multiple-input__item {
                            position: relative;
                            height: 32px;
                            width: max-content;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 12px;
                            padding: 4px 8px;
                            border: 1px solid #1746a2;
                            border-radius: 4px;
                            color: #1746a2;
                            font-weight: 500;
                        }

                        .text-show-more__container {
                            background: white;
                            font-size: 10px;
                            color: #9c9898;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 10px;
                            line-height: 15px;
                            height: 40px;
                            width: 80px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: absolute;
                            right: 0;
                        }
                    }

                    .button-choosed__container {
                        cursor: pointer;
                        height: 48px;
                        width: 133px;
                        border: 0.5px solid #1746a2;
                        border-radius: 4px;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 6px 0 16px;
                        margin-left: 20px;

                        p {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 18px;
                            text-align: center;

                            color: #1746a2;
                        }
                    }
                }

                .error-empty__field {
                    border: 0.5px solid #ef5350 !important;
                }
            }
        }

        .section-footer__container {
            justify-content: center;
            align-items: center;
            margin-top: 40px;

            .disable-button-add__container {
                background: rgba(23, 70, 162, 0.2) !important;
            }

            .button-add__container,
            .button-cancel__container {
                font-family: var(--main-font);
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: white;
                background: #1746a2;

                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                width: 117px;
                height: 42px;
            }

            .button-cancel__container {
                margin-left: 24px;
                background: white;
                color: #1746a2;
                border: 1px solid #1746a2;
            }
        }
    }
}

.create-and-edit-modal__wrapper__streamlinemanagement::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.create-and-edit-modal__wrapper__streamlinemanagement {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
