.streamline-box__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
    transition: 0.2s;
    height: 100%;

    .streamline-text {
        font-family: var(--main-font);
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #2d2d2d;

        box-sizing: border-box;
        padding: 0 8px;
        width: 100%;
    }
}
