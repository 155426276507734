.section-production-status__container__main-dashboard {
    width: calc(100vw - 300px);
    max-height: calc(100vh - 164px);
    overflow: auto;
    position: relative;

    .available-text {
        font-weight: 300;
        font-size: 10px;
        line-height: 15px;
        color: #80b44d;
    }

    .box-available {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: #80b44d;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
            font-weight: 500;
            font-size: 7px;
            color: white;
            line-height: 10px;
            padding-top: 1px;
            letter-spacing: -0.075em;
        }
    }
}
.section-production-status__container__main-dashboard::-webkit-scrollbar {
    display: none;
}

.chakra-modal__content::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.section-production-status__container__main-dashboard {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
