.parent-loading-slice {
    height: 649px;
    display: flex;
    justify-content: center;
    align-items: center;
    .child-loading-slice {
        width: 200px;
        text-align: center;
        .text-loading-slice {
            color: #000;
            font-size: 12px;
            font-family: Poppins;
            margin-top: 12px;
            margin-bottom: 29px;
        }
    }
}
