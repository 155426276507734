.raw-data-order-box-header {
    justify-content: space-between;
    padding: 0px 0px 35px 0px;
    background: #ffffff;

    .wrapper-btn {
        align-items: center;

        .btn-cancel {
            width: 68px;
            height: 32px;
            border-radius: 2px;
            border: 1px solid #0154a1;
            background: white;
            margin-right: 14px;

            font-family: var(--main-font);
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            text-transform: capitalize;
            text-align: center;
            color: #4163a4;
        }

        .btn-save {
            width: 55px;
            height: 32px;
            border-radius: 2px;
            background: #1746a2;

            font-family: var(--main-font);
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            text-transform: capitalize;
            text-align: center;
            color: white;
        }
    }

    .rekap-delivery_box {
        width: 141px;
        height: 32px;
        padding: 5px, 12px, 5px, 12px;
        border-radius: 2px;
        border: 1px solid #0154a1;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .text-rekap-delivery {
            font-family: var(--main-font);
            font-style: normal;
            font-size: 12px;
            font-weight: 600;
            line-height: 18px;
            text-align: left;
            text-transform: capitalize;
            color: #4163a4;
        }
    }

    .last-update {
        margin-top: 4px;
        color: #88a1cf;
        font-size: 10px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .text-supplier-stock {
        color: #4163a4;
        font-size: 16px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    
    .action-button-container {
        .rekap-delivery_box {
        width: 141px;
        height: 32px;
        padding: 5px, 12px, 5px, 12px;
        border-radius: 2px;
        border: 1px solid #0154A1;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .text-rekap-delivery {
            font-family: var(--main-font);
            font-style: normal;
            font-size: 12px;
            font-weight: 600;
            line-height: 18px;
            text-align: left;
            text-transform: capitalize;
            color: #4163A4;
        }
    }

    .add-more-order_box {
        width: 141px;
        height: 32px;
        padding: 5px, 12px, 5px, 12px;
        border-radius: 2px;
        border: 1px solid #0154A1;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .text-add-more-order {
            font-family: var(--main-font);
            font-style: normal;
            font-size: 12px;
            font-weight: 600;
            line-height: 18px;
            text-align: left;
            text-transform: capitalize;
            color: #4163A4;
        }
    }
        .box {
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25) !important;
            margin-right: -17px;
        }
        .divider {
            height: 31px;
            width: 0.5px;
            background-color: #b7b7b7;
            margin: 0px 17px;
        }
        
        .button-add-item__container {
            /// text
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: #4163a4;
            /// Button
            width: 135px;
            height: 32px;
            border: 1px solid #0154a2;
            border-radius: 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            padding: 7px 12px;
        }
        .button-recap-delivery__container {
            color: #4163a4;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            ///
            border-radius: 2px;
            border: 1px solid #0154a1;
            display: flex;
            height: 32px;
            width: 147px;

            align-items: center;
            cursor: pointer;
            padding: 5px 12px;
        }
        .button-add-more-order__container {
            color: #4163a4;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-left: 12px;
            margin-right: 5px;
            border-radius: 2px;
            border: 1px solid #0154a1;
            display: flex;
            height: 32px;
            width: 152px;

            align-items: center;
            cursor: pointer;
            padding: 5px 12px;
        }
    }
}
