.container-user-management {
    background: #fff;
    padding: 16px 21px;
    position: sticky;
    top: 0;
    z-index: 9;

    .btn-back-to-lobby {
        padding: 10px;
        height: 24px;
        width: 24px;
    }
    .btn-text-back {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */

        color: #000000;
    }
    .loading-profile-skeleton {
        height: 24px;
        width: 88px;
        border-radius: 4px;
    }
    .avatar-title-name {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #000000;
    }

    .status-role-badge {
        color: #1746a2;
        text-align: center;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.024px;
        text-transform: capitalize;
        display: flex;
        width: fit-content;
        height: 24px;
        padding: 0.5px 4px 1.5px 5px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: rgba(166, 191, 239, 0.4);
    }
}
