.detail-prodcut-status__container {
    padding: 27px 24px;

    .loading-state {
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .card__container {
        // border: 0.5px solid #8f8e8e;
        border-radius: 4px;
    }

    .text-head {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        text-transform: capitalize;
        color: #2d2d2d;
    }

    .see-more {
        font-weight: 300;
        font-size: 10px;
        line-height: 15px;
        text-decoration-line: underline;
        cursor: pointer;
        white-space: nowrap;
        color: #5bc0f8;
        margin-left: 8px;
        margin-top: 2px;
    }

    .report-problem__button {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height */

        color: #ef5350;
        border: 1px solid #ef5350;
        border-radius: 4px;
        background: white;
        margin-bottom: 24px;
    }

    .text-head__delay {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: capitalize;

        color: #2d2d2d;
    }

    .text-head__status {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #2d2d2d;
        margin-bottom: 12px;
    }

    .text-head__streamline {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: capitalize;
        color: #939393;
    }

    .table__container {
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
    }

    .wrapper__container {
        width: 100%;
        margin-bottom: 8px;
    }

    .wrapper-item {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #2d2d2d;
    }

    .card-status__container {
        .card-status__title {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #2d2d2d;
        }

        .card {
            width: 167px;
            display: flex;
            justify-content: center;
            align-items: center;

            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            border-radius: 4px;
            height: 52px;
        }
    }
}

.detail-prodcut-status__container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.table-column__container__product-detail-status {
    .column-sticky__item {
        padding-left: 15px;
        width: 100%;
        font-family: var(--main-font);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #001330;
        padding: 15px;
        .column-status {
            border-radius: 26px;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            padding: 7px 12px;
        }

        .column-action {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #1746a2;
            border: 1px solid #1746a2;
            border-radius: 4px;
            padding: 6px 8px;
            text-align: center;
            width: max-content;
        }
    }

    .column-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #2d2d2d;
    }

    .column-text__red {
        color: #ef5350;
    }

    .column-text__green {
        color: #80b44d;
    }

    .column-text__blue {
        color: #1746a2;
    }
}

.section-head-detail-card__container {
    position: sticky;
    padding: 24px 24px 18px;
    justify-content: space-between;
    align-items: center;
    top: 0;
    background: white;
    z-index: 2;
    border-bottom: 1px solid #d7d7d7;
    .head-title__container {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: capitalize;

        color: #2d2d2d;
    }
}

.detail-prodcut-status__container::-webkit-scrollbar {
    display: none;
}

.card-table__container {
    padding-bottom: 30px;
    height: calc(100% - 200px);
    overflow-y: auto;
}

.detail-prodcut-status__container {
    position: relative;
}
