.modal-item__container_firstForm {
    display: flex;
    width: 100%;
    gap: 60px;
    padding: 32px 38px;
    .FieldMenu-placeholder {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #9c9898;
    }
    .selected-role-text {
        font-family: 'Poppins' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 21px !important;
        color: #2d2d2d !important;
    }
    .menuList-container {
        max-height: 300px;
        overflow: auto;
        width: 100%;
    }
    .label-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #2d2d2d;
        margin-bottom: 6px;
    }

    .label-description {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #2d2d2d;
        margin-bottom: 17px;
    }

    .label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #2d2d2d;
        display: flex;
        width: 110px;
        margin-right: 32px;
        margin-bottom: 6px;
    }

    .required-field__text {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: #ef5350;
        margin-top: 4px;
    }

    .field-menu-list__container {
        width: 706px;

        .field-menu-item__container {
            height: 38px;
            border-bottom: 0.5px solid #d7d7d7;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;

            /* Black */

            color: #2d2d2d;
        }

        .field-menu-item__container:last-child {
            border-bottom: none;
        }

        .field-menu-item__container:hover {
            background: var(--main-color-3);
            color: white;
        }
    }

    .input-container,
    select,
    .menu-button {
        border: 1px solid rgb(165, 165, 165);
        box-sizing: border-box;
        height: 48px !important;
        width: 706px !important;
        padding-left: 16px;
        border-radius: 4px;
        background: white;
        cursor: pointer;
        flex: 1;
        font-style: normal;
        border: 0.5px solid #d7d7d7 !important;
        border-radius: 4px !important;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;

        outline: grey;

        color: #2d2d2d;

        span:nth-child(1) {
            text-align: left;
        }
    }

    .menu-button-error {
        border: 1px solid #ef5350 !important;
    }
    .uppercase-text {
        text-transform: uppercase;
    }
    .placeholder,
    .input-container::placeholder {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #9c9898;
        text-transform: capitalize;
    }

    .error-empty__field {
        border: 0.5px solid #ef5350 !important;
    }

    .menu-input__multiple-container {
        flex: 1;

        .selected-multiple-input__container {
            min-height: 40px;
            flex: 1;

            position: relative;
            border: 1px solid rgb(165, 165, 165);
            box-sizing: border-box;
            gap: 8px;
            align-items: center;
            border-radius: 4px;
            padding: 4px 8px 4px 16px;

            .empty-material__text {
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                text-align: center;
                color: #9c9898;
            }

            .selected-multiple-input__item {
                position: relative;
                height: 32px;
                width: max-content;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                padding: 4px 8px;
                border: 1px solid #1746a2;
                border-radius: 4px;
                color: #1746a2;
                font-weight: 500;
            }

            .text-show-more__container {
                background: white;
                font-size: 10px;
                color: #9c9898;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 15px;
                height: 40px;
                width: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 0;
            }
        }

        .button-choosed__container {
            cursor: pointer;
            height: 48px;
            width: 133px;
            border: 0.5px solid #1746a2;
            border-radius: 4px;
            justify-content: space-between;
            align-items: center;
            padding: 0 6px 0 16px;
            margin-left: 8px;

            p {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                text-align: center;

                color: #1746a2;
            }
        }
    }

    ////// SEDIAAN
    .form-pilih-sediaan {
        width: 500px;
        padding: 0px;

        .label-title {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #2d2d2d;
            margin-bottom: 6px;
        }

        .label-description {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #2d2d2d;
            margin-bottom: 17px;
        }

        .required-field__text {
            font-weight: 400;
            font-size: 10px;
            line-height: 15px;
            color: #ef5350;
            margin-top: 4px;
        }

        .field-menu-list__container {
            width: 500px;

            .field-menu-item__container {
                height: 48px;
                border-bottom: 0.5px solid #d7d7d7;
            }

            .field-menu-item__container:last-child {
                border-bottom: none;
            }

            .field-menu-item__container:hover {
                background: var(--main-color-3);
                color: white;
            }
        }

        .input-container,
        select,
        .menu-button {
            border: 1px solid rgb(165, 165, 165);
            box-sizing: border-box;
            height: 48px;
            width: 100%;
            padding-left: 16px;
            border-radius: 4px;
            background: white;
            cursor: pointer;
            flex: 1;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;

            outline: grey;

            color: #2d2d2d;

            span:nth-child(1) {
                text-align: left;
            }
        }

        .placeholder,
        .input-container::placeholder {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #9c9898;
        }

        .error-empty__field {
            border: 0.5px solid #ef5350 !important;
        }

        .menu-input__multiple-container {
            flex: 1;

            .selected-multiple-input__container {
                min-height: 40px;
                min-width: 600px;
                position: relative;
                border: 1px solid #d7d7d7;
                box-sizing: border-box;
                gap: 8px;
                align-items: center;
                border-radius: 4px;
                padding: 4px 8px 4px 16px;

                .empty-material__text {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    text-align: center;
                    color: #9c9898;
                }

                .selected-multiple-input__item {
                    position: relative;
                    height: 32px;
                    width: max-content;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                    padding: 4px 8px;
                    border: 1px solid #1746a2;
                    border-radius: 4px;
                    color: #1746a2;
                    font-weight: 500;
                }

                .text-show-more__container {
                    background: white;
                    font-size: 10px;
                    color: #9c9898;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 15px;
                    height: 40px;
                    width: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    right: 0;
                }
            }

            .button-choosed__container {
                cursor: pointer;
                height: 48px;
                width: 133px;
                border: 0.5px solid #1746a2;
                border-radius: 4px;
                justify-content: space-between;
                align-items: center;
                padding: 0 6px 0 16px;
                margin-left: 8px;

                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    text-align: center;

                    color: #1746a2;
                }
            }
        }
    }
}
