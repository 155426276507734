.table-demand-anticipation-additional-upload {
    .table-container-archived {
        .container-head {
            background: #edf0f6;
            .title-head {
                padding: 11px 6px;
                color: #2d2d2d;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: capitalize !important;
                height: 40px;
            }
        }

        .table-body-container {
            .table-tr {
                .container-drop-down {
                    padding: 11px 6px;
                    border-bottom: 1px solid #b7b7b7 !important;
                    /* identical to box height */
                    height: 40px;

                    .disabled-select {
                        background: #ffffff;
                        padding-left: 8px;
                        padding-right: 7px;
                        display: flex;
                        align-items: center;
                        border-radius: 4px;
                        border: 0.5px solid #1746a2;
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        /* identical to box height */
                        justify-content: space-between;
                        color: #2d2d2d;
                        width: 130px;
                        height: 20px;
                        font-size: 12px;
                        padding-top: 2px;
                        padding-bottom: 2px;
                    }
                    .menu-button {
                        border-radius: 4px;
                        border: 0.5px solid #1746a2;
                        background: #fff;
                        width: 103px !important;
                        height: 20px;
                        color: #2d2d2d;
                        font-family: Poppins;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: capitalize;
                        padding: 2px 7px 2px 8px !important;

                        span:nth-child(1) {
                            text-align: left;
                        }
                    }
                    .menu-item {
                        color: #2d2d2d;
                        font-family: Poppins;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: capitalize;
                        width: 100% !important;
                        padding: 6px 8px;
                    }
                    .menuList-container {
                        max-height: 300px !important;
                        overflow: auto !important;
                        padding: 0px;
                        min-width: 103px;
                        background-color: pink;
                        border-radius: 2px;
                    }
                }

                .input-value {
                    border: none;
                    border-radius: 0px;
                    color: var(--black, #2d2d2d);
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;
                }
                .input-value::placeholder {
                    font-style: italic;
                }
                .empty-input {
                    border: red solid 1px;
                }
            }
            .table-td {
                border-bottom: 1px solid #b7b7b7 !important;
                /* identical to box height */
                height: 40px;
                text-transform: capitalize;
                padding: 0px 0px;
                padding-top: 4px;

                /* Black */

                .icon {
                    position: absolute;
                    right: 0;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    margin-right: 26px;
                }
            }
        }
    }
}
