.modal-container-filter-multiple-material-config {
    .modal-filter-multiple-header {
        justify-content: space-between !important;
        width: 100% !important;
        padding: 13px 18px;
        border-bottom: 1px solid #b7b7b7;

        .modal-header-text {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height */

            color: #2d2d2d;
        }
    }
    .modal-body {
        max-height: 539px;
        overflow: hidden;
        padding: 16px 18px;

        .container-input {
            align-items: flex-end;
            gap: 12px;
            display: flex;
            .fieldMenu-container-single {
                .label {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    /* identical to box height */
                    color: #2d2d2d;
                    margin-bottom: 12px;
                    margin-top: 16px;
                }
                .FieldMenu-placeholder {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 18px;
                    /* identical to box height */

                    color: #b7b7b7;
                }
                .menuList-container {
                    max-height: 300px;
                    overflow: auto;
                    width: 335px;
                    padding-top: 0px;
                    padding-bottom: 0px;
                    border: 0.5px solid #929191;
                    border-radius: 4px;
                    border-bottom: none;
                }
                .menu-button {
                    box-sizing: border-box;
                    height: 32px !important;
                    padding-left: 16px;
                    border-radius: 4px;
                    background: white;
                    cursor: pointer;
                    flex: 1;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    border-radius: 2px;
                    border: 0.5px solid #2d2d2d;
                    outline: grey;

                    color: #2d2d2d;

                    span:nth-child(1) {
                        text-align: left;
                    }
                }
                .menu-item {
                    height: 38px;
                    border-bottom: 0.5px solid #929191;
                }
            }
            .extra-input {
                border-radius: 2px;
                border: 0.5px solid #2d2d2d !important;
                width: 64px;
                height: 32px;
                color: #2d2d2d;
                font-size: 12px;
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                padding: 0px 9px;
            }
            .extra-input:focus {
                outline: none;
            }
            .extra-input-disabled {
                @extend .extra-input;
                border-radius: 2px !important;
                border: 0.5px solid #2d2d2d !important;
                opacity: 0.10000000149011612 !important;
            }
            .clear-global-filter-jenis-material {
                height: 32px;
                margin-left: 5px;
                padding-left: 5px;
                padding-right: 5px;
            }
        }
    }
    .ModalFooter {
        gap: 12px;
        padding: 20px;
        border-top: 1px solid #b7b7b7;

        .btn-modal-close {
            width: 93px;
            height: 32px;
            border: 1px solid #0154a2;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .text-modal-close {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height */
                color: #4163a4;
            }
        }
    }

    .btn-apply-filter {
        width: 95px;
        height: 32px;
        padding: 7px 12px;
        background: #4163a4;
        border: 1px solid #0154a2;
        border-radius: 2px;
        cursor: pointer;

        .text-apply-filter {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height */

            color: #ffffff;
        }
    }
    .disabled-btn-apply-filter {
        background: #4163a4;
        opacity: 0.3;
    }
}
