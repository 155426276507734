.empty-problem__container {
    border: 2px dashed #ef5350;
    border-radius: 4px;
    height: 259px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 42px;

    .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 8px;
    }

    .desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        text-align: center;
        color: #000000;
        margin-bottom: 32px;
    }

    .report-problem__button {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #ef5350;

        width: 129px;
        height: 36px;
        border: 1px solid #ef5350;
        border-radius: 4px;
    }
}
