.raw-data-order__container {
    height: calc(100vh - 100px);
    padding: 22px 15px;
    font-family: var(--main-font);
    background: white;
    border: 0.5px solid #d7d7d7;
    border-radius: 8px;
    box-sizing: border-box;
    overflow: hidden;
}

// ---media-query for zoom 50% 
@media (min-width:2052px) and (max-width:2732px) {
        .raw-data-order__container {
        width: calc(100vw - 338px);
    }
 }

 // ---media-query for zoom 33% 
  @media (min-width:2733px) and (max-width:4102px) {
    .raw-data-order__container {
        width: calc(100vw - 1617px);
    }
 }

 // ---media-query for zoom 25% 
  @media (min-width:4103px) and (max-width:5464px) {
    .raw-data-order__container {
        width: calc(100vw - 2894px);
    }
 }
 
.raw-data-order__container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.raw-data-order__container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
