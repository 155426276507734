.supplier-stock-box-header {
    justify-content: space-between;
    padding: 0px 0px 35px 0px;
    background: #ffffff;

    .wrapper-btn {
        align-items: center;

        .btn-cancel {
            width: 68px;
            height: 32px;
            border-radius: 2px;
            border: 1px solid #0154a1;
            background: white;
            margin-right: 14px;

            font-family: var(--main-font);
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            text-transform: capitalize;
            text-align: center;
            color: #4163a4;
        }
    }

    .last-update {
        margin-top: 4px;
        color: #88a1cf;
        font-size: 10px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .text-supplier-stock {
        color: #4163a4;
        font-size: 16px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .button-edit {
        width: 73px;
        height: 32px;
        border: 1px solid #0154a1;
        background: white;
        border-radius: 2px;
        margin-right: 9px;
        font-family: var(--main-font);
        font-weight: 600;
        font-style: normal;
        font-size: 12px;
        line-height: 18px;
        color: #4163a4;
        text-transform: capitalize;
    }
    .button-edit:focus {
        outline: none;
        background: white;
    }

    // ==== calendar ==== //
    .date-picker-content {
        width: 156px;
        height: 32px;
        background: white;
        font-family: var(--main-font);
        font-weight: 400;
        font-style: normal;
        font-size: 12px;
        line-height: 18px;
        color: #d7d7d7;
        outline: none;
        position: relative;
        z-index: 4;
    }

    .react-date-picker__button {
        border-left: 0.5px solid #1746a2;
    }
    .react-date-picker__wrapper {
        border-radius: 2px;
        border: 1px solid #1746a2;
    }
    .react-date-picker__inputGroup {
        padding: 0px 12px;
    }
    .react-date-picker__inputGroup__input {
        font-family: var(--main-font);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #d7d7d7;
    }
    .react-date-picker__inputGroup__input:invalid {
        background: none;
    }
    .calendar-container {
        width: 343px;
        position: relative;
        left: 10px;
        top: 10px;
        border-radius: 4px;
    }
    .react-calendar__month-view__weekdays {
        padding-bottom: 6px;
    }
    .react-calendar__navigation__label__labelText {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: black;
    }
    .react-calendar__month-view__weekdays__weekday {
        font-weight: 600;
        font-style: normal;
        font-size: 13px;
        line-height: 18px;
        color: #94a3b8;
        text-align: center;
        text-transform: capitalize;

        abbr[title] {
            text-decoration: none;
        }
    }
    .react-calendar__month-view__days__day {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        text-align: center;
        color: black;
    }
    // ===========//
    .action-button-container {
        .box {
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25) !important;
            margin-right: -17px;
        }
        .divider {
            height: 31px;
            width: 0.5px;
            background-color: #b7b7b7;
            margin: 0px 17px;
        }
        .button-add-item__container {
            /// text
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: #4163a4;
            /// Button
            width: 135px;
            height: 32px;
            border: 1px solid #0154a2;
            border-radius: 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            padding: 7px 12px;
        }
    }
}
