.section-field-input__container__login-page {
    margin-bottom: 32px;

    .field-input__item {
        .label {
            font-size: 14px;
            line-height: 21px;
            color: #2d2d2d;
            margin-bottom: 12px;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
        }

        .input-login:focus-visible {
            box-shadow: none;
        }
        .input-login {
            border-radius: 0px;
            height: 44px;
            border: 1px solid #8c8c8c;
            border-radius: 4px;
            font-size: 14px;
            line-height: 21px;
            color: #2d2d2d;
            width: 392px;
            padding-right: 86px;
        }

        .input-login::placeholder {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: rgba(45, 45, 45, 0.4);
        }

        .login-error {
            margin-top: 8px;
            font-size: 12px;
            color: red;
        }

        .icon-eye__container {
            position: absolute;
            top: 16px;
            right: 16px;
            cursor: pointer;
            z-index: 2;
        }

        .icon-capslock__container {
            position: absolute;
            top: 14px;
            right: 46px;
            z-index: 2;
        }
    }

    .box-remember__container {
        .chakra-checkbox__control {
            border: 1px solid #2d2d2d;
            border-radius: 2px;
            width: 16px;
            height: 16px;
        }

        .chakra-checkbox__label {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #2d2d2d;
            margin-left: 10px;
        }
    }
}
