.section-top__container__login-page {
    width: 100%;

    color: #66666b;
    margin-bottom: 20px;

    .title {
        font-weight: 300;
        font-size: 24px;
        line-height: 36px;
        color: #2d2d2d;
        margin-bottom: 60px;
    }

    .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #2d2d2d;
    }

    .error-warn__container {
        background: #ffcccc;
        border: 0.5px solid #dc0000;
        border-radius: 4px;
        width: 100%;
        height: 47px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        margin-top: 12px;

        .error-text {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #ef5350;
            margin-left: 12px;
        }
    }
}
