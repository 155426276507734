.table-column__container__material-configuration {
    .column__item {
        display: flex;
        align-items: center;
        padding: 8px 0 8px;
        padding-left: 10px;
        border-bottom: 1px solid #cecece;
        padding-bottom: 8px;

        .checkbox-active {
            width: 20px;
            height: 20px;
            border: 1px solid #bbbbbb;
            outline: none;
        }

        .checkbox-disable {
            cursor: text;
            .css-1ydjfm6 {
                width: 20px;
                height: 20px;
                border-color: #2d2d2d;
                border-width: 3px;
            }
            .css-1ydjfm6[data-checked] {
                background: #1746a2;
                border-color: #1746a2;
            }
        }

        .column-status {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 19px;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: capitalize;
            color: var(--default-black);
            padding: 2px 16px;
            background: #e8e7e7;
            border-radius: 43px;

            @media (min-width: 1441px) {
                font-size: 14px;
            }
        }

        .column-text {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-transform: capitalize;
            color: #2d2d2d;
            text-align: left;

            @media (min-width: 1441px) {
                font-size: 12px;
            }
        }

        .column-text-ellipsis {
            @extend .column-text;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .column-text-status {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            text-align: center;
        }
    }

    .column__item-child {
        display: flex;
        align-items: center;
        width: 100%;

        .column__item-grand-child {
            width: 100%;
            display: flex;
            align-items: center;
        }

        .column-text {
            flex: 1;
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-transform: capitalize;
            color: #2d2d2d;
            width: 100%;

            @media (min-width: 1441px) {
                font-size: 12px;
            }
        }
    }

    .column__item:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
    }

    .column__item:nth-child(2) {
        position: sticky;
        left: 40px;
        z-index: 1;
    }

    .column__item:nth-child(3) {
        position: sticky;
        left: 180px;
        z-index: 1;
        border-right: 1px solid #cecece;
    }

    .column__item:nth-child(9) {
        display: flex;
        justify-content: center;
    }

    .column__item:nth-child(10) {
        display: flex;
        justify-content: center;
    }

    .column__item:nth-child(11) {
        display: flex;
        justify-content: center;
        padding: 0px;
    }
    .column__item:nth-child(12) {
        display: flex;
        justify-content: center;
        padding: 0px;
    }

    .column__item:last-child {
        position: sticky;
        right: 0;
        justify-content: center;
        border-left: 1px solid #cecece;
    }
}
