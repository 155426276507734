.supplier-stock__container {
    height: calc(100vh - 100px);
    padding: 22px 15px;
    font-family: var(--main-font);
    background: white;
    border: 0.5px solid #d7d7d7;
    border-radius: 8px;
    box-sizing: border-box;
    overflow: hidden;
}

// ---media-query for zoom 50% 
 @media (min-width:2052px) and (max-width:2732px) {
        .supplier-stock__container {
        width: calc(100vw - 357px);
    }
 }

 // ---media-query for zoom 33% 
  @media (min-width:2733px) and (max-width:4102px) {
    .supplier-stock__container {
        width: calc(100vw - 1635px);
    }
 }
 
 // ---media-query for zoom 25% 
  @media (min-width:4103px) and (max-width:5464px) {
    .supplier-stock__container {
        width: calc(100vw - 2916px);
    }
 }

