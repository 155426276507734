.section-select-streamline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 12px;
    position: relative;
    width: 147px;
    height: 34px;
    font-size: 12px;
    background: #ffffff;
    border: 1px solid #d7d7d7;
    border-radius: 4px;

    .notif-filter__container {
        right: 1px;
        top: -10px;
        position: absolute;
        background: #ef5350;
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #ffffff;
    }
}

.text-head-popover {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2d2d2d;
}
.after-head-popover__container {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    align-items: center;
    color: #5bc0f8;
    padding-top: 12px;

    .dots {
        width: 3px;
        height: 21px;
        left: 84px;
        top: 49px;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        color: #9c9a9a;
    }
}