.modal-failed-download-global__container {
    height: 62px;
    background: #EF6E6E;
    display: flex;
    align-items: center;
    padding-left: 12px;
    border-radius: 2px;
    margin: 8px 0;
    .box-icon {
        width: 32px;
        height: 32px;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        margin-right: 11px;
    }
    .text-desc-modal {
        width: 145px;
        text-align: left;
        font-family: var(--main-font);
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: white;
    }
    .box-close-btn {
      width: auto;
      height: 62px;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
    .box-close-btn:hover {
        box-shadow: none;
    }
    .box-close-btn:focus {
        background: white;
        box-shadow: none;
        border-color: transparent;
    }
}
