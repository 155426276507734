.material-config-drop-down {
    .section-select-filter {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 5px 12px;
        position: relative;
        width: 160px;
        height: 36px;
        font-size: 12px;
        background: #ffffff;
        border: 1px solid #d7d7d7;
        margin-right: 8px;
    }
    .filterList {
        border: 1px solid #d7d7d7;
        max-height: 224px;
        overflow: auto;

        .button-filter-options {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-radius: 0;
            height: 36px;
            border-bottom: 1px solid #d7d7d7;

            .text-select-filter {
                font-style: normal;

                font-size: 12px;
                line-height: 18px;
                padding: 8px 0px;
            }
        }
    }

    /// Custom Scrollbars In CSS : https://ishadeed.com/article/custom-scrollbars-css/
    .filterList::-webkit-scrollbar-thumb {
        background-color: #868686;
        border-radius: 10px;
        border: 7.9px solid white;
    }
    .filterList::-webkit-scrollbar {
        width: 20px;
    }
    .filterList:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}
