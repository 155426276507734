.order-management-box-header {
    justify-content: space-between;
    padding: 0px 0px 35px 0px;
    background: #ffffff;
    // border: 0.5px solid #dcdcdc;
    // border-radius: 8px;

    .text-order-management {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        /* identical to box height */

        color: #4163a4;
    }
    .action-button-container {
        .calender_eta {
            border-radius: 2px;
            border: 1px solid #1746a2;
            width: 166px;
            height: 32px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            .placeholder_calendar_eta {
                color: #d7d7d7;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-left: 12px;
            }

            .calendar_icon {
                position: absolute;
                border-left: #1746a2 solid 0.5px;
                height: 29px;
                padding-left: 11px;
                right: 12px;
                display: flex;
                align-items: center;
            }
        }
        .divider {
            height: 31px;
            width: 0.5px;
            background-color: #b7b7b7;
            margin: 0px 18px;
        }
        .button-order-confirmation__container {
            color: #4163a4;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            ///
            border-radius: 2px;
            border: 1px solid #0154a1;
            height: 32px;
            width: 169px;

            align-items: center;
            cursor: pointer;
            padding: 5px 11px;
            margin-right: 12px;
        }
        .button-order-delivery__container {
            color: #4163a4;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            ///
            border-radius: 2px;
            border: 1px solid #0154a1;

            height: 32px;
            width: 137px;

            align-items: center;
            cursor: pointer;
            padding: 5px 11px;
        }
        .box {
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25) !important;
            margin-right: -17px;
        }
    }
}
