.table-column__container__user-management {
    .column-sticky__item {
        border-bottom: 1px solid #f3f2f2;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        height: 68px;
        // height: 46px;
        padding-left: 32px;
        .checkbox-input > span {
            border-radius: 50%;
            height: 20px;
            width: 20px;
            border: 1px solid grey;
        }

        .column-status {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 19px;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: capitalize;
            color: var(--default-black);
            padding: 2px 16px;
            background: #e8e7e7;
            border-radius: 43px;
        }

        .column-text {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #001330;
            white-space: pre-wrap;
            width: 100%;
            margin-bottom: 2px;
        }
        
        .column-small__text {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 14px;
            color: #001330;
            white-space: pre-wrap;
            width: 100%;
            text-align: left;
        }

        .email-blue__text {
            color: #5bc0f8;
        }
    }

    .column-sticky__item:first-child {
        padding-left: 14px;
    }
}
