.upload-loader__container__orderplanning {
    height: 649px;
    display: flex;
    justify-content: center;
    align-items: center;
    .box {
        width: 200px;
        text-align: center;
        .cancel-button {
            padding: 5px 12px !important;
            color: #1746a2 !important;
            font-size: 12px !important;
            font-family: Poppins !important;
            font-weight: 500 !important;
            height: 32px !important;
        }
        .text-loading {
            color: #000;
            font-size: 12px;
            font-family: Poppins;
            margin-top: 12px;
            margin-bottom: 29px;
        }
    }
}