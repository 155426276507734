.section-success-upload__container__orderplanning{
    max-width: 421px;
    margin: 212px auto;
    display: flex;
    align-items: center;
    flex-direction: column;

    .title{
        margin: 18px 0 24px;

        color: #2D2D2D;
        font-size: 16px;
        font-family: Poppins;
        font-weight: 600;
    }


    .desc{
        color: #2D2D2D;
        text-align: center;
        font-size: 14px;
        margin-bottom: 42px;
    }

    .back-button{
        background: #0154A1;
        color: #FFF;
        font-size: 12px;
        font-weight: 600;
    }
}