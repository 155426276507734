.modal-confirm-global__container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 46px 16px;
    border-radius: 0px !important;

    .confirm-title {
        margin-top: 28.05px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 23px;
        /* identical to box height, or 163% */

        text-align: center;

        color: #2d2d2d;
    }

    .back-button {
        width: 119.21px;
        height: 31.56px;
        border-radius: 0px;
        border: 1px solid #1746a2;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 10.5441px;
        line-height: 16px;
        /* identical to box height */

        color: #1746a2;
        background: white;
    }
    .btnClassName {
        border-radius: 0px !important;
        font-family: 'Poppins' !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 10.5441px !important;
        line-height: 16px !important;
        /* identical to box height */
        width: 119.21px !important;
        height: 31.56px !important;
        color: #ffffff !important;
    }
    .disabledbtn {
        @extend .btnClassName;
        cursor: no-drop;
        background-color: #b1cbff !important;
    }
}
