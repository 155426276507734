.modal-delete-global__container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 46px 16px;

    .delete-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin: 24px 0;
    }

    .delete-description {
        margin-bottom: 42px;
    }

    .button-container {
        display: flex;
        gap: 20px;

        .delete-button,
        .delete-cancel {
            background: #ef5350;
            width: 117px;
            height: 36px;
            color: white;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            border-radius: 4px;
            outline: none !important;
        }

        .delete-cancel {
            background: white;
            border: 1px solid #dc0000;
            color: #dc0000;
        }
    }
}
