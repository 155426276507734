.section-add-new-item__container {
  .title-fieldmenu {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    text-transform: capitalize;
    color: #2D2D2D;
    margin-bottom: 6px;
  }
  
  .fieldmenu-input {
    border: 0.5px solid #D7D7D7;
    width: 706px;
    height: 48px;

    font-family: var(--main-font);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    color: #2D2D2D;
  }

  .disable-width-box {
    width: 500px;
    height: 48px;
    border: 1px solid red;
  }

  .active-width {
    width: 706px;
    height: 48px;
  }

  .active-width-emptyfield {
    width: 455px;
  }

  .menu-button-fieldmenu {
    width: 210px;
    height: 48px;
    border: 0.5px solid #D7D7D7;

    font-family: var(--main-font);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    color: #2D2D2D;
  }

  .disabled-field-supplier {
    border: 0.5px solid #D7D7D7;
    width: 706px;
    height: 48px;
  
    .label-selected-menulist {
      font-family: var(--main-font);
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 21px;
      text-align: left;
      color: #9c9898;
    }
  }

  .input-field-disabled {
    width: 462px;
    height: 48px;
    border: 0.5px solid #d7d7d7;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-top: 6px;
    color: #9c9898;
  }



  .btn {
    width: 93px;
    height: 32px;
    border-radius: 2px;
  }

  .btn-cancel {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    text-transform: capitalize;
    border: 0.5px solid #4163A4;
    background: white;
    color: #4163A4;
  }

  .btn-save {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    text-transform: capitalize;
    border: 0.5px solid #4163A4;
    background: #4163A4;
    color: white
  }

  .fieldmenu-placeholder {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #2D2D2D;
    text-transform: capitalize;
  }

  .menuList-container {
     max-height: 300px !important;
    overflow: auto !important;
    width: 706px;
  }

  .section-header__add-new-item {
    top: 0;
    position: sticky;
    background: white;
    display: flex;
    z-index: 2;
    padding-top: 30px;
    padding-bottom: 18px;
    padding-left: 17px;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 0.5px solid #D7D7D7;

    .text-header {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      text-transform: capitalize;
      color: #1746A2;
    }
  }

  .section-supplier__add-new-item {
    padding-left: 15px;
    padding-top: 23px;
    padding-bottom: 36px;
    border-bottom: 0.5px solid #D7D7D7;

    .text-supplier {
      font-family: var(--main-font);
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      text-transform: capitalize;
      color: #2D2D2D;
      margin-bottom: 6px;
    }

    .text-desc {
      font-family: var(--main-font);
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: left;
      color: #767676;
    }
  }

  .section-main-component__add-new-item {
    padding-left: 15px;
    padding-top: 36px;
    padding-bottom: 36px;
    padding-right: 78px;
    border-bottom: 0.5px solid #D7D7D7;
    align-items: flex-start;

    .text-main-component {
      font-family: var(--main-font);
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      text-transform: capitalize;
      color: #2D2D2D;
      margin-bottom: 6px;
    }

    .text-desc {
      font-family: var(--main-font);
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: left;
      color: #767676;
    }

    .menuList-kode-item__container {
    max-height: 300px !important;
    overflow: auto !important;
    }
  }

  .section-set-component__add-new-item {
    padding-left: 15px;
    padding-top: 36px;
    padding-right: 78px;
    border-bottom: 0.5px solid #D7D7D7;
    align-items: flex-start;

    .wrapper-fields__add-more-item {
      display: flex;
      flex-direction: column;
    }

    .text-set-component {
      font-family: var(--main-font);
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      text-transform: capitalize;
      color: #2D2D2D;
      margin-bottom: 6px;
    }

    .text-desc {
      font-family: var(--main-font);
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: left;
      color: #767676;
    }

    .menuList-kode-item__container {
    max-height: 300px !important;
    overflow: auto !important;
    width: 210px;
  }

  .wrapper-btn-more {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    margin-bottom: 37px;

    .btn-more-item {
      background: white;
      border-bottom: 1px solid #4163A4;
  
      font-family: var(--main-font);
      text-transform: capitalize;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #4163A4;
    }
    
    .btn-more-item:focus {
      outline: none;
    }
  }
  }

  .wrapper-btn-save-cancel {
    padding-top: 36px;
    padding-bottom: 27px;
  }

  .wrapper-trash-icon {
    display: flex;
    align-items: center;
    margin: 20px 0 0 20px;
    cursor: pointer;
  }

  .label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #2d2d2d;
        display: flex;
        width: 114px;
        margin-right: 32px;
        margin-bottom: 6px;
    }

  .required-field__text {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: #ef5350;
        margin-top: 4px;
    }

    .required-field__input {
      border: 0.5px solid red;
    }

  .scroll-container {
        overflow: auto;
        background-color: rgb(255, 255, 255);
        width: 706px;
        border-radius: 4px;

        .loading_fetch_next_page {
            color: var(--black, #757575);
            font-family: Open Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-left: 12px;
            padding-top: 10px;
            padding-bottom: 10px;
            text-align: center;
        }

        .no_data_infinite_scroll {
            color: var(--black, #757575);
            font-family: Open Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding-top: 10px;
            padding-bottom: 10px;
            text-align: center;
            position: absolute;
            left: 0;
            right: 0;
            background-color: #ffffff;
            border-left: 1px solid #e2e8f0;
            border-right: 1px solid #e2e8f0;
        }
        .list_item_infinite {
            color: var(--black, #2d2d2d) !important;
            font-family: Open Sans !important;
            font-size: 12px !important;
            font-style: normal !important;
            font-weight: 600 !important;
            line-height: normal !important;
            padding: 10px 10px;
            border-radius: 2px;
            border-bottom: 0.5px solid #d7d7d7;
        }
        .list_item_infinite:hover {
            background-color: #b9c7e3;
            color: #fff !important;
            cursor: pointer;
        }
        .list_item_infinite:active {
            background-color: #e0e0e0;
        }
    }

    .scroll-container::-webkit-scrollbar-track {
        background: #b7b7b7 !important;
        border: solid #ffffff 7px !important;
    }

    .scroll-container::-webkit-scrollbar {
        height: 6px !important;
        width: 15px !important;
    }

    .scroll-container::-webkit-scrollbar-thumb {
        border-radius: 100px !important;
        background: #4163a4 !important;
        border: solid #fff 5.7px;
        border-bottom: transparent !important;
        border-top: transparent !important;
    }
}