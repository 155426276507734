.table-column__container__order_delivery {
    .column__item {
        display: flex;
        padding-top: 11px;
        padding-bottom: 11px;
        padding-left: 10px;
        border-bottom: 1px solid #cecece;
        // padding-bottom: 8px;
        height: 40px;

        .checkbox-active {
            height: 15px;
            width: 15px;
            border: 1px solid #bbbbbb;
            outline: none;
        }

        .checkbox-disable {
            height: 15px;
            width: 15px;
            border: 1px solid #2d2d2d;
            outline: none;
        }

        .column-status {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 19px;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: capitalize;
            color: var(--default-black);
            padding: 2px 16px;
            background: #e8e7e7;
            border-radius: 43px;

            @media (min-width: 1441px) {
                font-size: 14px;
            }
        }

        .column-text {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-transform: capitalize;
            color: #2d2d2d;
            text-align: left;

            @media (min-width: 1441px) {
                font-size: 12px;
            }
        }
        .column-text-ellipsis {
            @extend .column-text;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .input-text-field {
            font-family: var(--main-font);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            color: #2d2d2d;
            text-align: center;
        }

        .column-text-status {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            text-align: center;
        }
    }

    .column__item:nth-child(1),
    .column__item:nth-child(2),
    .column__item:nth-child(3),
    .column__item:nth-child(4),
    .column__item:nth-child(5),
    .column__item:nth-child(6) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .column__item:nth-child(7) {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 20px;
    }

    .column__item-child {
        display: flex;
        align-items: center;
        width: 100%;

        .column__item-grand-child {
            width: 100%;
            display: flex;
            align-items: center;
        }

        .column-text {
            flex: 1;
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-transform: capitalize;
            color: #2d2d2d;

            @media (min-width: 1441px) {
                font-size: 12px;
            }
        }
    }
    .action-btn {
        background-color: #fff;
        position: sticky;
        right: 0;
        gap: 10px;
        align-items: center;
        justify-content: center;
        width: 90px;
        padding-left: 8px;
        border-bottom: 1px solid #cecece;
        border-left: 1px solid #cecece;
        .reset-btn {
            background-color: #fff;
            border: #1746a2 1px solid;
            border-radius: 3.515px;
            height: 31.555px;
            /// text
            color: #1746a2;
            font-family: Poppins;
            font-size: 10.544px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
}
