.table-additional-upload-supplier-stock {
    .table-container-archived {
        .container-head {
            background: #edf0f6;
            .title-head {
                padding: 11px 6px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height */
                text-transform: capitalize;
                color: #2d2d2d;
                height: 40px;
            }
        }

        .table-body-container {
            .table-tr {
                .input-value {
                    padding: 10px 6px;
                    border: none;
                    border-radius: 0px;
                    color: #2d2d2d;
                    font-size: 12px;
                    font-family: Poppins;
                }
                .empty-input {
                    border: red solid 1px;
                }
            }
            .table-td {
                border-bottom: 1px solid #b7b7b7 !important;
                /* identical to box height */
                height: 40px;
                text-transform: capitalize;
                padding: 0px 0px;
                padding-top: 4px;

                .icon-note {
                    position: absolute;
                    right: 0;
                    margin-right: 8px;
                    display: flex;
                    align-items: center;
                    height: 100%;
                }
                .icon-circle-edit {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    height: 100%;
                    right: 0;
                    margin-right: 8px;
                }
                /* Black */
                .table-column-status {
                    color: #267346;
                    font-family: Poppins;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    border-radius: 4px;
                    background: #d9f2e3;
                    width: 95px;
                    padding: 4px;
                    text-align: center;
                }
            }
        }
    }
}
