.order-delivery-box-header {
    justify-content: space-between;
    padding: 0px 0px 35px 0px;
    background: #ffffff;

    .container-text-order-delivery {
        gap: 10px;
        align-items: center;
        cursor: pointer;

        .text-order-delivery {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            /* identical to box height */

            color: #4163a4;
        }
    }
    .action-button-container {
        .button-edit {
            width: 73px;
            height: 32px;
            border: 1px solid #0154a1;
            background: white;
            margin-left: 10px;
            border-radius: 2px;
            margin-right: 6px;
            font-family: var(--main-font);
            font-weight: 600;
            font-style: normal;
            font-size: 12px;
            line-height: 18px;
            color: #4163a4;
            text-transform: capitalize;
        }
        .button-edit:focus {
            outline: none;
            background: white;
        }
        .divider {
            height: 31px;
            width: 0.5px;
            background-color: #b7b7b7;
            margin: 0px 18px;
        }
        .button-order-confirmation__container {
            color: #4163a4;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            ///
            border-radius: 2px;
            border: 1px solid #0154a1;
            display: flex;
            height: 32px;
            width: 169px;

            align-items: center;
            cursor: pointer;
            padding: 5px 11px;
            margin-right: 12px;
        }
        .button-order-delivery__container {
            color: #4163a4;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            ///
            border-radius: 2px;
            border: 1px solid #0154a1;
            display: flex;
            height: 32px;
            width: 137px;

            align-items: center;
            cursor: pointer;
            padding: 5px 11px;
        }
        .box {
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25) !important;
            margin-right: -17px;
        }
    }
    .wrapper-btn {
        align-items: center;

        .btn-cancel {
            width: 68px;
            height: 32px;
            border-radius: 2px;
            border: 1px solid #0154a1;
            background: white;
            margin-right: 14px;

            font-family: var(--main-font);
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            text-transform: capitalize;
            text-align: center;
            color: #4163a4;
        }
        .btn-confirm {
            width: 75px;
            height: 32px;
            border-radius: 2px;
            background: #1746a2;
            margin-right: 14px;
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            text-transform: capitalize;
            text-align: center;
            color: #fff;
        }
    }
}
