.table-column__container__table-add-item {
    .column-sticky__item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 40px;
        padding-left: 32px;

        .column__item {
            display: flex;
            padding: 20px 0 20px;
            padding-left: 10px;
            border-bottom: 1px solid #cecece;
        }

        .column-text {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            color: #2D2D2D;
            text-transform: uppercase;
        }

        .text-ellipsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .column-sticky__item:last-child {
        justify-content: center;
    }
}