.create-modal-edit-notes__container {
    background: white;
    padding: 10px 12px;
    border-radius: 4px;
    box-sizing: border-box;

    .text-note {
        font-family: var(--main-font);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-transform: capitalize;
        color: #2d2d2d;
    }
    .textarea-modal-edit {
        width: 256px;
        height: 91px;
        border: 0.5px solid #d7d7d7;
        border-radius: 2px;
        margin-top: 4px;
        padding: 0px 8px;
        padding-bottom: 60px;
        overflow: hidden;
        resize: none;

        font-family: var(--main-font);
        font-weight: 400;
        font-style: normal;
        font-size: 12px;
        line-height: 18px;
        color: #2d2d2d;
    }

    .textarea-modal-edit:focus {
        outline: none;
        border: 0.5px solid #d7d7d7;
    }
    .btn {
        width: auto;
        border: none;
        border-radius: none;
        background: none;
        cursor: pointer;

        font-family: var(--main-font);
        font-weight: 400;
        font-style: normal;
        font-size: 12px;
        line-height: 18px;
        text-transform: capitalize;
    }

    .btn-clear {
        color: #e97776;
        border-radius: 4px;
        background: #ffd7d7;
        padding: 1px 2px;
    }
    .btn-edit {
        color: #8e8e8e;
        border-radius: 4px;
        background: #f1eeee;
        padding: 1px 2px;
        margin-left: 12px;
        margin-right: 12px;
    }
    .btn-save {
        color: #1746a2;
        border-radius: 4px;
        background: #cbdbfb;
        padding: 1px 2px;
        width: 65px;
    }

    .btn-save-bg-unactive {
        color: #1746a2;
        background: white;
    }
    .btn-bg-unactive {
        color: #8e8e8e;
        background: white;
    }
}
