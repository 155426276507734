.entitas-text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-left: 3px;

    /* Black */

    color: #b1b1b1;
}
.root-disabled-menu-list {
    background: #eff4ff;
    padding-left: 16px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 0.5px solid #d7d7d7;
    cursor: no-drop;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    color: #2d2d2d;
}
