.baseline-per-week {
    align-items: flex-start;
    gap: 73px;
    padding: 36px 15px 36px 15px;
    border-bottom: 0.5px solid #d7d7d7;

    .title-baseline-per-week {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */

        color: #2d2d2d;
    }

    .tag-baseline-per-week {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        /* or 167% */

        color: #767676;
    }
    .baseline-per-week-container {
        .child-container-per-week {
            display: flex !important;
            column-gap: 34px !important;

            .required-field__text {
                font-weight: 400;
                font-size: 10px;
                line-height: 15px;
                color: #ef5350;
                margin-top: 4px;
            }
            .input-style-add-new-item-MC {
                width: 213px;
                height: 48px;
                outline: none;
                border: 0.5px solid #d7d7d7;
                border-radius: 4px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                /* identical to box height */

                color: #2d2d2d;
            }
            .input-style-add-new-item-MC::placeholder {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: #9c9898;
            }
            .input-style-add-new-item-MC-disabled {
                background: #e7e7e7;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                /* identical to box height */

                color: #2d2d2d;
            }
            .right-label-input {
                position: absolute;
                z-index: 1;
                top: 0;
                width: 71px;
                height: 48px;
                left: 142px;
                top: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #f8f8f8;
                border: 0.5px solid #d7d7d7;
                border-radius: 3px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height */

                text-align: center;

                color: #9c9898;
            }
        }
    }
}
