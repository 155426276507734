.section-head__container__asset-management {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 28px;
    align-items: center;

    .section-search__container__asset-management {
        position: relative;
        width: 481px;

        .search-input {
            width: 481px;
            height: 34px;
            border: 1px solid #d7d7d7;
            border-radius: 4px;
            outline: none;
            padding-left: 16px;

            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
        }

        .search-icon__container {
            position: absolute;
            right: 12px;
            top: 6px;
        }

        .search-input::placeholder {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #b1b1b1;
        }
    }

    .button-add-row {
        padding: 9px 29px;
        font-family: var(--main-font);
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        background-color: #1746a2;
        color: white;
        border: none;

        width: 176px;
        height: 36px;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }
}
