.section-table-material-configuration {
    width: calc(100vw - 330px);
    overflow-x: scroll;
    position: relative;
    height: calc(100vh - 296px);
    padding-bottom: 16px;

    .section-table {
        height: calc(100vh - 360px);
    }
}

.section-table-material-configuration::-webkit-scrollbar-track {
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    background: rgba(181, 176, 176, 0.3);
}

.section-table-material-configuration::-webkit-scrollbar {
    height: 7px;
    width: 0;
}

.section-table-material-configuration::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: #4163a4;
}


.hide-scrollbar-section-table-material-configuration {
    height: max-content;

    .section-table {
        height: max-content;
    }
}
