.modal-container-transition {
    .modal-body {
        max-height: 539px;
        overflow: hidden;
        padding-left: 50px;
        padding-right: 52px;
        padding-top: 86px !important;

        .container-form {
            display: grid;
            gap: 20px;
            .container-field {
                align-items: center;
                justify-content: space-between;
                .field {
                    width: 365px;
                    height: 36px;
                    padding: 8px 12px;
                    border-radius: 4px;
                    border: 0.5px solid #929191;
                    color: var(--black, #2d2d2d);
                    font-size: 14px;
                    font-family: Poppins;
                }
                .field:focus {
                    outline: none;
                }
                .field-disabled {
                    color: #9c9898;
                    font-size: 14px;
                    font-family: Poppins;
                    border-radius: 4px;
                    border: 0.5px solid #929191;
                    background: var(--primary-sub-header, #ededed);
                }
                .label {
                    color: var(--black, #2d2d2d);
                    font-size: 14px;
                    font-family: Poppins;
                    font-weight: 500;
                }
            }
        }
    }
    .modal-footer {
        gap: 12px;
        padding: 35px 51.66px 67.44px 0px;

        .btn-modal-ignore {
            width: 118.039px;
            height: 31.555px;
            border-radius: 2px;
            border: 0.879px solid #1746a2;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .text-modal-ignore {
                color: #1746a2;
                font-size: 10.544px;
                font-family: Poppins;
                font-weight: 600;
            }
        }
        .btn-apply-submit {
            width: 95px;
            height: 32px;
            padding: 7px 12px;
            border-radius: 2px;
            border: 0.879px solid #1746a2;
            background: #1746a2;
            cursor: pointer;
            justify-content: center;
            display: flex;

            .text-apply-submit {
                color: #fff;
                font-size: 10.544px;
                font-family: Poppins;
                font-weight: 600;
                width: 40px;
            }
        }
    }
}
