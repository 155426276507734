.modal-format-global__container {
    // width: 100%;
    // width: 350px;
    height: 154px;
    background: #F5B2B2;
    padding: 17px 11px;
    border-radius: 4px;

    .wrapper-title {
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 12px;
    }

    .format-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    .format-description {
        width: 309px;
        margin-bottom: 42px;
        font-weight: 400;
        font-size: 10px;
        line-height: 18px;
        color: #2D2D2D;
        text-align: left;
    }

    .back-button {
        height: 24px;
        display: flex;
        align-items: flex-start;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #2d2d2d;
        background: #F5B2B2;
        outline: none;
        border: none;
    }

    .back-button:hover {
        box-shadow: none;
    }

    .back-button:focus {
        background: #F5B2B2;
        box-shadow: none;
        border-color: transparent;
    }
}