.table-head__container__demand_anticipation {
    width: max-content;
    border-radius: 4px;
    position: sticky;
    top: 0;
    z-index: 2;
    background: white;

    .active-margin__right {
        margin-right: 30px;
    }

    .table-head__item:first-child {
        position: sticky;
        z-index: 5;
        left: 0;
    }

    .table-head__item:nth-child(2) {
        position: sticky;
        z-index: 5;
        left: 70px;
    }

    .table-head__item:nth-child(3) {
        position: sticky;
        z-index: 5;
        left: 210px;
        border-right: 1px solid #cecece;
    }

    .table-head__item:nth-child(1) {
        .table-head__parent,
        .table-head__child-container {
            display: flex;
            align-items: center;
            padding-left: 10px;
        }
    }

    .table-head__item:nth-child(2) {
        .table-head__parent,
        .table-head__child-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .table-head__item:nth-child(3) {
        .table-head__parent,
        .table-head__child-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .table-head__item:nth-child(4) {
        .table-head__parent,
        .table-head__child-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .table-head__item:nth-child(6) {
        .table-head__parent,
        .table-head__child-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .table-head__item:nth-child(7) {
        .table-head__parent,
        .table-head__child-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .table-head__item:nth-child(8) {
        .table-head__parent,
        .table-head__child-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .table-head__item:nth-child(4) {
        .table-head__parent,
        .table-head__child-container {
            .table-head__child {
            display: flex;
            align-items: center;
            justify-content: center;
            }
        }
    }

    .table-head__item:nth-child(6) {
        .table-head__parent,
        .table-head__child-container {
            .table-head__child {
            display: flex;
            align-items: center;
            justify-content: center;
            }
        }
    }

    .table-head__item:nth-child(7) {
        .table-head__parent,
        .table-head__child-container {
            .table-head__child {
            display: flex;
            align-items: center;
            justify-content: center;
            }
        }
    }

    .table-head__item:nth-child(8) {
        .table-head__parent,
        .table-head__child-container {
            .table-head__child {
            display: flex;
            align-items: center;
            justify-content: center;
            }
        }
    }

    .table-head__item:nth-child(9) {
        .table-head__parent,
        .table-head__child-container {
            .table-head__child {
            display: flex;
            align-items: center;
            justify-content: center;
            }
        }
    }

    .table-head__item {
        .table-head__parent {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .table-head__item {
        .table-head__parent,
        .table-head__child-container {
            .table-head__child {
            display: flex;
            align-items: center;
            justify-content: center;
            }
        }
    }

    .table-head__item {
        display: flex;
        align-items: center;
        text-transform: capitalize;

        font-family: var(--main-font);
        font-style: normal;
        color: #2d2d2d;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;

        @media (min-width: 1441px) {
            font-size: 12px;
        }

        .table-head__parent {
            width: 100%;
            height: 40px;
            align-items: center;
            text-align: left;
            color: #2d2d2d;
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            background: #edf0f6;
            margin-bottom: 4px;
            border-top: 2px solid #f0f2ef;
            border-bottom: 2px solid #f0f2ef;

            @media (min-width: 1441px) {
                font-size: 12px;
            }
        }

        .table-head__child-container {
            display: flex;
            align-items: center;
            flex-direction: column;
            flex: 1;

            width: 100%;
            height: 16px;
            background: #ededed;
        }

        .table-head__child,
        .table-head__grand-child {
            flex: 1;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding-left: 10px;

            font-family: var(--main-font);
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 18px;
            text-transform: capitalize;
            color: #2d2d2d;
        }

        .table-head__child {
            padding-bottom: 4px;
            margin-bottom: 4px;
            width: 100%;
            height: 16px;
        }

        .table-head__child-container {
            .table-head__child {
                padding-bottom: 0;
                margin-bottom: 0;

                p {
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
    }

    .table-head__item:last-child {
        position: sticky;
        z-index: 5;
        .table-head__parent,
        .table-head__child-container {
            display: flex;
            align-items: center;
            text-align: left;
        }
    }

    .text-year__table-head-children {
    position: absolute;
    bottom: -2px;
    left: 71px;

    color: rgba(183, 183, 183, 0.72);
    font-family: var(--main-font);
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    }
}
