.menu-filter-jenis-material__container {
    display: flex;
    .box-drop-down-global-filter-jenis-material {
        .menu-button-jenis-material {
            background: white !important;
            height: 36px;
            width: 225px;

            border: 1px solid #d7d7d7 !important;
            border-radius: 4px !important;

            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #2d2d2d;
            margin-left: 16px;
            text-align: left;

            .chakra-button__icon {
                margin-left: 16px;
            }
        }

        .menu-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 38px;

            color: var(#2d2d2d);
            font-family: var(--main-font);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            text-align: left;
        }
    }

    .clear-global-filter-jenis-material {
        height: 36px;
        margin-left: 5px;
        padding-left: 5px;
        padding-right: 5px;
    }
}
