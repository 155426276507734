.table-skeleton__container {
    .section-head__container__table-skeleton {
        background: #e7efff;
        border-radius: 8px;
        .section-head__item-container {
            padding: 16px;
        }
    }

    .section-body__container__table-skeleton {
        .section-body__item-container {
            padding: 16px;
        }
    }

    .section-body__container__table-skeleton:nth-child(odd) {
        background: #fafafa;
    }
}
