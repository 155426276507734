.table-head__container__user-management {
    width: 100%;
    margin-bottom: 4px;

    .table-head__item {
        display: flex;
        padding-left: 32px;
        align-items: center;
        justify-content: flex-start;
        font-family: var(--main-font);
        font-style: normal;
        color: #2d2d2d;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-transform: capitalize;
        color: #2d2d2d;
        border-top: 2px solid #f0f2ef;
        border-bottom: 2px solid #f0f2ef;

        background: var(--main-color-1);
        height: 34px;

        .table-head__button:hover,
        .table-head__button[data-active] {
            background: none;
        }

        .table-head__text {
            text-transform: uppercase;
            color: #2d2d2d;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
        }
    }

    .table-head__item:first-child {
        border-radius: 4px 0px 0px 4px;
        border-left: 2px solid #f0f2ef;
        padding-left: 14px;
    }

    .table-head__item:last-child {
        justify-content: center;
        border-radius: 0px 4px 4px 0px;
        border-right: 2px solid #f0f2ef;
    }
}
