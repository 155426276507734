.table-column__container__sku-configuration {
    .column__item {
        display: flex;
        padding: 20px 0 20px;
        padding-left: 10px;
        border-bottom: 1px solid #cecece;

        .checkbox-input > span {
            border-radius: 50%;
            height: 18px;
            width: 18px;
            border: 1px solid gray;
        }

        .column-status {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 19px;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: capitalize;
            color: var(--default-black);
            padding: 2px 16px;
            background: #e8e7e7;
            border-radius: 43px;

            @media (min-width: 1441px) {
                font-size: 14px;
            }
        }

        .column-text {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 19px;
            text-transform: uppercase;
            color: #001330;

            @media (min-width: 1441px) {
                font-size: 14px;
            }
        }
    }

    .column__item-child {
        display: flex;
        align-items: center;
        width: 100%;

        .column__item-grand-child {
            width: 100%;
            display: flex;
            align-items: center;
        }

        .column-text {
            flex: 1;
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;

            line-height: 19px;
            text-transform: uppercase;
            color: #001330;

            @media (min-width: 1441px) {
                font-size: 14px;
            }
        }
    }

    .column__item:first-child {
        position: sticky;
        left: 0;
    }

    .column__item:nth-child(2) {
        position: sticky;
        left: 325px;
        border-right: 1px solid #cecece;
    }

    .column__item:last-child {
        position: sticky;
        right: 0;
        justify-content: center;
        border-left: 1px solid #cecece;
    }
}
