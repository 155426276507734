.empty-state-global__container {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 50vh;

    .empty-state__text {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #2d2d2d;
        margin: 24px 0 16px;
        text-transform: capitalize;
    }

    .empty-state__desc {
        width: 310px;
        text-transform: capitalize;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #2d2d2d;
    }
}
