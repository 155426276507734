.modal-container-edit-note {
    .modal-edit-note-header {
        justify-content: space-between !important;
        width: 100% !important;
        padding: 13px 18px;
        border-bottom: 1px solid #b7b7b7;

        .modal-header-text {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height */

            color: #2d2d2d;
        }
    }
    .modal-body {
        max-height: 539px;
        overflow: hidden;
        padding: 32px 18px;
        .container-edit-note {
            width: 508px;
            height: 128px;

            .input-edit {
                resize: none;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.002em;
                padding: 12px 14px;
                border-radius: 2px;
                color: #2d2d2d;
                width: 508px;
                height: 128px;
                border: 0.5px solid #2d2d2d !important;
            }
            .input-edit:focus {
                outline: none;
            }
            .input-edit:disabled {
                font-family: 'Poppins' !important;
                font-style: normal !important;
                font-weight: 500 !important;
                font-size: 12px !important;
                line-height: 18px !important;
                letter-spacing: 0.002em !important;
                padding: 12px 14px !important;
                border-radius: 2px !important;
                color: #2d2d2d !important;
                background: white !important;
                border: 0.5px solid #2d2d2d !important;
            }
        }
    }
    .modal-footer {
        gap: 16px;
        padding-right: 18px;
        padding-bottom: 33px;
        padding-top: 0px;

        .btn-modal-close {
            width: 101px;
            height: 32px;
            border: 1px solid #1746a2;
            border-radius: 2px;
            padding: 7px 11px;
            cursor: pointer;
            .text-modal-close {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height */

                color: #1746a2;
            }
        }
        .btn-apply-edit-note {
            width: 107px;
            height: 32px;
            padding: 7px 12px;
            background: #4163a4;
            border-radius: 2px;
            cursor: pointer;
            text-align: center;

            .text-apply-edit-note {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height */

                color: #ffffff;
            }
        }
        .btn-apply-edit-note-disabled {
            @extend .btn-apply-edit-note;
            background: #7596d5;
            cursor: no-drop;
        }
    }
}
