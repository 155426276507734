.section-head__container__order-recap {
    width: 100%;
    justify-content: space-between;
    padding-bottom: 32px;
    align-items: center;
    position: relative;
    top: 0;
    z-index: 2;
    background: white;

    .section-left,
    .section-search__container__order-recap {
        position: relative;
        width: 283px;
        background: white;
        margin-right: 12px;

        .search-input {
            width: 283px;
            height: 34px;
            border: 1px solid #D7D7D7;
            margin-right: 21px;
            border-radius: 4px;
            outline: none;
            padding-left: 12px;
            padding-top: 8px;
            padding-bottom: 8px;

            font-family: var(--main-font);
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: black;
        }

        .search-input::placeholder {
            font-family: var(--main-font);
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #B1B1B1;
        }

        .search-icon__container {
            position: absolute;
            right: 10px;
            top: 6px;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #b8b8b8;
        }
    }

    .field-filter-date__container {
        height: 34px;
        border: 1px solid #D7D7D7;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 7px 10px;

    span {
        font-family: var(--main-font);
        font-style: normal;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 18px !important;
        color: #2D2D2D;
    }
}

.field-filter-date__container:hover {
    background: none !important;
}

.field-filter-date__container[data-active] {
    background: none !important;
}


    .filter-date__container {
        position: relative;
        width: 239px;
        height: 34px;
        background: white;
        display: flex;
        align-items: center;
        padding-left: 15px;
        border: 1px solid #D7D7D7;
        border-radius: 4px;
        
        .filter-date-input {
            outline: none;
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 18px;
            color: #D7D7D7;
        }

        .calendar-date {
            position: absolute;
            top: 12px;
            left: -12px;
        }

        .icon-calendar-input__date {
            position: absolute;
            top: 1px;
            right: 3px;
            height: 28px;
            width: 20px;
            padding: 4px;
            outline: none;
            border-left: 1.5px solid #D7D7D7;
        }

        .input-date {
            width: 220px;
            height: 29px;
            outline: none;
            border-radius: 6px;
            background: white;

            .react-date-picker__wrapper {
                border: none;
            } 
        }

        .btn-icon {
            position: absolute;
            right: 2px;
            top: 4px;
            background: white;
            height: 28px;
            width: 20px;
            outline: none;
            border-radius: 0px;
            border-left: 1.5px solid #D7D7D7;
        }

        .calendar-content {
            margin-top: 12px;
            position: absolute;
            right: -25px;
            top: 0px;
            border-radius: 10px;

            width: 239px;
            height: 251px;
            padding: 19px 15px;
            background: white;

            .text-content {
                font-family: var(--main-font);
                font-weight: 300;
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                color: #2D2D2D;
                margin-bottom: 8px;
            }

            .date-picker-content {
                width: 209px;
                height: 32px;
                background: white;
                font-family: var(--main-font);
                font-weight: 300;
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                color: #2D2D2D;
                outline: none;
            }

            .react-date-picker__wrapper {
                border-radius: 2px;
                border: .5px solid #B7B7B7;
            }

            .react-date-picker__inputGroup {
                padding-left: 16px;
            }

            .calendar-first {
                position: absolute;
                left: 225px;
                top: -77px;
                margin-left: 5px;
                border-radius: 4px;
            }

            .react-calendar__month-view__weekdays {
                padding-bottom: 6px;   
            }

            .react-calendar__month-view__weekdays__weekday {
                color: #2D2D2D;
                font-weight: 600;
                font-style: normal;
                font-size: 8px;
                line-height: 12px;
                text-align: center;
                text-transform: capitalize;
                text-decoration: none;
                padding: 16px 0px;
            }

            .react-calendar__month-view__days__day {
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 16px;
                text-align: center;
                background: white;
                color: #2D2D2D;
            }

            .calendar-second {
                position: absolute;
                left: 225px;
                top: -155px;
                margin-left: 5px;
                border-radius: 4px;
            }

            .btn-reset {
                padding: 9px 22px;
                background: white;
                margin-right: 12px;
                border: 1px solid #4163A4;

                font-family: var(--main-font);
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: #4163A4;
                text-transform: capitalize;
            }

            .btn-apply {
                padding: 9px 22px;
                background: #4163A4;
                border: 1px solid #4163A4;

                font-family: var(--main-font);
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: white;
                text-transform: capitalize;
            }
        }
    }

    .active-display {
        display: none;
    }

    .section-right {
        display: flex;
        align-items: center;

        .button-download {
            width: 110px;
            height: 34px;
            background: white;
            display: flex;
            justify-items: center;
            align-items: center;
            padding-left: 11px;
            padding-top: 8px;
            padding-bottom: 8px;
            border-radius: 4px;
            border: 1px solid #D7D7D7;

            font-family: var(--main-font);
            font-weight: 500;
            font-style: normal;
            font-size: 12px;
            line-height: 18px;
            color: #2D2D2D;
            margin-right: 11px;

        }

        .menu-item {
            height: 34px;
            border: 1px solid #D7D7D7;
            display: flex;
            padding-left: 8px;
            align-items: center;
            cursor: pointer;
        }

        .menu-item-text {
            font-family: var(--main-font);
            font-weight: 400;
            font-style: normal;
            font-size: 12px;
            line-height: 18px;
            color: #2D2D2D;
            padding-left: 8px;
        }
    }
}
