.table-head__container__sku-configuration {
    width: max-content;
    border-radius: 4px;
    position: sticky;
    top: 0;
    z-index: 2;
    background: white;

    .table-head__item:first-child {
        position: sticky;
        z-index: 5;
        left: 0;
    }

    .table-head__item:nth-child(2) {
        position: sticky;
        z-index: 5;
        border-right: 1px solid #cecece;
        left: 325px;
    }

    .table-head__item {
        display: flex;
        align-items: center;
        font-family: var(--main-font);
        font-style: normal;
        text-transform: uppercase;
        color: #2d2d2d;
        font-weight: 500;
        font-size: 12px;
        line-height: 21px;

        @media (min-width: 1441px) {
            font-size: 14px;
        }

        .table-head__parent {
            width: 100%;
            height: 40px;
            align-items: center;
            font-weight: 500;
            text-align: center;
            padding-right: 20px;
            background: #e8edf6;
            font-size: 12px;
            margin-bottom: 4px;
            border-top: 2px solid #f0f2ef;
            border-bottom: 2px solid #f0f2ef;

            @media (min-width: 1441px) {
                font-size: 14px;
            }
        }

        .table-head__child-container {
            display: flex;
            align-items: center;
            flex-direction: column;
            flex: 1;
      
            width: 100%;
            height: 35px;
            background: #ededed;
        }

        .table-head__child,
        .table-head__grand-child {
            flex: 1;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding-left: 10px;

            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-transform: uppercase;

            color: #2d2d2d;
        }

        .table-head__child {
            padding-bottom: 4px;
            margin-bottom: 4px;
            width: 100%;
        }

        .table-head__child-container {
            .table-head__child {
                padding-bottom: 0;

                margin-bottom: 0;

                p {
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
    }

    .table-head__item:first-child {
        .table-head__parent,
        .table-head__child-container {
            border-left: 2px solid #f0f2ef;
            border-radius: 4px 0 0 4px;
        }
    }

    .table-head__item:last-child {
        position: sticky;
        right: 0;
        z-index: 5;
        border-left: 1px solid #cecece;
        .table-head__parent,
        .table-head__child-container {
            justify-content: center;
            border-radius: 0 4px 4px 0;
            border-right: 2px solid #f0f2ef;
            text-align: center;
        }
    }
}
