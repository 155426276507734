.section-footer__container__sku-configuration {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 32px;

    .showing-text {
        font-family: var(--main-font);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;

        display: flex;
        align-items: center;
        text-align: center;
        text-transform: capitalize;
        color: #5c6c8b;
    }

    .pagination-sku-configuration__container {
        ul {
            display: flex;
            list-style-type: none;

            .selected {
                background: var(--main-color-7);

                a {
                    color: white !important;
                }
            }

            li {
                width: 24px;
                height: 24px;
                border: 1px solid #b1b1b1;
                border-radius: 4px;

                text-decoration: none;
                margin: 0 4px;

                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 24px;
                    height: 24px;
                    font-family: var(--main-font);
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 20px;
                    text-align: center;
                    letter-spacing: 1px;

                    color: #1a1a1a;
                }
            }
        }
    }
}
