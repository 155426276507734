.section-head__container__sku-configuration {
    width: 100%;
    justify-content: space-between;
    padding-bottom: 28px;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 2;
    background: white;

    .section-left,
    .section-search__container__sku-configuration {
        position: relative;
        width: 481px;

        .search-input {
            width: 481px;
            height: 38px;
            border: 1px solid #d7d7d7;

            border-radius: 4px;
            outline: none;
            padding-left: 16px;

            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
        }

        .search-icon__container {
            position: absolute;
            right: 12px;
            top: 6px;
        }

        .search-input::placeholder {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;

            color: #b8b8b8;
        }
    }
    .section-right {
        display: flex;
        align-items: center;

        .section-right__button {
            border: 1px solid #1746a2;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 37px;

            .button-text {
                font-family: var(--main-font);
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;

                color: #1746a2;
            }
        }

        .button-add {
            width: 152px;
            background: #0154a2;
            margin-left: 20px;

            .button-text {
                color: #ffffff;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
            }
        }

        .button-export,
        .button-download {
            width: 117px;
            justify-items: center;
            align-items: center;
        }

        .button-export > .button-text {
            margin-right: 12px;
        }
    }
}
