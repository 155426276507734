.root-container-add-new-item {
    background: #ffffff;
    border: 0.5px solid #dcdcdc;
    border-radius: 8px;
    // margin-top: -36px;
    margin-bottom: 50px;
    .header-text-create-new-item {
        align-items: center;
        display: flex;
        border-bottom: 0.5px solid #d7d7d7;
        padding: 29px 10px 17px 10px;

        .btn-back {
            align-items: center;
            gap: 8px;
            cursor: pointer;
        }

        .text-add-item-material-conf {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            text-transform: capitalize;
            color: #1746A2;
        }
    }

    .complete-data {
        align-items: flex-start;
        gap: 73px;
        padding: 23px 15px 32px 15px;
        border-bottom: 0.5px solid #d7d7d7;
        .title-complete-data {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height */

            color: #2d2d2d;
        }

        .tag-complete-data {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            /* or 167% */

            color: #767676;
        }
        .form-add-new-item-container {
            .required-field__text {
                font-weight: 400;
                font-size: 10px;
                line-height: 15px;
                color: #ef5350;
                margin-top: 4px;
            }
            .infinite-scroll-container {
                .enabled-input-supplier-name {
                    border: 0.5px solid #d7d7d7 !important;
                    padding-right: 60px;
                }
                .enabled-input-supplier-name::placeholder {
                    color: #9c9898;
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
                .required-field__text {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 15px;
                    color: #ef5350;
                    margin-top: 4px;
                }
                .divider_input_infinite_scroll {
                    height: 29px;
                    width: 1px;
                    background-color: #d7d7d7;
                    margin-right: 14px;
                }
                .scroll-box-container {
                    max-height: 310px;
                    overflow: auto;
                    background-color: rgb(255, 255, 255);
                    width: 706px;
                    border-radius: 4px;

                    .loading_fetch_next_page {
                        color: var(--black, #757575);
                        font-family: Open Sans;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        margin-left: 12px;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        text-align: center;
                    }

                    .no_data_infinite_scroll {
                        color: var(--black, #757575);
                        font-family: Open Sans;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        text-align: center;
                        position: absolute;
                        left: 0;
                        right: 0;
                        background-color: #ffffff;
                        border-left: 1px solid #e2e8f0;
                        border-right: 1px solid #e2e8f0;
                    }
                    .list_item_infinite {
                        color: var(--black, #2d2d2d) !important;
                        font-family: Open Sans !important;
                        font-size: 12px !important;
                        font-style: normal !important;
                        font-weight: 600 !important;
                        line-height: normal !important;
                        padding: 10px 10px;
                        border-radius: 2px;
                        border-bottom: 0.5px solid #d7d7d7;
                    }
                    .list_item_infinite:hover {
                        background-color: #b9c7e3;
                        color: #fff !important;
                        cursor: pointer;
                    }
                    .list_item_infinite:active {
                        background-color: #e0e0e0;
                    }
                }

                .scroll-box-container::-webkit-scrollbar-track {
                    background: #b7b7b7 !important;
                    border: solid #ffffff 7px !important;
                }

                .scroll-box-container::-webkit-scrollbar {
                    height: 6px !important;
                    width: 15px !important;
                }

                .scroll-box-container::-webkit-scrollbar-thumb {
                    border-radius: 100px !important;
                    background: #4163a4 !important;
                    border: solid #fff 5.7px;
                    border-bottom: transparent !important;
                    border-top: transparent !important;
                }
            }
            width: 706px !important;
            gap: 40px !important;
            display: grid;
            .label-selected-menuList {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                /* identical to box height */

                color: #2d2d2d;
            }
            .child-container {
                display: flex;
                column-gap: 34px;
                row-gap: 40px;
                flex-wrap: wrap;
                max-width: 470px;

                .input-style-add-new-item-MC {
                    width: 213px;
                    height: 48px;
                    outline: none;
                    border: 0.5px solid #d7d7d7;
                    border-radius: 4px;
                    background: #e7e7e7;
                }
                .input-style-add-new-item-MC::placeholder {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #9c9898;
                }
                .right-label-input {
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    width: 71px;
                    height: 48px;
                    left: 142px;
                    top: 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #f8f8f8;
                    border: 0.5px solid #d7d7d7;
                    border-radius: 3px;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    /* identical to box height */

                    text-align: center;

                    color: #9c9898;
                }
            }

            .label {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                /* identical to box height */

                color: #2d2d2d;
            }

            .fieldMenu-placeholder {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height */

                color: #9c9898;
            }

            .menu-button {
                border: 1px solid rgb(165, 165, 165);
                box-sizing: border-box;
                height: 48px !important;
                width: 706px;
                padding-left: 16px;
                border-radius: 4px;
                background: white;
                cursor: pointer;
                flex: 1;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;

                outline: rgb(255, 35, 35);
                border: 0.5px solid #d7d7d7 !important;
                border-radius: 4px !important;
                color: #2d2d2d;

                span:nth-child(1) {
                    text-align: left;
                }
            }
            .menuList-container {
                max-height: 300px !important;
                overflow: auto !important;
                width: 706px;
            }

            .disabled-field-input {
                background: #e7e7e7;
                border: 0.5px solid #d7d7d7;
                border-radius: 4px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height */
                margin-top: 6px;
                color: #9c9898;
            }
        }
    }

    .button-action-material-conf-container {
        display: flex;
        gap: 12px;
        justify-content: flex-end;
        margin-right: 7px;
        .cancel-btn {
            font-family: 'Poppins';
            cursor: pointer;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height */
            display: flex;
            align-items: center;
            justify-content: center;
            background: #ffffff;
            border: 0.5px solid #dcdcdc;
            border-radius: 8px;
            color: #4163a4;
            width: 93px;
            height: 32px;

            border-radius: 2px;
        }
        .save-btn {
            font-family: 'Poppins';
            cursor: pointer;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height */
            width: 85px;
            height: 32px;
            background: #4163a4;
            border-radius: 2px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .btn-submit-add-new-item {
        gap: 12px;
        justify-content: flex-end;
        max-width: 1061px;
        padding-top: 36px;
        padding-bottom: 44px;

        .btn-save-item {
            width: 85px;
            padding: 5px 12px !important;
            color: #fff !important;
            font-family: Poppins !important;
            font-size: 12px !important;
            font-style: normal !important;
            font-weight: 600 !important;
            line-height: normal !important;
        }
        .btn-save-draft {
            width: 93px;
            color: #4163a4 !important;
            text-align: center !important;
            font-family: Poppins !important;
            font-size: 12px !important;
            font-style: normal !important;
            font-weight: 600 !important;
            line-height: normal !important;
            padding: 5px 12px !important;
        }
    }
}
