.pagination-container-style {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 32px;
    background: #e8eef9;
    padding: 10px 8px;
    .label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        /* identical to box height */

        color: #b2b2b2;
    }
    .menu-button {
        background: #ffffff;
        border: 0.5px solid #2d2d2d;
        border-radius: 2px;
        width: 53px !important;
        height: 20px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        /* identical to box height */
        color: #000000;
        padding-left: 7px !important;

        span:nth-child(1) {
            text-align: left;
        }
    }
    .menu-item {
        font-family: 'Poppins' !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 10px !important;
        line-height: 15px !important;
        /* identical to box height */

        color: #000000;
        width: 53px !important;
    }
    .menuList-container {
        max-height: 300px !important;
        overflow: auto !important;
        padding: 0px;
        min-width: 40px;
        background-color: pink;
        border-radius: 2px;
    }
    .pagination-material-configuration__container {
        .wrapper-label-text {
            display: flex;
            align-items: center;
            margin-right: 43px;

            .label-text-order {
                font-family: var(--main-font);
                font-weight: 500;
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                color: #2D2D2D;
                padding-right: 18px;
            }
            .label-value {
                font-family: var(--main-font);
                font-weight: 400;
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                color: #000;
             }
            .pallet-text {
                @extend .label-value;
                font-family: var(--main-font);
                font-weight: 400;
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                padding-right: 24px;
                color: #EA4F4F;
            }
            .koli-text {
                @extend .label-value;
                font-family: var(--main-font);
                font-weight: 400;
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                color: #004199;
                padding-right: 24px;
            }
            .pcs-text {
                @extend .label-value;
                font-family: var(--main-font);
                font-weight: 400;
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
                color: #267346;
            }
        }
        ul {
            display: flex;
            list-style-type: none;

            .selected {
                background: var(--main-color-7);

                a {
                    color: white !important;
                }
            }

            li {
                width: 24px;
                height: 24px;
                border: 1px solid #b1b1b1;
                border-radius: 4px;

                text-decoration: none;
                margin: 0 4px;
                background: white;

                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 24px;
                    height: 24px;
                    font-family: var(--main-font);
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 20px;
                    text-align: center;
                    letter-spacing: 1px;
                    color: #2d2d2d;
                }
            }
        }
    }
}
