.section-select-filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 12px;
    position: relative;
    width: 170px;
    height: 34px;
    font-size: 12px;
    background: #ffffff;
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    margin-right: 8px;

    .text-select-streamline {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #2d2d2d;
    }
}
.filterList {
    border: 1px solid #d7d7d7;
    border-radius: 4px 4px 0px 0px;

    .button-filter-options {
        padding: 0px;
        display: flex;
        justify-content: flex-start;
        padding: 5px 12px;
        border-radius: 0;
        border: 1px solid #d7d7d7;

        .text-select-filter {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            padding: 8px 0px;
            color: #2d2d2d;
        }
    }
}
.filterList:focus {
    outline: none !important;
    box-shadow: none !important;
}
