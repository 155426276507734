.container-column-notes-supplier-stock {
    .input-text {
        border: none;
        outline: none;
        box-shadow: none;
        font-family: var(--main-font);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-transform: capitalize;
        color: #2d2d2d;
    }
}
