.text-status-material-config {
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 95px;
    text-align: center;
    border-radius: 4px;
    padding: 4px;
}
