.global-header__container {
    height: 56px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--default-grey);
    padding: 22px 24px;
    z-index: 3;

    .section-left__head-info {
        display: flex;
        align-items: center;

        .dpps-text {
            font-family: 'Open Sans';
            font-weight: 800;
            font-size: 28px;
            line-height: 38px;
            text-align: center;
            color: rgba(34, 54, 71, 1);
        }

        .material-text {
            font-family: var(--main-font);
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            text-transform: capitalize;
            display: flex;
            align-items: center;
            text-align: center;
            color: #2d2d2d;
            margin-right: 9px;
        }

       .menu-button {
            background: white !important;
            height: 36px;
            width: max-content;
            // width: 225px;
            border: 1px solid #D7D7D7 !important;
            border-radius: 4px !important;

            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #2D2D2D;
            margin-left: 16px;

            .chakra-button__icon {
                margin-left: 16px;
            }
        }

        .menu-item {
            display: flex;
            align-items: center;
            height: 38px;

            .menu-item-text {
            color: var(#2D2D2D);
            font-family: var(--main-font);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            text-align: left;
            }
        }

        .container-item-supplier {
            display: grid;
            gap: 12px;
            .checkbox-custom-style {
                .css-1ydjfm6 {
                    width: 15px;
                    height: 15px;
                    border-color: #2d2d2d;
                    border-width: 2.5px;
                    position: absolute;
                    top: -6.5px;
                    right: -8px;
                }
                .css-1ydjfm6[data-checked] {
                    background: #1746a2;
                    border-color: #1746a2;
                }
            }

        }

        .menu-item {
            display: flex;
            align-items: center;
            height: 38px;

            .menu-item-text {
            color: var(#2D2D2D);
            font-family: var(--main-font);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            text-align: left;
            }
        }

        // ---media-query 1500px to 1024px 
        @media only screen and (max-width: 1500px) and (min-width: 1024px) {
        .wrapper-info__icon {
            display: block;
        }

        .material-text,
        .wrapper-info__icon {
            display: none;
        }
     }
    }

    .section-right__head-info {
        .week-text__head-info {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #2d2d2d;
            margin-right: 20px;
        }

        .date-text__head-info,
        .time-text__head-info {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #2d2d2d;
            margin-right: 20px;
        }

        .time-text__head-info {
            margin-right: 50px;
        }
        
        // ---media-query 1500px to 1024px 
        @media only screen and (max-width: 1500px) and (min-width: 1024px) {
        .wrapper-info__icon {
            display: block;
        }

        .wrapper-refresh-icon,
        .wrapper-toogle-fullscreen, 
        .wrapper-info__icon {
            display: none;
        }
     }
    }
}
