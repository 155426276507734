.table-column__container__order-planning {
    border-bottom: 1px solid #cecece;
    flex-direction: column;
    background: white;


    .column__item:first-child {
        position: relative;
    }

    .floating-action-column {
        background: white;
        position: sticky;
        right: 0;
        width: 101px;
        border-left: 1px solid #cecece;

        .box-icon {
            border-radius: 4px;
            padding: 2px;
        }

        .floating-action__button {
            display: flex;
            justify-content: center;
            gap: 8px;
            align-items: center;
            margin-bottom: 16px;
        }
    }

    #column__item--0,
    #column__item--1,
    #column__item--2{
        position: sticky;
        left: 0;
        z-index: 2;
    }

    #column__item--1 {
        left: 164px;
    }

    #column__item--2 {
        border-right: 1px solid #cecece;
        left: 284px;


    }

    .column__item {
        display: flex;
        padding: 9px 0;


        .checkbox-input>span {
            border-radius: 50%;
            height: 20px;
            width: 20px;
            border: 1px solid grey;
        }

        .column-status {
            font-family: var(--main-font);
            font-style: normal;

            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            text-transform: capitalize;
            padding: 7px 12px;
            border-radius: 43px;
            width: 118px;
            font-weight: 500;
            font-size: 8px;
            line-height: 15px;
            padding: 0;
            height: 22px;

            @media (min-width: 1441px) {
                font-size: 10px;
            }
        }

        .column-text,
        .column-oddo,
        .column-text__child {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 21px;
            color: #2d2d2d;
            padding-right: 16px;
            height: max-content;
            padding-left: 12px;

            @media (min-width: 1441px) {
                // font-size: 14px;
            }
        }

        .column-text__child {
            width: 100%;
            text-align: left;
        }

        // .column-oddo {
        //     opacity: 0.3;
        // }

        .column__input {
            width: 115px;
            // height: 30px;
            border: 1px solid #b1b1b1;
            padding: 0 16px;
            border-radius: 0px;
            text-align: center;
        }

        .column__input_second {
            // height: 30px;
            border: 1px solid #b1b1b1;
            border-radius: 0px;
            text-align: center;
        }
    }

    .column__item-child {
        display: flex;
        width: 100%;
        font-size: 12px;

        @media (min-width: 1441px) {
            // font-size: 14px;
        }
    }

    .column-item__multiple-child{
        .column-text__child{
            text-align: center;

        }
    }
}