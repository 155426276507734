.forbidden-page__container {
    width: 100%;
    height: calc(100vh - 249px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    border: 0.5px solid #d7d7d7;
    border-radius: 4px;
    .title {
        font-weight: 500;
        font-size: 32px;
        line-height: 48px;
        text-align: center;
        margin: 28px 0 24px;
        color: #2d2d2d;
    }

    .desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #2d2d2d;
    }
}
