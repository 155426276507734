.table-head__container__asset-management {
    width: 100%;
    .table-head__item {
        display: flex;
        justify-content: flex-start;
        padding-left: 32px;
        align-items: center;

        p {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            text-transform: uppercase;
            color: #2d2d2d;
        }

        background: var(--default-grey);
        height: 33px;
        background-color: rgba(23, 70, 162, 0.1);
        border-top: 0.5px solid rgba(23, 70, 162, 0.1);
        border-bottom: 0.5px solid rgba(23, 70, 162, 0.1);
    }

    .table-head__item:first-child {
        justify-content: flex-start;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-bottom: 0.5px solid rgba(23, 70, 162, 0.1);
        border-left: 0.5px solid rgba(23, 70, 162, 0.1);
        border-top: 0.5px solid rgba(23, 70, 162, 0.1);
        height: 33px;
    }

    .table-head__item:last-child {
        justify-content: center;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom: 0.5px solid rgba(23, 70, 162, 0.1);
        border-right: 0.5px solid rgba(23, 70, 162, 0.1);
        border-top: 0.5px solid rgba(23, 70, 162, 0.1);
        height: 33px;
    }

    #table-head__item-name {
        justify-content: flex-start;
    }

    .table-head__item-name {
        text-align: left !important;
    }
}
