.main-component__container {
  margin-left: 73px;

  .label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #2d2d2d;
        display: flex;
        width: 114px;
        margin-right: 32px;
        margin-bottom: 6px;
    }

  .fieldmenu-input__main-component {
    border: 0.5px solid #D7D7D7;
    width: 210px;
    height: 48px;

    font-family: var(--main-font);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    color: #2D2D2D;
  }
  .fieldmenu-input__main-component:disabled {
    border: 0.5px solid #D7D7D7;
    width: 210px;
    height: 48px;

    font-family: var(--main-font);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    color: #9c9898;
    background-color: #eff4ff;
  }

  .title-fieldmenu {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    text-transform: capitalize;
    color: #2D2D2D;
    margin-bottom: 6px;
  }

  .input-field-disabled {
    width: 462px;
    height: 48px;
    border: 0.5px solid #d7d7d7;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-top: 6px;
    color: #9c9898;
  }

  .required-field__text {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: #ef5350;
        margin-top: 4px;
    }

    .required-field__input {
      border: 0.5px solid #ef5350;
    }

  .scroll-container {
        max-height: 310px;
        overflow: auto;
        background-color: rgb(255, 255, 255);
        // width: 706px;
        width: 210px;
        border-radius: 4px;

        .loading_fetch_next_page {
            color: var(--black, #757575);
            font-family: Open Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-left: 12px;
            padding-top: 10px;
            padding-bottom: 10px;
            text-align: center;
        }

        .no_data_infinite_scroll {
            color: var(--black, #757575);
            font-family: Open Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding-top: 10px;
            padding-bottom: 10px;
            text-align: center;
            position: absolute;
            left: 0;
            right: 0;
            background-color: #ffffff;
            border-left: 1px solid #e2e8f0;
            border-right: 1px solid #e2e8f0;
        }
        .list_item_infinite {
            color: var(--black, #2d2d2d) !important;
            font-family: Open Sans !important;
            font-size: 12px !important;
            font-style: normal !important;
            font-weight: 600 !important;
            line-height: normal !important;
            padding: 10px 10px;
            border-radius: 2px;
            border-bottom: 0.5px solid #d7d7d7;
        }
        .list_item_infinite:hover {
            background-color: #b9c7e3;
            color: #fff !important;
            cursor: pointer;
        }
        .list_item_infinite:active {
            background-color: #e0e0e0;
        }
    }

    .scroll-container::-webkit-scrollbar-track {
        background: #b7b7b7 !important;
        border: solid #ffffff 7px !important;
    }

    .scroll-container::-webkit-scrollbar {
        height: 6px !important;
        width: 15px !important;
    }

    .scroll-container::-webkit-scrollbar-thumb {
        border-radius: 100px !important;
        background: #4163a4 !important;
        border: solid #fff 5.7px;
        border-bottom: transparent !important;
        border-top: transparent !important;
    }
}