.table-head__container__order_confirmation {
    width: max-content;
    border-radius: 4px;
    position: sticky;
    top: 0;
    z-index: 2;
    background: white;

    .active-margin__right {
        margin-right: 30px;
    }

    .table-head__item {
        display: flex;
        align-items: center;
        text-transform: capitalize;

        font-family: var(--main-font);
        font-style: normal;
        color: #2d2d2d;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;

        @media (min-width: 1441px) {
            font-size: 12px;
        }

        .table-head__parent {
            width: 100%;
            height: 40px;
            align-items: center;
            color: #2d2d2d;
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            background: #edf0f6;
            margin-bottom: 3px;
            text-transform: capitalize;
            border-top: 2px solid #f0f2ef;
            border-bottom: 2px solid #f0f2ef;
            justify-content: center;

            @media (min-width: 1441px) {
                font-size: 12px;
            }
        }

        .table-head__child-container {
            display: flex;
            align-items: center;
            flex-direction: column;
            flex: 1;
            text-align: center;
            width: 100%;
            height: 18px;
            background: #ededed;
        }

        .table-head__child,
        .table-head__grand-child {
            flex: 1;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding-left: 10px;

            font-family: var(--main-font);
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 18px;
            text-transform: capitalize;
            color: #2d2d2d;
        }

        .table-head__child {
            padding-bottom: 4px;
            margin-bottom: 4px;
            width: 100%;
            height: 16px;
        }

        .table-head__child-container {
            .table-head__child {
                padding-bottom: 0;
                margin-bottom: 0;

                p {
                    color: #2d2d2d;
                    text-align: center;
                    font-family: Poppins;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }
    .table-head__item:nth-child(1) {
        .table-head__parent,
        .table-head__child-container {
            display: flex;
            align-items: center;
        }
    }
    .table-head__item:nth-child(3) {
        .table-head__parent,
        .table-head__child-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .table-head__item:nth-child(4) {
        .table-head__parent,
        .table-head__child-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .table-head__item:nth-child(5) {
        .table-head__parent,
        .table-head__child-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .table-head__item:nth-child(6) {
        .table-head__parent,
        .table-head__child-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .table-head__item:nth-child(7) {
        .table-head__parent,
        .table-head__child-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .table-head__item:nth-child(8) {
        .table-head__parent,
        .table-head__child-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .table-head__item:last-child {
        .table-head__parent,
        .table-head__child-container {
            color: #b7b7b7;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .table-head__item_action_btn {
        display: flex;
        align-items: center;
        text-transform: capitalize;
        justify-content: center;
        background-color: #edf0f6;
        width: 90px;
        position: sticky;
        right: 0;
        border-left: 1px solid #cecece;
        /// text
        .text-action {
            color: #2d2d2d;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
}
