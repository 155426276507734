.modal-item__container_secondForm {
    display: flex;
    width: 100%;
    gap: 60px;
    padding: 32px 38px;

    .icon-eye__container {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
        z-index: 2;
    }
    .FieldMenu-placeholder {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #9c9898;
    }
    .selected-role-text {
        font-family: 'Poppins' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 21px !important;
        color: #2d2d2d !important;
    }
    .menuList-container {
        width: 100%;
        margin-top: -10px;
    }
    .divider_input_infinite_scroll {
        height: 29px;
        width: 1px;
        background-color: #d7d7d7;
        margin-right: 14px;
    }
    .scroll-container {
        max-height: 310px;
        overflow: auto;
        background-color: rgb(255, 255, 255);
        width: 706px;
        border-radius: 4px;

        .loading_fetch_next_page {
            color: var(--black, #757575);
            font-family: Open Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-left: 12px;
            padding-top: 10px;
            padding-bottom: 10px;
            text-align: center;
        }

        .no_data_infinite_scroll {
            color: var(--black, #757575);
            font-family: Open Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding-top: 10px;
            padding-bottom: 10px;
            text-align: center;
            position: absolute;
            left: 0;
            right: 0;
            background-color: #ffffff;
            border-left: 1px solid #e2e8f0;
            border-right: 1px solid #e2e8f0;
        }
        .list_item_infinite {
            color: var(--black, #2d2d2d) !important;
            font-family: Open Sans !important;
            font-size: 12px !important;
            font-style: normal !important;
            font-weight: 600 !important;
            line-height: normal !important;
            padding: 10px 10px;
            border-radius: 2px;
            border-bottom: 0.5px solid #d7d7d7;
        }
        .list_item_infinite:hover {
            background-color: #b9c7e3;
            color: #fff !important;
            cursor: pointer;
        }
        .list_item_infinite:active {
            background-color: #e0e0e0;
        }
    }

    .scroll-container::-webkit-scrollbar-track {
        background: #b7b7b7 !important;
        border: solid #ffffff 7px !important;
    }

    .scroll-container::-webkit-scrollbar {
        height: 6px !important;
        width: 15px !important;
    }

    .scroll-container::-webkit-scrollbar-thumb {
        border-radius: 100px !important;
        background: #4163a4 !important;
        border: solid #fff 5.7px;
        border-bottom: transparent !important;
        border-top: transparent !important;
    }
    .label-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #2d2d2d;
        margin-bottom: 6px;
    }

    .label-description {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #2d2d2d;
        margin-bottom: 17px;
    }

    .label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #2d2d2d;
        display: flex;
        width: 114px;
        margin-right: 32px;
        margin-bottom: 6px;
    }

    .required-field__text {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: #ef5350;
        margin-top: 4px;
    }
    .field-menu-list__container {
        width: 706px;

        .field-menu-item__container {
            height: 38px;
            border-bottom: 0.5px solid #d7d7d7;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;

            /* Black */

            color: #2d2d2d;
        }

        .field-menu-item__container:last-child {
            border-bottom: none;
        }

        .field-menu-item__container:hover {
            background: var(--main-color-3);
            color: white;
        }
    }

    .input-container,
    select,
    .menu-button {
        border: 1px solid rgb(165, 165, 165);
        box-sizing: border-box;
        height: 48px !important;
        width: 706px !important;
        padding-left: 16px;
        border-radius: 4px;
        background: white !important;
        cursor: pointer;
        border: 0.5px solid #d7d7d7 !important;
        border-radius: 4px !important;
        flex: 1;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        outline: grey;
        color: #2d2d2d;
        span:nth-child(1) {
            text-align: left;
        }
    }
    .menu-button:hover {
        background-color: transparent;
    }
    .menu-button-error {
        border: 1px solid #ef5350 !important;
    }
    .uppercase-text {
        text-transform: uppercase;
    }
    .disabled-input-supplier-name {
        background-color: #d3e0fc !important;
        border: 0.5px solid #9e9e9e !important;
    }
    .enabled-input-supplier-name {
        border: 0.5px solid #d5d3d3 !important;
    }
    .placeholder,
    .input-container::placeholder {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #9c9898;
        text-transform: capitalize;
    }

    .error-empty__field {
        border: 0.5px solid #ef5350 !important;
    }

    .menu-input__multiple-container {
        flex: 1;

        .selected-multiple-input__container {
            min-height: 40px;
            flex: 1;

            position: relative;
            border: 1px solid rgb(165, 165, 165);
            box-sizing: border-box;
            gap: 8px;
            align-items: center;
            border-radius: 4px;
            padding: 4px 8px 4px 16px;

            .empty-material__text {
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                text-align: center;
                color: #9c9898;
            }

            .selected-multiple-input__item {
                position: relative;
                height: 32px;
                width: max-content;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                padding: 4px 8px;
                border: 1px solid #1746a2;
                border-radius: 4px;
                color: #1746a2;
                font-weight: 500;
            }

            .text-show-more__container {
                background: white;
                font-size: 10px;
                color: #9c9898;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 15px;
                height: 40px;
                width: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 0;
            }
        }

        .button-choosed__container {
            cursor: pointer;
            height: 48px;
            width: 133px;
            border: 0.5px solid #1746a2;
            border-radius: 4px;
            justify-content: space-between;
            align-items: center;
            padding: 0 6px 0 16px;
            margin-left: 8px;

            p {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                text-align: center;

                color: #1746a2;
            }
        }
    }
}
