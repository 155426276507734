.form-after-generate-container {
    padding: 0px 15px 23px 15px;
    .form-container {
        display: flex;
        flex-direction: column;
        .item-code-container {
            .label-input-item-name {
                color: #2d2d2d;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            .input-item-name {
                height: 48px;
                margin-top: 6px;
                border-radius: 4px;
                border: 0.5px solid #d7d7d7;
                padding: 15px 17px;
                /// text
                color: var(--Black, #2d2d2d);
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: uppercase;
                min-width: 706px;
                outline: none;
            }
            .input-item-name:disabled {
                @extend .input-item-name;
                background-color: #d7d7d7;
            }
        }
        .input-qty-ospo {
            min-width: 335px;
            height: 48px;
            margin-top: 6px;
            border-radius: 4px;
            border: 0.5px solid #d7d7d7;
            padding: 15px 17px;
            /// text
            color: var(--Black, #2d2d2d);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
            outline: none;
        }
        .input-qty-ospo:disabled {
            @extend .input-qty-ospo;
            background-color: #d7d7d7;
        }

        .no-po-no-ospo-container {
            margin-top: 40px;
            display: flex;
            gap: 20px;
            align-items: center;
            .label-input-no-po-no-ospo {
                margin-bottom: 6px;
                color: #2d2d2d;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .due-date-container {
            margin-top: 40px;
            margin-bottom: 40px;
            .label-input-due-date {
                color: #2d2d2d;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            .input-due-date__container {
                .calender_due-date {
                    width: 166px;
                    height: 48px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;

                    .date-picker-content {
                        width: 204px;
                        height: 48px;

                        background: white;
                        font-family: var(--main-font);
                        font-style: normal;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 18px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: #b1b1b1;
                        outline: none;

                        .react-date-picker__wrapper {
                            height: 48px;
                            margin-top: 6px;
                            border-radius: 4px;
                            border: 0.5px solid #d7d7d7;
                            padding: 15px 17px;
                            /// text
                            color: var(--Black, #2d2d2d);
                            font-family: Poppins;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            text-transform: uppercase;
                            min-width: 706px;
                            outline: none;
                        }
                        .react-date-picker__inputGroup__input:invalid {
                            background: unset;
                        }

                        .react-date-picker__inputGroup {
                            padding: 0px 6px;
                        }

                        .react-date-picker__button {
                            display: flex;
                            align-items: center;
                            padding: 6px;
                        }

                        .react-date-picker__calendar {
                            width: 350px;
                            max-width: 100vw;
                            z-index: 5;
                        }
                    }

                    .required-field__text {
                        color: red;
                        margin-top: 4px;
                        font-size: 12px;
                        font-family: 'Lato';
                    }

                    .calendar-first {
                        width: 250px;
                        position: absolute;
                        left: -4px;
                        top: -334px;
                        margin-left: 5px;
                        border-radius: 4px;
                    }

                    .react-calendar__month-view__weekdays {
                        padding-bottom: 6px;
                    }

                    .react-calendar__month-view__weekdays__weekday {
                        color: #2d2d2d;
                        font-weight: 600;
                        font-style: normal;
                        font-size: 8px;
                        line-height: 12px;
                        text-align: center;
                        text-transform: capitalize;
                        text-decoration: none;
                        padding: 16px 0px;
                    }

                    .react-calendar__month-view__days__day {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 16px;
                        text-align: center;
                        background: white;
                        color: #2d2d2d;
                    }
                }
            }
        }
        .btn-submit {
            margin-top: 24px;
            align-self: flex-end;
            height: 32px;
            padding: 5px 12px;
            border-radius: 2px;
            border: 1px solid #0154a1;
            background-color: #0154a1;
            /// text
            color: #fff;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
}
