.table-head__container__streamline-management {
    width: 100%;
    margin-bottom: 14px;
    padding: 0 52px;
    font-family: var(--main-font);
    background: #e8ecf6;
    position: sticky;
    top: 98px;
    z-index: 1;
    border-radius: 4px;
    border: 2px solid #f0f2ef;

    .table-head__item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: var(--main-font);
        font-style: normal;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        color: #2d2d2d;
        height: 33px;
    }

    .table-head__button:hover,
    .table-head__button[data-active] {
        background: none;
    }

    .table-head__text {
        text-transform: uppercase;
        color: #2d2d2d;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
    }
    .table-head__item:first-child {
        border-radius: 4px 0px 0px 4px;
        // border-left: 2px solid #f0f2ef;
    }

    .table-head__item:last-child {
        justify-content: center;
        border-radius: 0px 4px 4px 0px;
        // border-right: 2px solid #f0f2ef;
    }
}
