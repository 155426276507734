.streamline-management__container {
    height: calc(100vh - 175px);
    overflow-y: auto;
    font-family: var(--main-font);
    background: white;
    border: 0.5px solid #d7d7d7;
    border-radius: 4px;
    box-sizing: border-box;
}

.streamline-management__container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.streamline-management__container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
