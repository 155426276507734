.section-add-more-order {
    font-family: var(--main-font);
    background: white;
    border: 0.5px solid #d7d7d7;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: hidden;
    min-height: 100vh;
    margin-bottom: 48px;

    .title-header-container {
        display: flex;
        border-bottom: #d7d7d7 solid 0.5px;
        padding-top: 30px;
        padding-left: 10px;
        padding-bottom: 17px;
        .back-btn {
            align-items: center;
            gap: 8px;
            .title {
                color: #1746a2;
                text-align: center;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: capitalize;
            }
        }
    }
    .divider {
        height: 0.5px;
        width: 100%;
        background-color: #d7d7d7;
        margin-top: 32px;
        margin-bottom: 36px;
    }
}

// ---media-query for zoom 67%
@media (min-width: 1724px) and (max-width: 2051px) {
    .section-add-more-order {
        width: calc(100vw - 288px);
    }
}

// ---media-query for zoom 50%
@media (min-width: 2052px) and (max-width: 2732px) {
    .section-add-more-order {
        width: calc(100vw - 927px);
        // background: yellow;
    }
}

// ---media-query for zoom 33%
@media (min-width: 2733px) and (max-width: 4102px) {
    .section-add-more-order {
        width: calc(100vw - 2203px);
    }
}

// ---media-query for zoom 25%
@media (min-width: 4103px) and (max-width: 5464px) {
    .section-add-more-order {
        width: calc(100vw - 3481px);
    }
}

.section-add-more-order::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.section-add-more-order {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
