.table-container-recap-order {
    overflow-x: auto;
    border-radius: 4px;

    .table-recap-order {
        /// start stackoverflow
        /// question : https://stackoverflow.com/questions/50361698/border-style-do-not-work-with-sticky-position-element
        /// answer by blackheart : https://stackoverflow.com/users/3238377/blackheart
        border-collapse: separate !important;
        border-spacing: 0 !important;
        /// end stackoverflow
        ///
        .empty-state-container {
            padding: 0px;

            .empty-state-box {
                width: calc(100vw - 337px) !important;
                position: sticky !important;
                left: 0 !important;
            }
        }
    }
}
.table-container-recap-order::-webkit-scrollbar-track {
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    background: rgba(181, 176, 176, 0.3);
}

.table-container-recap-order::-webkit-scrollbar {
    height: 7px;
    width: 0;
}

.table-container-recap-order::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: #4163a4;
}
