.container-filter-item-code-component {
    .btn-filter-code-item-container {
        position: relative;
        .btn-filter-badge {
            position: absolute;
            width: 18px;
            height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ef5350;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 15px;
            border-radius: 100%;
            color: #ffffff;
            top: -10px;
            right: -10px;
        }
        border: 1px solid #b1b1b1;
        border-radius: 2px;
        width: 160px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 12px;
        padding-right: 12px;
        cursor: pointer;
        .btn-filter-code-item-icon {
            border-left: 0.5px solid #d7d7d7;
            height: 29px;
            display: flex;
            align-items: center;
            padding-left: 10.25px;
        }
        .btn-filter-text {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height */

            color: #d7d7d7;
        }
    }
    .has-item-code-selected {
        border: 1px solid #1746a2 !important;
        border-radius: 2px !important;
    }
}
