.create-and-edit-modal__wrapper__usermanagement {
    position: absolute;
    left: 16px;
    right: 16px;
    top: 16px;
    background: #fff;

    .create-and-edit-modal__container__usermanagement {
        width: 100%;

        .section-head__container {
            padding-top: 47px;
            padding-bottom: 38px;
            padding-left: 35px;
            border-bottom: 0.5px solid #d7d7d7;

            .head-title__container {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                align-items: center;
                text-align: center;
                text-transform: capitalize;

                color: #1746a2;

                margin-left: 110px;
            }

            .arrow-back__text {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                margin-left: 4px;
                display: flex;
                align-items: center;
                text-align: center;
                text-transform: capitalize;

                color: #1746a2;
            }
        }

        .section-body__container {
            margin-bottom: 56px;
            width: 100%;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            // flex-direction: column;

            .modal-item__container {
                display: flex;
                width: 100%;
                gap: 60px;
                padding: 40px 38px 32px 38px;
                .FieldMenu-placeholder {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    /* identical to box height */

                    color: #9c9898;
                }

                .selected-role-text {
                    font-family: 'Poppins' !important;
                    font-style: normal !important;
                    font-weight: 500 !important;
                    font-size: 14px !important;
                    line-height: 21px !important;
                    color: #2d2d2d !important;
                }
                .menu-list-container {
                    max-height: 300px;
                    overflow: auto;
                    width: 100%;
                }
                .menu-list-container::-webkit-scrollbar-track {
                    background: #b7b7b7 !important;
                    border: solid #ffffff 7px !important;
                }

                .menu-list-container::-webkit-scrollbar {
                    height: 6px !important;
                    width: 15px !important;
                }

                .menu-list-container::-webkit-scrollbar-thumb {
                    border-radius: 100px !important;
                    background: #4163a4 !important;
                    border: solid #fff 5.7px;
                    border-bottom: transparent !important;
                    border-top: transparent !important;
                }
                .label-title {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #2d2d2d;
                    margin-bottom: 6px;
                }

                .label-description {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #2d2d2d;
                    margin-bottom: 17px;
                }

                .label {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    color: #2d2d2d;
                    display: flex;
                    width: 110px;
                    margin-right: 32px;
                    margin-bottom: 6px;
                }

                .required-field__text {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 15px;
                    color: #ef5350;
                    margin-top: 4px;
                }

                .field-menu-list__container {
                    width: 706px;

                    .field-menu-item__container {
                        height: 38px;
                        border-bottom: 0.5px solid #d7d7d7;
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 16px;

                        /* Black */

                        color: #2d2d2d;
                    }

                    .field-menu-item__container:last-child {
                        border-bottom: none;
                    }

                    .field-menu-item__container:hover {
                        background: var(--main-color-3);
                        color: white;
                    }
                }

                .input-container,
                select,
                .menu-button {
                    border: 1px solid rgb(165, 165, 165);
                    box-sizing: border-box;
                    height: 48px !important;
                    width: 706px !important;
                    padding-left: 16px;
                    border-radius: 4px;
                    background: white;
                    cursor: pointer;
                    flex: 1;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    border: 0.5px solid #d7d7d7 !important;
                    border-radius: 4px !important;
                    outline: grey;

                    color: #2d2d2d;

                    span:nth-child(1) {
                        text-align: left;
                    }
                }
                .uppercase-text {
                    text-transform: uppercase;
                }
                .placeholder,
                .input-container::placeholder {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #9c9898;
                    text-transform: capitalize;
                }

                .error-empty__field {
                    border: 0.5px solid #ef5350 !important;
                }

                .menu-input__multiple-container {
                    flex: 1;

                    .selected-multiple-input__container {
                        min-height: 40px;
                        flex: 1;

                        position: relative;
                        border: 1px solid rgb(165, 165, 165);
                        box-sizing: border-box;
                        gap: 8px;
                        align-items: center;
                        border-radius: 4px;
                        padding: 4px 8px 4px 16px;

                        .empty-material__text {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            text-align: center;
                            color: #9c9898;
                        }

                        .selected-multiple-input__item {
                            position: relative;
                            height: 32px;
                            width: max-content;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 12px;
                            padding: 4px 8px;
                            border: 1px solid #1746a2;
                            border-radius: 4px;
                            color: #1746a2;
                            font-weight: 500;
                        }

                        .text-show-more__container {
                            background: white;
                            font-size: 10px;
                            color: #9c9898;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 10px;
                            line-height: 15px;
                            height: 40px;
                            width: 80px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: absolute;
                            right: 0;
                        }
                    }

                    .button-choosed__container {
                        cursor: pointer;
                        height: 48px;
                        width: 133px;
                        border: 0.5px solid #1746a2;
                        border-radius: 4px;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 6px 0 16px;
                        margin-left: 8px;

                        p {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 18px;
                            text-align: center;

                            color: #1746a2;
                        }
                    }
                }
            }

            .materials-selected__container {
                border: 1px solid rgb(165, 165, 165);
                border-radius: 8px;

                margin-bottom: 32px;
                .material-selected__item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid rgb(165, 165, 165);
                    box-sizing: border-box;
                    height: 40px;
                    width: 100%;
                    padding: 0 16px;
                    background: white;
                    flex: 1;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    cursor: auto;

                    outline: grey;

                    color: #939393;
                }

                .material-selected__item:first-child {
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                }
                .material-selected__item:last-child {
                    border: none;
                    border-radius: 8px;
                }
            }
        }

        .section-footer__container {
            justify-content: flex-end;
            align-items: center;
            padding-bottom: 48px;

            .disable-button-add__container {
                background: rgba(23, 70, 162, 0.2) !important;
            }

            .button-add__container,
            .button-cancel__container {
                font-family: var(--main-font);
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: white;
                background: #1746a2;

                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                width: 117px;
                height: 42px;
            }

            .button-cancel__container {
                margin-left: 24px;
                background: white;
                color: #1746a2;
                border: 1px solid #1746a2;
            }
        }
    }

    .box-warn__container {
        background: rgba(91, 192, 248, 0.2);
        border: 1px solid #5bc0f8;
        border-radius: 2px;
        height: 34px;
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 10px;

        .warn-email__text {
            font-weight: 400;
            font-size: 10px;
            line-height: 15px;
            text-align: center;
            margin-left: 6px;
            color: #2d2d2d;
        }
    }
}
