.modal-delete-global__container {
    .description-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        width: 268px;

        span {
            color: #ef5350;
            font-weight: 500;
        }
    }
}
