@import '@styles/baseColor';

.title__container__login-page {
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    color: white;
    font-weight: 700;
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-bottom: 6px;

    animation-name: animate-title;
    animation-duration: 1s;

    .logo-text {
        font-weight: 600;
        font-size: 42px;
        line-height: 63px;
        color: #1746a2;
        margin-left: 8px;
        color: var(--main-color);
    }
}

@keyframes animate-title {
    0% {
        transform: translateY(70px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
