.section-head__container__streamline-management {
    width: 100%;
    justify-content: space-between;
    padding-bottom: 28px;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 2;
    background: white;

    .field-menu-list__container {
        max-height: 300px;
        overflow: auto;
    }

    .section-search__container__streamline-management {
        position: relative;
        width: 481px;

        .search-input {
            width: 481px;
            height: 38px;
            border: 1px solid #d7d7d7;
            border-radius: 4px;
            outline: none;
            padding-left: 16px;

            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
        }

        .search-icon__container {
            position: absolute;
            right: 12px;
            top: 6px;
        }

        .search-input::placeholder {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;

            color: #b8b8b8;
        }
    }

    .button-add-streamline__container {
        padding: 0 18px;
        font-family: var(--main-font);
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: #2d2d2d;
        background: var(--main-color);
        color: white;
        border: none;

        height: 37px;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }
}
