.section-order__container__main-dashboard {
    width: calc(100vw - 300px);
    max-height: calc(100vh - 164px);
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    display: flex;
    // justify-content: center;

    .card__container_empty {
        position: relative;
        background: #ffffff;
        border-radius: 4px;
        margin-bottom: 6px;
        height: 156px;
        width: 270px;
        border: 0.5px solid var(--default-grey)
    }

    .card__container {
        position: relative;
        background: #ffffff;
        border-radius: 4px;
        margin-bottom: 6px;
        height: 156px;
        width: 270px;
        border: 0.5px solid var(--default-grey);

        .snfg-text {
            font-style: normal;
            font-weight: 400;
            font-size: 9px;
            line-height: 14px;
            /* identical to box height */
            color: #939393;
            text-transform: capitalize;
        }

        .snfg-text-bottom {
            @extend .snfg-text;
            margin-top: 4px;
        }

        .section-head__card {
            justify-content: space-between;
            align-items: center;
            border-radius: 4px 4px 0px 0px;
            padding: 5px 8px;
            height: 30px;


            .card-streamline__name {
                font-family: var(--main-font);
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                text-transform: uppercase;
                color: var(--default-black);
            }

            .card-date {
                font-family: var(--main-font);
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 22px;
                color: var(--default-black);
            }
        }

        .section-body__card {
            position: relative;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            width: 270px;


            .card-product__text {
                font-family: var(--main-font);
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                height: 36px;
                color: var(--default-black);
            }
        }

        .snfg-text-container {
            position: relative;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 4px;
            padding-bottom: 6px;
            width: 270px;

        }

        .section-foot__card {
            width: 100%;

            .section-wrap-foot__card {
                display: flex;
                align-items: center;
                padding: 0px 8px;
                height: 38px;
                gap: 6px;
                border-top: 0.5px solid var(--default-grey);

                border-radius: 0px 0px 4px 4px;
            }

            .card-status {
                border-radius: 26px;
                padding: 3.5px 12px;
                font-family: var(--main-font);
                font-weight: 500;
                font-size: 10px;
                height: 22px;
            }
        }
    }
}

.section-order__container__main-dashboard::-webkit-scrollbar {
    display: none;
}