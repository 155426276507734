.section-table-rekap-delivery__configuration {
    padding: 22px 15px;
    font-family: var(--main-font);
    background: white;
    border: 0.5px solid #d7d7d7;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: hidden;
}

// // ---media-query for zoom 75% 
// @media (min-width:1624px) and (max-width:1823px) {
//         .section-table-rekap-delivery__configuration {
//         width: calc(100vw - 296px);
//     }
//  }

// // ---media-query for zoom 67% 
// @media (min-width:1824px) and (max-width:2051px) {
//         .section-table-rekap-delivery__configuration {
//         width: calc(100vw - 509px);
//     }
//  }

// // ---media-query for zoom 50% 
//  @media (min-width:2052px) and (max-width:2732px) {
//         .section-table-rekap-delivery__configuration {
//         width: calc(100vw - 1148px);
//     }
//  }

// //  ---media-query for zoom 33% 
//   @media (min-width:2733px) and (max-width:4102px) {
//     .section-table-rekap-delivery__configuration {
//         width: calc(100vw - 2425px);
//     }
//  }

// //  ---media-query for zoom 25% 
//   @media (min-width:4103px) and (max-width:5464px) {
//     .section-table-rekap-delivery__configuration {
//         width: calc(100vw - 3706px);
//     }
//  }

.section-table-rekap-delivery__configuration::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.section-table-rekap-delivery__configuration {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}