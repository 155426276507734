.drop-down-menu-container {
    .menu-button {
        background: white !important;
        background: none;
        display: flex;
        justify-content: space-between;
        border-radius: 3px !important;
        line-height: 16px;
        height: 38px !important;
        text-align: left;
        color: #2d2d2d;
    }

    .menu-button-name {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }
}
.drop-down-menu-list-container {
    width: 500px;
    padding: 0px !important;
    border-radius: 8px;

    .menu-item-list {
        border-bottom: 0.5px solid #d7d7d7;
    }
}
.error-schema-drop-down {
    margin: 4px !important;
    color: red !important;
    font-size: 11px !important;
}
