.section-head-global__container {
    margin-bottom: 12px;

    .title-text__head-info {
        font-family: var(--main-font);
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 27px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: capitalize;
        margin-right: 36px;
        color: #2d2d2d;
    }

}
