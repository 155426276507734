.table-column__container__raw-data_order {
    .column__item {
        display: flex;
        align-items: center;
        padding: 8px 0 8px;
        padding-left: 10px;
        border-bottom: 1px solid #cecece;
        padding-bottom: 8px;
        justify-content: center;

        .box-column-status {
            display: flex;
            width: 95px;
            height: 23px;
            border-radius: 4px;
            align-items: center;
            justify-content: center;

            .text-colum-status {
                font-family: var(--main-font);
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 15px;
                text-align: center;
            }
        }

        .checkbox-active {
            height: 15px;
            width: 15px;
            border: 1px solid #bbbbbb;
            outline: none;
        }

        .checkbox-disable {
            height: 15px;
            width: 15px;
            border: 1px solid #2d2d2d;
            outline: none;
        }

        .column-status {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 19px;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: capitalize;
            color: var(--default-black);
            padding: 2px 16px;
            background: #e8e7e7;
            border-radius: 43px;

            @media (min-width: 1441px) {
                font-size: 14px;
            }
        }

        .column-text {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-transform: capitalize;
            color: #2d2d2d;
            text-align: left;

            @media (min-width: 1441px) {
                font-size: 12px;
            }
        }

        .column-text-ellipsis {
            @extend .column-text;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .column-text-status {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            text-align: center;
        }
    }

    .column__item-child {
        display: flex;
        align-items: center;
        width: 100%;

        .column__item-grand-child {
            width: 100%;
            display: flex;
            align-items: center;
        }

        .column-text {
            flex: 1;
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-transform: capitalize;
            color: #2d2d2d;
            width: 100%;

            @media (min-width: 1441px) {
                font-size: 12px;
            }
        }
    }

    .column__item:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
    }

    .column__item:nth-child(2) {
        position: sticky;
        z-index: 1;
        left: 80px;
    }

    .column__item:nth-child(3) {
        position: sticky;
        z-index: 1;
        left: 192px;
    }

    .column__item:nth-child(4) {
        position: sticky;
        z-index: 1;
        left: 287px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .column__item:nth-child(5) {
        position: sticky;
        z-index: 1;
        left: 427px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-right: 1px solid #cecece;
    }

    .column__item:last-child {
        position: sticky;
        right: 0;
        justify-content: center;
        border-left: 1px solid #cecece;
    }
}
