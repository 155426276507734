.section-upload-error__container__orderplanning{
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: max-content;
    margin-top: 64px;

    .title{
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        margin: 16px 0 42px;

    }


    .error-message__label{
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #2D2D2D;
        padding-bottom: 8px;
    }

    .error-message__container{
        flex-direction: column;

        .error-message__text{
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 16px;
            color: #ED5555;
        }
    }

    .button-container{
            margin-top: 36px;
            width: 100%;
            max-width: 504px;
            display: flex;
            justify-content: flex-end;

            button{
                height: 32px;
                border-radius: 2px;
                border: 1px solid #0154A1;
            }

            .back-button{
                background: white;
                color: #4163A4;
                font-size: 12px;
                font-weight: 600;
                color: #4163A4;
                margin-right: 16px;
            }

            .upload-again{
                background: #0154A1;
                color: #FFF;
                font-size: 12px;
                font-weight: 600;
            }
    }

  

}