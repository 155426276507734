.streamline-head__order-container {
    width: 270px;
    height: 37px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 4px;
    margin-bottom: 18px;
}