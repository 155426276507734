.section-tab__container__asset-management {
    width: 100%;
    padding-bottom: 8px;
    margin-bottom: 28px;

    .tab-item {
        font-family: var(--main-font);
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height */

        cursor: pointer;
        color: #d7d7d7;
        text-transform: uppercase;
        margin-right: 35px;
    }

    .active-tab {
        opacity: 1;
        padding-left: 8px;
        padding-right: 8px;
        border-bottom: 0.5px solid #1746a2;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        color: #1746a2;
        font-family: var(--main-font);
    }
}
