.formContainer {
    .input-text-area-container {
        .input-container::placeholder {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #939393;
        }

        .input-container {
            font-size: 12px;
            line-height: 21px;
            color: #2d2d2d !important;
        }
        .error-text {
            margin: 4px;
            color: red;
            font-size: 11px;
        }
    }
}

.btn-modal-save {
    width: 117px !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    padding: '12px 0px' !important;
    background-color: #1746a2 !important;
    color: #ffffff;
    border-radius: 4px;
}
.btn-modal-save:hover {
    background-color: #1d54c4 !important;
}
.btn-modal-save:active {
    background-color: #1746a2 !important;
}

.btn-modal-cancel {
    @extend .btn-modal-save;
    background-color: #ffffff !important;
    color: #1746a2 !important;
    border: 1px solid #1746a2;
}

.btn-modal-cancel:hover {
    background-color: #ffffff !important;
}
.btn-modal-cancel:active {
    background-color: #ffffff !important;
}
.title-modal {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */

    text-align: center;
}
.modal-title-form {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
}
.label-pilih-status {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-top: 24px;
    margin-bottom: 8px;
}
.label-masukan-notes {
    @extend .label-pilih-status;
    margin-top: 22px;
}
.btn-close-toast {
    padding: 5px;
    cursor: pointer;
}
.btn-close-toast:hover {
    background-color: #2e9e62;
}
