.sku-management__container {
    height: calc(100vh - 160px);
    padding: 32px 18px;
    font-family: var(--main-font);
    background: white;
    border: 0.5px solid #d7d7d7;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: hidden;
}

.modal-actived__sku-management {
    height: calc(100vh - 86px);
    overflow: scroll;
}

.sku-management__container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sku-management__container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
