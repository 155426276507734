.table-additional-upload {
    .table-container-archived {
        .container-head {
            background: #edf0f6;
            .title-head {
                padding: 11px 6px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height */
                text-transform: capitalize;
                color: #2d2d2d;
                height: 40px;
            }
        }

        .table-body-container {
            .table-tr {
                .input-value {
                    padding: 10px 6px;
                    border: none;
                    border-radius: 0px;
                    color: #2d2d2d;
                    font-size: 12px;
                    font-family: Poppins;
                }
                .error-input {
                    border: red solid 1px;
                }
            }
            .table-td {
                border-bottom: 1px solid #b7b7b7 !important;
                /* identical to box height */
                height: 40px;
                text-transform: capitalize;
                padding: 0px 0px;
                padding-top: 4px;
                width: auto;
                /* Black */
            }
        }
    }
}
