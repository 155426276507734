.material-configuration-box-header {
    justify-content: space-between;
    padding: 0px 0px 35px 0px;
    background: #ffffff;

    .text-material-conf {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */

        color: #4163a4;
    }
    .action-button-container {
        .divider {
            height: 31px;
            width: 0.5px;
            background-color: #b7b7b7;
            margin: 0px 24px;
        }
        .button-add-item__container {
            /// text
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: #4163a4;
            /// Button
            width: 135px;
            height: 32px;
            border: 1px solid #0154a2;
            border-radius: 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            padding: 7px 12px;
            margin-right: 12px;
        }
    }
}
