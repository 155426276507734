.demand-anticipation__container {
    height: calc(100vh - 100px);
    padding: 22px 15px;
    font-family: var(--main-font);
    background: white;
    border: 0.5px solid #d7d7d7;
    border-radius: 8px;
    box-sizing: border-box;
    overflow: hidden;
}
.td-status-container {
    .fieldmenu-input {
        width: 130px !important;
        text-align: left;
    }
}

// ---media-query for zoom 67% 
@media (min-width:1824px) and (max-width:2051px) {
        .demand-anticipation__container {
        width: calc(100vw - 376px);
    }
 }

// ---media-query for zoom 50% 
 @media (min-width:2052px) and (max-width:2732px) {
        .demand-anticipation__container {
        width: calc(100vw - 1016px);
    }
 }

//  ---media-query for zoom 33% 
  @media (min-width:2733px) and (max-width:4102px) {
    .demand-anticipation__container {
        width: calc(100vw - 2294px);
    }
 }

//  ---media-query for zoom 25% 
  @media (min-width:4103px) and (max-width:5464px) {
    .demand-anticipation__container {
        width: calc(100vw - 3572px);
    }
 }

