:root {
    --main-font: 'Poppins';
}

* {
    font-family: var(--main-font);
}

//table
.table-row {
    width: 100%;
    // display: flex;
}

Text {
    margin: 0;
    font-family: 'Poppins', sans-serif;

    letter-spacing: 0.2px;
}

.loading-box {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    div {
        display: inline-block;
        position: absolute;
        left: 8px;
        width: 8px;
        background: rgb(0, 69, 45);
        animation: loading-box 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }

    div:nth-child(1) {
        left: 8px;
        animation-delay: -0.24s;
    }

    div:nth-child(2) {
        left: 24px;
        animation-delay: -0.12s;
    }

    div:nth-child(3) {
        left: 42px;
        animation-delay: 0;
    }

    @keyframes loading-box {
        0% {
            top: 8px;
            height: 64px;
        }
        50%,
        100% {
            top: 24px;
            height: 32px;
        }
    }
}

.loading-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    div {
        position: absolute;
        top: 33px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: rgb(0, 69, 45);
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    div:nth-child(1) {
        left: 8px;
        animation: loading-ellipsis1 0.6s infinite;
    }
    div:nth-child(2) {
        left: 8px;
        animation: loading-ellipsis2 0.6s infinite;
    }
    div:nth-child(3) {
        left: 32px;
        animation: loading-ellipsis2 0.6s infinite;
    }
    div:nth-child(4) {
        left: 56px;
        animation: loading-ellipsis3 0.6s infinite;
    }

    @keyframes loading-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes loading-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    @keyframes loading-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(24px, 0);
        }
    }
}
.global-filter-button__container {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: #2d2d2d;

    height: 38px;

    border: 1px solid #8b8b8b;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0 16px;

    span {
        font-family: var(--main-font);
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #2d2d2d;
    }
}

.global-filter-button__container:hover {
    background: none !important;
}

.global-filter-button__container[data-active] {
    background: none !important;
}

#chakra-toast-manager-top-center,
#chakra-toast-manager-center-top {
    bottom: 180px !important;
    left: 22px !important;

    .chakra-toast__inner {
        min-width: 500px;
        max-width: 540px;
        min-height: 48px;
        height: max-content;
        .chakra-alert__icon {
            display: none;
        }

        .chakra-alert {
            min-height: 48px;
            height: max-content;
            background: #ef5350;
            border-radius: 0;
            padding: 8px 16px 8px 24px;
            align-items: center;
        }

        .chakra-alert__desc {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
        }

        button {
            position: static;
            svg {
                width: 12px;
                height: 12px;
            }
        }
    }
}

#chakra-toast-manager-center-top {
    .chakra-alert {
        background: #ace381 !important;
    }
}
