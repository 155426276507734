.section-table-add-new-item__configuration {
    height: calc(100vh - 160px);
    padding: 32px 18px;
    font-family: var(--main-font);
    background: white;
    border: 0.5px solid #d7d7d7;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: hidden;
}


.section-table-add-new-item__configuration::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.section-table-add-new-item__configuration {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

//=========== STYLE MODAL ===============//
.section-modal-add-new-item__container {
    height: calc(100vh - 160px);
    width: calc(100vw - 98px);
    font-family: var(--main-font);
    background: white;
    border: 0.5px solid #d7d7d7;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: hidden;
}

.modal-actived__section-add-new-item {
    height: calc(100vh - 86px);
    overflow: scroll;
    scroll-behavior: smooth;
}

.section-modal-add-new-item__container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.section-modal-add-new-item__container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}