@import '@styles/baseColor';

.section-bottom__container__login-page {
    display: flex;

    .remember-me__container {
        display: flex;
        margin-bottom: 48px;

        cursor: pointer;

        .checkbox {
            margin-right: 8px;
            cursor: pointer;
        }
    }

    .text {
        color: #3c9bd4;
    }

    .button-login__login-page {
        height: 42px;
        width: 100%;

        background: #1746a2;
        border-radius: 4px;

        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #ffffff;
        margin-top: 100px;
    }
}
