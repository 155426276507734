.body-table-container {
    .body-table-cell {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-transform: capitalize;
        padding-right: 0px;
        padding-top: 12px;
        padding-bottom: 9px;
        border-bottom: 0.5px solid #d7d7d7;
        vertical-align: top;
    }
}
