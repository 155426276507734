.section-table__container__table-add-item {
    // width: calc(100vw - 330px);
    width: calc(100vw - 332px);
    overflow-x: scroll;
    position: relative;
    height: calc(100vh - 337px);
    padding-bottom: 16px;

    .section-table {
        height: calc(100vh - 360px);
    }
}

.section-table__container__table-add-item::-webkit-scrollbar {
    height: 7px;
    width: 0;
}

.section-table__container__table-add-item::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: #4163a4;
}

.hide-scrollbar-section-table__container__table-add-item::-webkit-scrollbar {
    display: none;
}

.hide-scrollbar-section-table__container__table-add-item {
    height: max-content;

    .section-table {
        height: max-content;
    }
}
