.component-button {
    padding: 12px 10.5px !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    border-radius: 4px !important;
    background: #1746a2 !important;
    color: #ffffff !important;
    text-transform: capitalize !important;
}
.component-button:hover {
    background: #1746a2 !important;
}
.component-button:active {
    background: #1746a2 !important;
}
.component-button-disabled {
    @extend .component-button;
    background: #9fb5de !important;
}
.component-button-disabled:hover {
    background: #9fb5de !important;
    cursor: no-drop !important;
}
.component-button-disabled:active {
    background: #9fb5de !important;
}

.component-button-outline {
    @extend .component-button;
    background: #ffffff !important;
    border: solid 1px #1746a2 !important;
    color: #1746a2 !important;
}
.component-button-outline:hover {
    background-color: #ffffff !important;
}
