.section-table-archived-list__container {
    background: white;
    padding: 22px 15px;
    border: 0.5px solid #d7d7d7;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: hidden;
}

.title-archive {
    color: #1746A2;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
}

// ---media-query for zoom 67% 
@media (min-width:1724px) and (max-width:2051px) {
        .section-table-archived-list__container {
        width: calc(100vw - 255px);
    }
 }

// ---media-query for zoom 50% 
 @media (min-width:2052px) and (max-width:2732px) {
        .section-table-archived-list__container {
        width: calc(100vw - 892px);
    }
 }

//  ---media-query for zoom 33% 
  @media (min-width:2733px) and (max-width:4102px) {
    .section-table-archived-list__container {
        width: calc(100vw - 2170px);
    }
 }

//  ---media-query for zoom 25% 
  @media (min-width:4103px) and (max-width:5464px) {
    .section-table-archived-list__container {
        width: calc(100vw - 3453px);
    }
 }

.section-table-archived-list__container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.section-table-archived-list__container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
