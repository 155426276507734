.component-infinite-data-select-kode-item {
    width: 706px;
    .infinite-scroll-container {
        .input-data {
            border: 0.5px solid #d7d7d7 !important;
            padding-right: 60px;
        }
        .input-data::placeholder {
            color: #9c9898;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .required-field__text {
            font-weight: 400;
            font-size: 10px;
            line-height: 15px;
            color: #ef5350;
            margin-top: 4px;
        }
        .divider_input_infinite_scroll {
            height: 29px;
            width: 1px;
            background-color: #d7d7d7;
            margin-right: 14px;
        }
        .scroll-box-container {
            max-height: 230px;
            overflow: auto;
            background-color: rgb(255, 255, 255);
            border-radius: 4px;

            .loading_fetch_next_page {
                color: var(--black, #757575);
                font-family: Open Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-left: 12px;
                padding-top: 10px;
                padding-bottom: 10px;
                text-align: center;
            }

            .no_data_infinite_scroll {
                color: var(--black, #757575);
                font-family: Open Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                padding-top: 10px;
                padding-bottom: 10px;
                text-align: center;
                position: absolute;
                left: 0;
                right: 0;
                background-color: #ffffff;
                border-left: 1px solid #e2e8f0;
                border-right: 1px solid #e2e8f0;
            }
            .list_item_infinite {
                color: var(--black, #2d2d2d) !important;
                font-family: Open Sans !important;
                font-size: 12px !important;
                font-style: normal !important;
                font-weight: 600 !important;
                line-height: normal !important;
                padding: 10px 10px;
                border-radius: 2px;
                border-bottom: 0.5px solid #d7d7d7;
            }
            .list_item_infinite:hover {
                background-color: #b9c7e3;
                color: #fff !important;
                cursor: pointer;
            }
            .list_item_infinite:active {
                background-color: #e0e0e0;
            }
        }

        .scroll-box-container::-webkit-scrollbar-track {
            background: #b7b7b7 !important;
            border: solid #ffffff 7px !important;
        }

        .scroll-box-container::-webkit-scrollbar {
            height: 6px !important;
            width: 15px !important;
        }

        .scroll-box-container::-webkit-scrollbar-thumb {
            border-radius: 100px !important;
            background: #4163a4 !important;
            border: solid #fff 5.7px;
            border-bottom: transparent !important;
            border-top: transparent !important;
        }
    }
}
