.main-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .lobby-page__container {
        width: 898px;
        height: 535px;
        background: #ffffff;
        border: 0.5px solid #dcdcdc;
        padding-right: 80px;
        padding-left: 80px;
        border-radius: 8px;
        position: relative;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

        .btn-user-management {
            position: absolute;
            border-radius: 4px;
            border: 1px solid #4163a4;
            align-items: center;
            gap: 4px;
            padding: 4px 8px;
            top: 39px;
            right: 31px;
            cursor: pointer;

            .title {
                color: #4163a4;
                text-align: center;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0.028px;
                text-transform: capitalize;
            }
        }
        .loading-btn-user-management {
            position: absolute;

            align-items: center;
            gap: 4px;
            padding: 4px 8px;
            top: 39px;
            right: 31px;
        }

        .main-text {
            font-family: var(--main-font);
            font-weight: 500;
            font-style: normal;
            font-size: 24px;
            line-height: 22px;
            text-align: center;
            text-transform: capitalize;
            color: #2d2d2d;
            margin-top: 45px;
        }

        .digital-text {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            text-transform: lowercase;
            color: #969696;
            margin-top: 28px;
        }

        .wrapper-content__icons {
            margin-top: 60px;
            margin-bottom: 60px;
            justify-content: center;
            align-items: center;

            .wrapper-text-icons {
                display: flex;
                justify-content: center;
                align-items: center;

                .text-icons {
                    font-family: var(--main-font);
                    font-weight: 500;
                    font-style: normal;
                    font-size: 14px;
                    line-height: 22px;
                    text-align: center;
                    text-transform: capitalize;
                    margin-top: 24px;
                    width: 112px;
                }
            }

            .icons {
                width: 130px;
                height: 130px;
                object-fit: contain;
                cursor: pointer;
                transition: all 0.3s ease;
            }

            .icons-active {
                border-radius: 18px;
                box-shadow: #eff8fa 0px 0px 0px 3px, #eff8fa 0px 0px 0px 6px, #eff8fa 0px 0px 0px 9px,
                    #eff8fa 0px 0px 0px 12px, #eff8fa 0px 0px 0px 15px;
                transition: none;
            }

            .text-active {
                color: #1746a2;
                box-shadow: none;
            }
        }

        .button-next {
            width: 247px;
            height: 42px;
            background: #446dbe;
            border-radius: none;
            cursor: pointer;
            padding: 9px 0px;

            font-family: var(--main-font);
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            text-transform: capitalize;
            color: #ffffff;
        }
    }
}
