.stock-raw-material {
    align-items: flex-start;
    gap: 73px;
    padding: 36px 15px 36px 15px;
    border-bottom: 0.5px solid #d7d7d7;

    .title-stock-raw-material {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */

        color: #2d2d2d;
    }

    .tag-stock-raw-material {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        /* or 167% */

        color: #767676;
    }
    .stock-raw-material-container {
        width: 706px !important;
        gap: 40px !important;
        display: grid;
        position: relative;
        .qty {
            color: rgb(255, 255, 255);
            width: 40px;
            height: 40px;
            background: #1746a2;
            position: absolute;
            top: -38px;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
        }
        .child-container {
            display: flex;
            column-gap: 34px;
            align-items: flex-end;

            .btn-add-new-material {
                width: 92px;
                display: flex;
                border-bottom: 1px solid #0154a2;
                margin-left: 120px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                gap: 8px;
                /* identical to box height */

                color: #4163a4;
                cursor: pointer;
            }
            .input-style-add-new-item-MC {
                width: 213px;
                height: 48px;
                outline: none;
                border: 0.5px solid #d7d7d7;
                border-radius: 4px;
            }
            .input-style-add-new-item-MC::placeholder {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: #9c9898;
            }
            .input-style-stock-raw-material-MC {
                width: 706px;
                height: 48px;
                outline: none;
                border: 0.5px solid #d7d7d7;
                border-radius: 4px;
            }
            .input-style-stock-raw-material-MC::placeholder {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: #9c9898;
            }
            .right-label-input {
                position: absolute;
                z-index: 1;
                top: 0;
                width: 71px;
                height: 48px;
                left: 142px;
                top: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #f8f8f8;
                border: 0.5px solid #d7d7d7;
                border-radius: 3px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height */

                text-align: center;

                color: #9c9898;
            }
            .required-field__text {
                font-weight: 400;
                font-size: 10px;
                line-height: 15px;
                color: #ef5350;
                margin-top: 4px;
                position: absolute;
            }
        }

        .label {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            /* identical to box height */
            margin-bottom: 6px;
            color: #2d2d2d;
        }

        .FieldMenu-placeholder {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height */

            color: #9c9898;
        }

        .menu-button {
            border: 1px solid rgb(165, 165, 165);
            box-sizing: border-box;
            height: 48px !important;
            width: 706px;
            padding-left: 16px;
            border-radius: 4px;
            background: white;
            cursor: pointer;
            flex: 1;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;

            outline: rgb(255, 35, 35);
            border: 0.5px solid #d7d7d7 !important;
            border-radius: 4px !important;
            color: #2d2d2d;

            span:nth-child(1) {
                text-align: left;
            }
        }
        .menuList-container {
            max-height: 300px !important;
            overflow: auto !important;
            width: 706px;
        }
    }
}
