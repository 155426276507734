.table-head__container__main-dashboard {
    width: max-content;
    position: sticky;
    top: 0;
    z-index: 2;
    .table-head__item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;

        font-family: var(--main-font);
        font-style: normal;

        text-align: center;
        background: #859bc6;
        border-radius: 4px;
        height: 50px;
        padding: 0 8px;

        color: #ffffff;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }

    .table-head__item:first-child {
        background: #2d2d2d;
        font-weight: 600;
        line-height: 16px;
    }

    .table-head__item:last-child {
        margin-right: 0;
    }
}
