.sku-management__container {
    background: #ffffff;
    border: 0.5px solid #d7d7d7;
    border-radius: 8px;
    overflow-y: auto;
    display: flex;
    scroll-behavior: smooth;
    flex-direction: column;

    .required-field__text {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: #ef5350;
        margin-top: 4px;
    }

    .wrapper-co {
        display: flex;
    }

    .arrow-back__text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        margin-left: 4px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: capitalize;

        color: #1746a2;
    }

    .edit-wrapper__input {
        .chakra-menu__menu-list {
            max-height: 300px;
            overflow: auto;
        }
    }

    .button-co {
        width: 117px;
        height: 38px;
        margin-left: 16px;
        margin-top: 27px;
        border-radius: 4px;
        padding: 5px 12px;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        font-family: 'Open Sans';
        background: #0154a2;
        color: #fff;
        font-style: normal;
    }

    .wrapper-button {
        margin: 60px auto 20px auto;
        width: 400px;
        display: flex;
        justify-content: flex-end;

        .btn-save__item {
            align-items: center;
            padding: 5px 12px;
            gap: 10px;

            width: 117px;
            height: 42px;

            background: #4163a4;
            border-radius: 4px;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;

            color: #ffffff;

            margin-left: 16px;
        }

        .btn-back_to_top {
            border-radius: 4px;
            border: 2px solid #e4e6e9;
            background: white;
            color: #2d2d2d;
            border: 1px solid #4163a4;
            font-size: 12px;
            font-weight: 600;
            width: 117px;
            height: 42px;
        }
    }
}
.section-body-oddo {
    justify-content: space-between;
    margin-bottom: 22px;

    .input-wrapper-oddo {
        width: 700px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 7px;
    }

    .oddo-field__text {
        color: red;
        margin-top: 4px;
        font-size: 12px;
        font-family: 'Lato';
    }

    .label-input-oddo {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height */

        color: #2d2d2d;
    }

    .input-oddo {
        width: 498px;
        height: 38px;
        padding-left: 15px;
        outline: none;
        border: 1px solid rgb(165, 165, 165);
        border-radius: 3px;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        font-weight: 500;
        font-family: 'Open Sans';
        color: #939393;
    }

    .button-oddo {
        width: 117px;
        height: 38px;
        margin-left: 16px;
        background: #4163a4;
        border-radius: 4px;
        padding: 5px 12px;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        font-family: 'Open Sans';
        color: #ffff;
        font-style: normal;
    }
    .button-oddo-disable {
        width: 117px;
        height: 38px;
        margin-left: 16px;
        background: #4163a4;
        border-radius: 4px;
        padding: 5px 12px;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        font-family: 'Open Sans';
        color: #ffff;
        font-style: normal;
    }
}

.create-and-edit-modal_container_sku {
    margin: 0 auto;
    width: 600px;

    .section-head__container {
        display: flex;
        align-items: center;
        margin-top: 35px;
        margin-bottom: 33px;

        .head-title-back {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            align-items: center;
            text-align: center;
            text-transform: capitalize;

            color: #1746a2;
        }

        .head-title__container {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: capitalize;
            margin-left: 140px;

            color: #1746a2;

            margin-left: 151px;
        }
    }

    .section-body__container {
        .menu-button {
            background: white !important;
            width: 500px;
            background: none;
            display: flex;
            justify-content: space-between;
            border: 1px solid rgb(165, 165, 165) !important;
            border-radius: 3px !important;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-align: left;
            color: #939393;
        }

        .edit-wrapper__input {
            // display: flex;

            align-items: center;
            margin-bottom: 18px;

            .edit-label__input {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 7px;
                /* identical to box height */

                color: #2d2d2d;

                span {
                    color: #dc0000;
                }
            }
            .edit-input__wrapper {
                display: flex;
                flex-direction: column;
            }
            .edit-field__text {
                color: red;
                margin-top: 4px;
                font-size: 12px;
                font-family: 'Lato';
            }

            .edit-input {
                width: 498px;
                height: 38px;
                font-size: 14px;
                outline: none;
                text-align: left;
                padding-left: 15px;
                outline: none;
                border: 1px solid rgb(165, 165, 165);
                box-sizing: border-box;
                border-radius: 4px;
                font-style: normal;
                font-weight: 500;
                font-family: 'Open Sans';
                background: white;
                color: #939393;
            }
        }

        .modal-item__container {
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .label {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                color: #2d2d2d;
                display: flex;
                width: 165px;
                margin-right: 32px;
                display: flex;
                align-self: center;
            }

            .required-field__text {
                font-weight: 400;
                font-size: 10px;
                line-height: 15px;
                color: #ef5350;
                margin-top: 4px;
            }

            .input-container {
                width: 500px;
                text-align: left;
                padding: 6px;
                outline: none;
                border: 1px solid rgb(165, 165, 165);
                box-sizing: border-box;
                background: white;
                border-radius: 3px;
            }

            select,
            .menu-button {
                border: 1px solid rgb(165, 165, 16);
                box-sizing: border-box;
                height: 40px;
                width: 100%;
                padding-left: 16px;
                border-radius: 4px;
                background: white;
                cursor: pointer;
                flex: 1;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;

                outline: grey;

                color: #939393;

                span:nth-child(1) {
                    text-align: left;
                }
            }

            .button-generate {
                border-radius: 4px;
                margin-left: 10px;
                font-size: 12px;
                padding: 5px 30px;
                background-color: #e4e6e9;
            }

            .menu-input__multiple-container {
                flex: 1;

                .selected-multiple-input__container {
                    min-height: 40px;
                    flex: 1;
                    border: 1px solid rgb(165, 165, 165);
                    box-sizing: border-box;
                    gap: 8px;
                    align-items: center;
                    border-radius: 4px;
                    padding: 4px 8px;
                    flex-wrap: wrap;

                    .selected-multiple-input__item {
                        height: 24px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 16px;
                        font-size: 12px;
                        padding: 4px 8px;
                        border: 1px solid rgb(165, 165, 165);
                        border-radius: 4px;
                    }
                }

                .button-choosed__container {
                    cursor: pointer;
                    height: 40px;
                    width: 74px;
                    border-radius: 4px;
                    border: 1px solid rgb(165, 165, 165);
                    justify-content: space-between;
                    align-items: center;
                    padding: 12px 6px 12px 8px;
                    margin-left: 8px;

                    p {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px;
                        text-align: center;

                        color: #939393;
                    }
                }
            }
        }

        .materials-selected__container {
            border: 1px solid rgb(165, 165, 165);
            border-radius: 8px;

            margin-bottom: 32px;

            .material-selected__item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid rgb(165, 165, 165);
                box-sizing: border-box;
                height: 40px;
                width: 100%;
                padding: 0 16px;
                background: white;
                flex: 1;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                cursor: auto;

                outline: grey;

                color: #939393;
            }

            .material-selected__item:first-child {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }

            .material-selected__item:last-child {
                border: none;
                border-radius: 8px;
            }
        }
    }

    .section-footer__container {
        justify-content: flex-end;
        align-items: center;

        .button-add__container,
        .button-cancel__container {
            font-family: var(--main-font);
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #2d2d2d;
            background: rgb(225, 225, 225);

            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            width: 117px;
            height: 42px;
        }

        .button-cancel__container {
            margin-left: 24px;
            background: white;
            border: 2px solid #e4e6e9;
        }
    }
}

.accordion-container {
    border: none;
    border-top: 0.5px solid #e8e8e8 !important;
    display: flex;
    flex-direction: column;

    top: 50%;
    left: 50%;

    margin-bottom: 30px;

    .accordion-panel-wrapper__container {
        margin: 0 auto;
    }

    .accordion-wrapper-child__container {
        margin: 0 auto;
        width: 600px;
    }

    .accordion-title {
        display: flex;
        justify-content: space-between;
        width: 416px;
        padding: 16px 0;
        text-align: left;
        color: #b1b1b1;
    }

    .accordion-title_active {
        display: flex;
        justify-content: space-between;
        width: 416px;
        padding: 16px 0;
        color: #2d2d2d;
    }

    .accordion-icon {
        color: blue($color: #000000);
    }

    .accordion-icon_active {
        color: #2d2d2d;
    }

    .accordion-main-title {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
        color: #2d2d2d;
        font-family: 'Open Sans';
        line-height: 22px;
    }

    .wrapper-title-accordion {
        margin: 0 auto;
        width: 600px;
        padding: 0;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height */

        text-transform: uppercase;
        margin-bottom: 25px;
        margin-top: 30px;
    }

    .accordion-wrapper-label {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height */

        text-transform: capitalize;

        color: #2d2d2d;
    }
}

.edit-input_disable {
    width: 498px;
    height: 38px;
    background: #eeeeee;
    border-radius: 4px;
    text-align: left;
    padding-left: 15px;
    font-size: 14px;
    outline: none;
    outline: grey;
    color: #978c8c;
    box-sizing: border-box;
}

.accordion-wrapper__input {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;

    .accordion-container-input {
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    .required-field__text {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: #ef5350;
        margin-top: 4px;
    }

    .accordion-label-title__input {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height */

        text-transform: capitalize;

        color: #2d2d2d;
    }

    .accordion-label__input {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 7px;
        /* identical to box height */

        color: #2d2d2d;
    }

    .accordion-input {
        width: 498px;
        height: 38px;
        border: 0.5px solid #929191;
        text-align: left;
        padding-left: 15px;
        font-size: 14px;
        outline: none;
        border-radius: 3px;
        background: white;
        outline: grey;
        color: #939393;
        box-sizing: border-box;
    }
}

.input-read_only {
    background: #e3e3e3;
    border: 1px solid #929191;
    outline: grey;
    color: #939393;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    padding-left: 16px;
    border-radius: 4px;
    cursor: pointer;
    flex: 1;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    span:nth-child(1) {
        text-align: left;
    }
}

.sku-management__container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sku-management__container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}
