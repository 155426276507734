.section-head__container__order-planning {
    display: flex;
    justify-content: space-between;

    .section-search__container__order-planning {
        position: relative;
        width: 481px;
        margin-bottom: 28px;

        .search-input {
            width: 481px;
            height: 38px;
            border: 1px solid #d7d7d7;

            border-radius: 4px;
            outline: none;
            padding-left: 16px;

            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
        }

        .search-icon__container {
            position: absolute;
            right: 12px;
            top: 6px;
        }

        .search-input::placeholder {
            font-family: var(--main-font);
            font-style: normal;
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            color: #b1b1b1;
        }
    }

    .section-button{
        display: flex;
        align-items: flex-start;
        gap: 16px;

        .button-container{
            cursor: pointer;
            width: max-content;
            border: 1px solid #0154A2;
            border-radius: 2px;
            padding: 7px 12px;
            display: flex;
            align-items: center;
            background: white;
            height: 32px;

            .button-text{
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                color: #0154A1;
                margin-left: 8px;
            }
        }
    }
}
