.container-edit-table-date-picker-order-confirmation {
    .input-date-eta__container {
        .calender_eta {
            width: 166px;
            height: 32px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            background: pink;

            .date-picker-content-order-confirmation {
                width: 204px;
                height: 32px;

                background: white;
                font-family: var(--main-font);
                font-style: normal;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0px;
                text-align: left;
                color: #b1b1b1;
                outline: none;

                .react-date-picker__wrapper {
                    border-top: 1px solid #1746a2;
                    border-right: 1px solid #1746a2;
                    border-left: 1px solid #1746a2;
                    border-bottom: 1px solid #1746a2;
                    border-radius: 2px;
                }

                .react-date-picker__inputGroup {
                    padding: 0px 6px;
                }

                .react-date-picker__button {
                    display: flex;
                    align-items: center;
                    padding: 6px;
                    border-left: 0.5px solid #1746a2;
                }

                .react-date-picker__calendar {
                    width: 350px;
                    max-width: 100vw;
                    z-index: 5;
                }
            }

            .required-field__text {
                color: red;
                margin-top: 4px;
                font-size: 12px;
                font-family: 'Lato';
            }

            .calendar-first {
                width: 250px;
                position: absolute;
                left: 60px;
                top: 12px;
                margin-left: 5px;
                border-radius: 4px;
            }

            .react-calendar__month-view__weekdays {
                padding-bottom: 6px;
            }

            .react-calendar__month-view__weekdays__weekday {
                color: #2d2d2d;
                font-weight: 600;
                font-style: normal;
                font-size: 8px;
                line-height: 12px;
                text-align: center;
                text-transform: capitalize;
                text-decoration: none;
                padding: 16px 0px;
            }

            .react-calendar__month-view__days__day {
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 16px;
                text-align: center;
                background: white;
                color: #2d2d2d;
            }
        }
    }

    button:disabled {
        cursor: not-allowed;
        abbr {
            color: grey;
        }
    }
}

.error-eta__container {
    .react-date-picker__wrapper {
        border: 1px solid red !important;

        path {
            fill: red !important;
        }
    }
}
