.section-head__container__head-add-item-configuration {
    width: 100%;
    justify-content: space-between;
    padding-bottom: 25px;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 2;
    background: white;

    .text-head {
        font-family: var(--main-font);
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
        text-align: center;
        color: #1746A2;
    }

    .section-right {
        display: flex;
        align-items: center;

        .section-right__button {
            border: 1px solid #1746a2;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 32px;

            .button-text {
                font-family: var(--main-font);
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: #1746a2;
                text-transform: capitalize;
            }
        }

        .button-add {
            width: 127px;
            background: white;
            margin-right: 10px;

            .button-text {
                font-family: var(--main-font);
                color: #4163A4;
                font-weight: 600;
                font-style: normal;
                font-size: 12px;
                line-height: 18px;
            }
        }
    }
}
