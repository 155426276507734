.container-error-detail-upload-demand-anticipation {
    .error-detail {
        max-height: 300px !important;
        overflow: auto !important;
    }
    .error-detail::-webkit-scrollbar-track {
        background: #b7b7b7 !important;
        border: solid #ffffff 7px !important;
    }
    .error-detail::-webkit-scrollbar {
        height: 6px !important;
        width: 17px !important;
    }
    .error-detail::-webkit-scrollbar-thumb {
        border-radius: 100px !important;
        background: #4163a4 !important;
        border: solid #fff 5.7px;
        border-bottom: transparent !important;
        border-top: transparent !important;
    }
}
