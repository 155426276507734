.section-head__container__user-management {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 28px;
    align-items: center;
    .section_head_button_action {
        justify-content: flex-end;
        width: 100%;
        gap: 12px;

        .menu-button-role {
            background-color: blue;
            border: 1px solid #b1b1b1;
            border-radius: 4px !important;
            box-sizing: border-box;
            width: 148px !important;
            height: 32px !important;
            background: white;
            padding: 7px 12.5px;
            cursor: pointer;

            .FieldMenu-placeholder {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 300;
                font-size: 12px;
                line-height: 18px;

                color: #d7d7d7;
            }

            .selected-role-text {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                width: 102px;
                overflow: hidden;
                text-overflow: ellipsis;
                /* Black */

                color: #2d2d2d;
            }
            span:nth-child(1) {
                text-align: left;
            }
        }

        .field-menu-list__container {
            // width: 706px;
            .field-menu-item__container {
                height: 38px;
                border-bottom: 0.5px solid #d7d7d7;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                padding-bottom: 10px;
                /* Black */
                color: #2d2d2d;
            }

            .field-menu-item__container:last-child {
                border-bottom: none;
            }
        }

        .field-menu-list__container::-webkit-scrollbar-track {
            background: #b7b7b7 !important;
            border: solid #ffffff 7px !important;
        }

        .field-menu-list__container::-webkit-scrollbar {
            height: 6px !important;
            width: 15px !important;
        }

        .field-menu-list__container::-webkit-scrollbar-thumb {
            border-radius: 100px !important;
            background: #4163a4 !important;
            border: solid #fff 5.7px;
            border-bottom: transparent !important;
            border-top: transparent !important;
        }

        .menu-button-JM {
            @extend .menu-button-role;
            width: 162px;
            padding: 7px 5.5px;
        }
        .menuList-container-JM {
            // @extend .menuList-container-role;
            max-height: 300px;
            overflow: auto;
            width: 100%;
        }
        .btn-filter-by-name-container {
            @extend .menu-button-role;
            position: relative;
            width: 168px;
            padding-left: 12px;
            padding-left: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .has-user-selected {
            border: 1px solid #1746a2;
        }

        .has-user-selected-text {
            font-family: 'Poppins' !important;
            font-style: normal !important;
            font-weight: 400 !important;
            font-size: 12px !important;
            line-height: 18px !important;
            /* identical to box height */

            color: #1746a2 !important;
        }
        .btn-filter-badge {
            position: absolute;
            width: 18px;
            height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ef5350;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 15px;
            border-radius: 100%;
            color: #ffffff;
            top: -10px;
            right: -10px;
        }
        .btn-filter-text {
            @extend .FieldMenu-placeholder;
        }
        .btn-filter-name-icon {
            border-left: 0.5px solid #d7d7d7;
            height: 29px;
            display: flex;
            align-items: center;
            padding-left: 8px;
            // padding-left: 16px;
        }
        .has-user-selected-icon {
            border-left: 0.5px solid #1746a2;
        }
        .menuList-container-role {
            max-height: 244px;
            overflow: auto;
            width: 370px;

            // max-height: 300px;
            // width: 100%;
        }
        .divider {
            height: 31px;
            width: 0.5px;
            background-color: #b7b7b7;
            margin-left: 20px;
            margin-right: 20px;
        }
    }

    .section-search__container__user-management {
        position: relative;
        width: 481px;

        margin-right: 16px;

        .search-input {
            width: 481px;
            height: 40px;
            border: 1px solid #d7d7d7;
            border-radius: 4px;
            outline: none;
            padding-left: 16px;

            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
        }

        .search-icon__container {
            position: absolute;
            right: 12px;
            top: 6px;
            cursor: pointer;
        }

        .search-input::placeholder {
            font-family: var(--main-font);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height */

            color: #b1b1b1;

            color: #b8b8b8;
        }
    }

    .button-add-account__container {
        /// text
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #4163a4;
        /// Button
        // width: 150px;
        height: 32px;
        border: 1px solid #0154a2;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 5px 12px;
    }
}
