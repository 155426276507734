.subhead-row {
    background-color: #e2e6ee;
    height: 28px !important;

    .subhead-cell-table {
        text-transform: capitalize !important;
        color: #645959;
        font-family: 'Poppins' !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        padding-top: 5px !important;
        line-height: 18px !important;
        padding-bottom: 5px !important;
        padding-right: 0px !important;
        background-color: #ededed !important;
    }
}

.top-subhead-row {
    background-color: #fff;
    height: 4px;

    .top-subhead-cell-table {
        padding: 0;
        background-color: #fff;
    }
}
